:root {
    --light:#f2f4fc;
    --blue:#3565FC;
    --black:#000000;
    --font-color:#202842;
    --white:#ffffff;
    --dark: #202842;
    --red: #EA2B12;
    --green: #009A4F;
    --yellow: #FFB800;
    --light-blue: #1594AD;
    --light-bg: #eaefff;
}
