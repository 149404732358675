@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  font-family: "Roboto", sans-serif !important;
  font-style: normal;
  overflow-y: scroll;
  scrollbar-width: thin;
}

html {
  scroll-behavior: smooth;
}

a:hover {
  text-decoration: none;
}

.table-wrapper {
  overflow-x: auto;
  max-width: 100%;
}

.table__container .stripped__table {
  width: 100%;
}

.actual_grid_box {
  width: 30px;
  border: none;
}

/* Header */
.header-main {
  border-bottom: 1px solid #d7e0fe;
  position: sticky;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999999;
  background: #fcfcfc;
}

.header-select-property .form-select option {
  font-family: "Roboto", sans-serif;
}

.logo-name {
  padding: 27px 25px;
  background: var(--light);
  font-weight: 900;
  font-size: 22px;
  line-height: 26px;
  margin: 0;
  color: var(--dark);
  width: 76%;
  text-align: center;
}

.logo-main {
  padding: 10px 10px 10px 0;
  background: var(--white);
  margin-left: -2px;
}

.header-main .form-select {
  border: none !important;
  border-bottom: 1px solid var(--black) !important;
  border-radius: 0;
}

.header-avtar {
  margin-right: 10px;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  object-fit: cover;
}

.header-main .header-left-col {
  max-width: calc(296px + calc(var(--bs-gutter-x) * 1));
}

.header-main .header-right-col {
  max-width: calc(100% - calc(296px + calc(var(--bs-gutter-x) * 1)));
  width: 100%;
}

.header-main .header-row {
  align-items: center;
}

.multiselector .multiSelectContainer {
  box-shadow: none;
  text-align: left;
  padding: 12px 0;
  width: 100%;
}

.header-right .header-select-property .form-select {
  background-color: transparent;
}

.header-select-property .form-select {
  font-weight: 700;
  font-size: 16px;
}

.header-select-property .form-select:focus {
  outline: none;
  box-shadow: none;
}

.header-select-property .form-select option:hover {
  background: red !important;
  background-color: red !important;
}

.header-select-property .form-select option {
  font-weight: 400;
  font-size: 14px;
}

.multiselector .multiSelectContainer input::placeholder {
  color: var(--dark) !important;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
}

.multiselector .searchWrapper {
  border: none;
  border-bottom: 1px solid var(--light);
  position: relative;
}

.multiselector .searchWrapper:after {
  content: "";
  right: 8px;
  position: absolute;
  top: 19px;
  background: url(../Images/Header/downArrow.svg) no-repeat;
  width: 11px;
  height: 9px;
}

.header-select-property .multiselector .multiSelectContainer {
  width: 360px;
}

.header-search-input {
  position: relative;
}

.header-search-input {
  width: 418px;
  margin-right: 20px;
}

.header-icons li {
  padding-right: 20px;
  margin-left: 20px;
  border-right: 1px solid var(--light);
  cursor: pointer;
}

.header-icons li:last-child {
  margin-right: 20px;
}

.header-icons ul {
  display: flex;
  align-items: center;
}

.right-menu {
  margin-right: 30px;
}

.search-group {
  background: #f5f7ff;
  border: 1px solid #e1e8ff;
  border-radius: 5px;
}

.search-group input {
  border-radius: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  padding: 13px 13px 13px 2px;
  border-left: none !important;
  box-shadow: none !important;
  background: transparent !important;
}

.search-group input::placeholder {
  color: #b1b4bd;
  font-style: italic;
}

.search-group button {
  padding: 8px;
  display: flex;
  align-items: center;
  background: transparent;
}

.search-group button:hover,
.search-group button:focus,
.search-group button:focus-visible,
.search-group button:active,
.search-group button:focus-visible {
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
  background: transparent !important;
}

.logo-main img {
  cursor: pointer;
}

.header-btn {
  background-color: inherit !important;
  border: none;
  padding: 0;
}

.header-btn:focus,
.header-btn:focus-visible {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

/* .headerToDoListMain {
  display: flex;
  justify-content: center;
  box-shadow: -4px 4px 18px rgba(0, 0, 0, 0.21);
  border-radius: 5px;
  position: fixed;
  padding-top: 20px;
  top: 0;
  right: 0;
  width: 350px;
  height: 100%;
  z-index: 111;
  overflow-y: auto;
  background: var(--white);
} */

.headerToDoListMainWrapper .checkbox-container h5 {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--font-color);
}

.headerToDoListMainWrapper .checkbox-container {
  margin-bottom: 20px;
}

.headerToDoListTitle h2 {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: var(--font-color);
}

.headerToDoListTitle {
  margin-bottom: 25px;
}

.headerToDoListMainWrapper .form-groups label {
  display: flex;
  justify-content: space-between;
}

.headerToDoListMainWrapper .form-groups label span {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  max-width: 300px;
}

.headerToDoListMainWrapper .form-checkbox {
  margin-bottom: 10px;
}

.cursorPointer {
  cursor: pointer;
}

/* home-sidebar */

.home-aside {
  display: flex;
  justify-content: center;
  box-shadow: 0px 0px 50px rgba(138, 149, 179, 0.1);
  position: fixed;
  left: 0;
  top: 80px;
  height: calc(100vh - 80px);
  z-index: 111;
  overflow-y: auto;
  background-color: var(--white);
}

.home-aside.menu-collapse {
  background-color: var(--white);
  box-shadow: 0 0 50px rgba(138, 149, 179, 0.1);
  display: flex;
  height: calc(100vh - 80px);
  justify-content: center;
  left: 0;
  overflow-y: auto;
  position: fixed;
  top: 80px;
  z-index: 111;
  width: 80px;
}

.home-aside ul li a {
  padding: 23px 28px;
  position: relative;
  overflow: hidden;
}

.home-aside ul li a:hover {
  background: var(--light);
}

.home-aside ul li > img {
  padding: 23px 21px;
  position: relative;
  overflow: hidden;
}

.home-aside ul li > img:hover {
  background: var(--light);
}

.home-aside ul li > div {
  padding: 23px 21px;
  position: relative;
  overflow: hidden;
}

.home-aside ul li > div:hover {
  background: var(--light);
}

.page-body {
  --bs-gutter-x: 1.5rem;
  /* margin: 80px 0 0 80px; */
  min-height: 100%;
  padding: 30px calc(30px - calc(var(--bs-gutter-x) * 0.5));
  position: relative;
  top: 0;
  margin-left: auto;
  width: calc(100vw - 80px);
}

.home-aside a.active {
  background: var(--light);
}

.home-aside a.active *,
.home-aside li a:hover * {
  filter: brightness(0);
}

.asideConfigurationMain .active img {
  filter: unset;
}

.home-aside ul li a.active::after,
.home-aside ul li a.active::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 20px;
  background: var(--white);
  left: 0;
  bottom: -14px;
  border-top-right-radius: 10px;
}

.home-aside ul li a.active::before {
  border-top-right-radius: 0;
  border-bottom-right-radius: 10px;
  top: -14px;
  bottom: inherit;
}

/* Form-groups css */

.textTypeFile input {
  border-radius: 5px;
  border: 1px solid #cfd9f8 !important;
  padding: 11px 15px 11px 15px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--font-color);
}

.search-group-for-table {
  background: var(--white);
  border: 1px solid #cfd9f8;
  border-radius: 3px;
}

.search-group-for-table button {
  padding: 5px;
  display: flex;
  align-items: center;
  background: transparent;
}

.search-group-for-table input {
  font-size: 12px;
  line-height: 100%;
  padding: 0;
}

.search-group-for-table input:hover,
.search-group-for-table input:focus,
.search-group-for-table input:focus-visible,
.search-group-for-table input:active,
.search-group-for-table input:focus-visible {
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
  /* background: transparent !important; */
}

.search-group-for-table button:hover,
.search-group-for-table button:focus,
.search-group-for-table button:focus-visible,
.search-group-for-table button:active,
.search-group-for-table button:focus-visible {
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
  background: transparent !important;
}

/* .all-input-fields .col-xl-3,
.all-input-fields .col-xl-2,
.all-input-fields .col-xl-4,
.all-input-fields .col-xl-11,
.all-input-fields .col-xl-6 {
  margin-bottom: 40px;
} */
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown),
.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 0;
  padding-bottom: 0;
  outline: none;
  box-shadow: none;
  border: 1px solid #cfd9f8 !important;
}

.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  min-height: 100%;
  height: 44px;
  line-height: 19px;
  border: 1px solid #cfd9f8 !important;
}

.form-floating > .form-control:focus ~ label {
  color: var(--black) !important;
}

.from-groups {
  position: relative;
}

.form-floating > label {
  top: -7px !important;
}

.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  transform: scale(0.85) translateY(-1.4rem) translateX(0.15rem) !important;
}

.from-groups label {
  position: absolute;
  top: -8px;
  left: 10px;
  background: var(--white);
  /* padding: 0 5px; */
  padding: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: var(--font-color);
  z-index: 1;
}

.no-border-break label {
  position: unset;
  margin-bottom: 5px !important;
  background: transparent;
}

.from-groups input {
  border-radius: 5px;
  padding: 14px 15px 11px 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  color: var(--font-color);
}

.no-border-break input {
  padding: 5px;
  height: 30px;
}

.from-groups input:focus {
  outline: none !important;
  box-shadow: none !important;
}

.all-input-fields .multiselector button {
  border: 1px solid #cfd9f8 !important;
  box-shadow: none;
  text-align: left;
  color: var(--dark) !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--font-color);
  padding: 10px 15px;
  width: 100%;
}

.all-input-fields.header-select-property .multiselector button {
  width: 360px;
}

.form-control:disabled {
  background: transparent;
  color: darkgrey;
}

table.table .p-multiselect .p-multiselect-label {
  font-size: 12px;
}

.p-multiselect .p-multiselect-label {
  border-radius: 5px !important;
  border: none !important;
  padding: 7px !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 100% !important;
  color: var(--font-color) !important;
}

.table .p-multiselect:not(.p-disabled).p-focus {
  border: none !important;
}

.p-multiselect {
  border-radius: 5px !important;
  border: 1px solid #cfd9f8 !important;
  width: 100% !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: var(--black) !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  background: transparent !important;
  align-items: center;
  display: flex;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: var(--green) !important;
  background: var(--green) !important;
}

.p-multiselect:not(.p-disabled).p-focus {
  box-shadow: none !important;
  border: 1px solid #cfd9f8 !important;
  outline: none !important;
}

.p-checkbox .p-checkbox-box {
  width: 15px !important;
  height: 15px !important;
  border-radius: 1px !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  padding: 0 1.25rem !important;
  margin-bottom: 14px !important;
}

.p-checkbox {
  align-items: center !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: var(--green) !important;
}

/* Redio BTN */
.form-label {
  font-size: 12px;
  line-height: 100%;
}

.form-radio {
  position: relative;
}

.form-radio input[type="radio"] {
  position: absolute;
  left: 0;
  top: 0;
  visibility: hidden;
  opacity: 0;
}

.form-radio label {
  padding-left: 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: var(--font-color);
  position: relative;
  cursor: pointer;
}

.form-radio label::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border: 1.5px solid var(--font-color);
  border-radius: 100%;
}

.form-radio label::before {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  left: 4px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 100%;
  background: var(--blue);
  opacity: 0;
}

.form-radio input[type="radio"]:checked ~ label::before {
  opacity: 1;
}

.form-radio input[type="radio"]:checked ~ label::after {
  border-color: var(--blue);
}

/* Checkbox BTN */
/* input:not([type="radio"], [type="checkbox"]):focus {
  background: transparent;
} */

.form-checkbox {
  position: relative;
}

.form-checkbox label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: var(--font-color);
}

.form-checkbox label::before {
  content: "";
  position: absolute;
  width: 18px;
  height: 18px;
  background: url(../Images/FormCheck/Checked.svg) no-repeat center center;
  background-size: contain;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  opacity: 0;
}

.form-checkbox label::after {
  content: "";
  position: absolute;
  width: 18px;
  height: 18px;
  border: 1px solid #8f93a0;
  border-radius: 3px;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.form-checkbox input[type="checkbox"] {
  position: absolute;
  left: 0;
  top: 0;
  visibility: hidden;
  opacity: 0;
}

.form-checkbox input[type="checkbox"]:checked ~ label::before {
  opacity: 1;
}

.form-checkbox input[type="checkbox"]:checked ~ label span {
  text-decoration-line: line-through;
}

.form-checkbox input[type="checkbox"]:checked ~ label::after {
  opacity: 0;
}

/* Input Show Hide Password */

.show-password button {
  padding: 4px;
  display: flex;
  align-items: center;
  background: transparent;
  border: none !important;
}

.show-password input {
  border: none !important;
}

.close-icon {
  display: none;
}

.show-password button:hover,
.show-password button:focus,
.show-password button:focus-visible,
.show-password button:active,
.show-password button:focus-visible {
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
  background: transparent !important;
}

.show-password {
  position: relative;
}

.show-password .password-group {
  border: 1px solid #cfd9f8;
  border-radius: 5px;
  background: var(--white);
  overflow: hidden;
  height: 30px;
}

.hideShowBtn {
  position: absolute;
  right: 0;
  top: 4px;
}

.password-group img {
  width: 18px;
  height: 18px;
}

/* normal react bootstrap select */

.from-groups .form-select {
  border-radius: 5px;
  border: 1px solid #cfd9f8 !important;
  padding: 5px;
  height: 30px;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  /* line-height: 16px; */
  color: var(--font-color);
  /* background: transparent; */
}

.from-groups .form-select:hover,
.from-groups .form-select:focus,
.from-groups .form-select:focus-visible,
.from-groups .form-select:active,
.from-groups .form-select:focus-visible {
  box-shadow: none !important;
  outline: none !important;
}

/* common btn css */

.default-btn {
  background: lightgray;
  padding: 10px 20px;
  border: 1px solid gray;
  border-radius: 5px;
  color: gray;
  font-weight: 500;
  font-size: 13px;
  line-height: 100%;
}

.default-btn:hover {
  background: lightgray;
  color: var(--black);
  border: 1px solid var(--black);
}

.default-btn:focus,
.default-btn:focus-visible,
.default-btn:active,
.default-btn:focus-visible {
  box-shadow: none !important;
  outline: none !important;
  background: lightgray;
  color: gray;
  border: 1px solid gray;
}

.red-btn {
  background: #f44336;
  border: 1px solid #f44336;
  color: var(--white);
}

.red-btn:hover {
  border: 1px solid #8f93a0;
  background: var(--white);
}

.red-btn:focus,
.red-btn:focus-visible,
.red-btn:active,
.red-btn:focus-visible {
  box-shadow: none !important;
  outline: none !important;
  color: var(--white);
  background: #f44336;
  border: 1px solid #f44336;
}

.primary-btn {
  background: var(--blue);
  border: 1px solid var(--blue);
  color: var(--white);
}

.secondary-btn {
  background: var(--white);
  border: 1px solid #8f93a0;
  color: var(--black);
}

.primary-btn:focus,
.primary-btn:focus-visible,
.primary-btn:active,
.primary-btn:focus-visible {
  box-shadow: none !important;
  outline: none !important;
  color: var(--white);
  background: var(--blue);
  border: 1px solid var(--blue);
}

.primary-btn:hover {
  border: 1px solid #8f93a0;
  background: var(--white);
  color: var(--black);
}

.secondary-btn:hover {
  border: 1px solid var(--blue);
  background: var(--blue);
  color: var(--white);
}

.secondary-btn:focus,
.secondary-btn:focus-visible,
.secondary-btn:active,
.secondary-btn:focus-visible {
  box-shadow: none !important;
  outline: none !important;
  background: var(--white);
  border: 1px solid var(--black);
  color: var(--black);
}

.add-btn-plus {
  background: #e1e8ff;
  border-radius: 5px;
  color: var(--blue);
  padding: 6.5px 16px 6.5px 12px;
  border: none;
  display: flex;
  align-items: center;
  font-size: 15px;
  line-height: 100%;
}

.add-btn-plus img {
  margin-right: 6px;
}

.add-btn-plus:hover {
  background: #e1e8ff;
  color: var(--blue);
  border: none;
}

.add-btn-plus:focus,
.add-btn-plus:focus-visible,
.add-btn-plus:active,
.add-btn-plus:focus-visible {
  box-shadow: none !important;
  outline: none !important;
  background: #e1e8ff;
  color: var(--blue);
}

.btn-close {
  border-radius: 50%;
  padding: 7px;
  font-size: 8px;
  background: url("../Images/Action Icons/close_btn_blue.svg") no-repeat;
  width: 30px;
  height: 30px;
  border: none !important;
  background-size: contain;
  opacity: 1;
}

.btn-close:hover {
  background-color: transparent;
  border: 1px solid black;
}

.btn-close:hover,
.btn-close:focus,
.btn-close:focus-visible,
.btn-close:active,
.btn-close:focus-visible {
  box-shadow: none !important;
  outline: none !important;
  background-color: transparent !important;
}

/* date picker css */
.react-datepicker-wrapper .react-datepicker__input-container input {
  border-radius: 5px;
  border: 1px solid #cfd9f8 !important;
  padding: 10px;
  font-weight: 400;
  font-size: 11px;
  line-height: 100%;
  color: var(--font-color);
  /*background: transparent;*/
  width: 100%;
}

table td .react-datepicker-wrapper .react-datepicker__input-container input {
  border-radius: 5px;
  border: 1px solid #cfd9f8 !important;
  padding: 3px;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  color: var(--font-color);
  /* background: transparent; */
  width: 100%;
}

table td .react-datepicker__input-container::after {
  position: absolute;
  content: "";
  background: url(../Images/Header/calender-icon.svg) no-repeat;
  background-size: contain;
  height: 14px;
  width: 14px;
  right: 11px;
  top: 0;
  bottom: 0;
  margin: auto;
}

table .form-select option {
  font-size: 12px;
}

input#startFrom {
  border-top-right-radius: 0;
  width: 100%;
  border-bottom-right-radius: 0;
}

input#endDate {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 100%;
}

/* badges and action icons css */

.default-badge {
  border-radius: 5px;
  padding: 5px 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
}

.active-badge {
  color: var(--green);
  background: #d9f0e7 !important;
  border-radius: 5px;
}

.inactive-badge {
  color: var(--red) !important;
  background: #f3d7d6 !important;
  border-radius: 5px;
}

.lblue-badge {
  color: var(--blue);
  background: #e1e8ff !important;
  border-radius: 5px;
}

.lYellow-badge {
  color: #ffb800;
  background: rgba(255, 184, 0, 0.12) !important;
  border-radius: 5px;
}

.lgreen-badge {
  color: #1594ad;
  background: #deecf1 !important;
  border-radius: 5px;
}

.Allactions img {
  cursor: pointer;
  width: 17px;
  height: 17px;
}

.Allactions img:not(:last-child) {
  margin-right: 6px;
}

.btn:disabled {
  background-color: transparent;
}

.eportExelAction,
.besideEportExelAction {
  padding: 8px;
  background: var(--light-bg);
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.allExportExelAction {
  display: flex;
  grid-gap: 10px;
}

/* Multi option select dropdown */
.select__multi-value {
  position: relative;
}

.select__multi-value__remove {
  position: absolute;
  right: -7px;
  top: -7px;
  background: var(--red);
  border-radius: 50% !important;
  height: 15px;
  width: 15px;
}

.select__multi-value__remove svg {
  fill: var(--white);
}

.select__control {
  padding: 8px 3px 8px !important;
  border: 1px solid #cfd9f8 !important;
  border-radius: 5px !important;
}

.select__value-container {
  overflow: unset !important;
}

.select__multi-value:not(:last-child) {
  margin-right: 20px;
  padding: 0 10px;
}

/* stripped table css  */
.stripped__table table .react-datepicker__input-container {
  z-index: 1;
  position: relative;
}

.react-datepicker__input-container::after {
  position: absolute;
  content: "";
  background: url(../Images/Header/calender-icon.svg) no-repeat;
  background-size: contain;
  height: 17px;
  width: 17px;
  right: 11px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.react-datepicker__input-container input {
  margin: 0 !important;
  padding: 0;
}

.stripped__table .react-datepicker__input-container::after {
  right: 5px;
  top: 0;
  z-index: 0;
  pointer-events: none;
}

.from-groups .p-multiselect > * {
  height: 30px;
}

.stripped__table table th,
.stripped__table table td {
  /* font-size: 14px; */
  font-size: 12px;
  background-color: white;
  vertical-align: middle;
  line-height: 100%;
  color: var(--black);
}

.stripped__table
  .only-year-table-date-picker
  .react-datepicker-wrapper
  .react-datepicker__input-container
  input {
  border: none !important;
  font-weight: bold;
  font-size: 14px;
}

/* .stripped__table table {
  border: 1px solid transparent;
  position: relative;
  z-index: 0;
} */

.stripped__table table.positionStaticTable {
  position: static;
}

.stripped__table table .default-badge {
  font-size: 12px;
}

.stripped__table table::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #d7e0fe;
  border-radius: 5px !important;
  z-index: -1;
}

.stripped__table table thead tr:first-child {
  border-top-left-radius: 5px;
}

.stripped__table table thead tr:last-child {
  border-top-right-radius: 5px;
}

/* .stripped__table table tr:not(tfoot tr),
.stripped__table table tbody tr:last-child {
  border-bottom: 1px solid #d7e0fe;
} */
/* .stripped__table table tbody tr:last-child
 {
  border-bottom: none;
} */
/* .stripped__table table tfoot {
  border: none;
} */
/* .stripped__table table th,
.stripped__table table tfoot tr:last-child td {
  background-color: transparent;
} */
/* .stripped__table table th,
.stripped__table table td {
  border: none;
} */
/* .stripped__table table thead tr {
  border-bottom: 1px solid #9ab2fd !important;
} */
.stripped__table table tfoot tr td {
  border-top: 1px solid #9ab2fd !important;
}

.react-datepicker {
  border: 1px solid #9ab2fd !important;
}

.react-datepicker__header {
  border-bottom: 1px solid #9ab2fd !important;
}

.react-datepicker__year-wrapper {
  max-width: 210px !important;
}

/* 
.stripped__table th:nth-child(1),
.table-even td:nth-child(1) {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.stripped__table th:last-child,
.table-even td:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.stripped__table th {
  background: #e1e7f9;
  border: transparent;
} */

/* .stripped__table .table-even {
  background: #f5f7ff90;
} */
/* .table tr td {
  background: transparent;
}

.stripped__table .table tr td {
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
  color: var(--font-color);
  padding: 0 20px;
}

.stripped__table .table thead th {
  padding: 10px 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: 36px;
  padding: 0 20px;
  color: var(--font-color);
  text-transform: uppercase;
}

.table,
.table thead th {
  border: none;
} */

.stripped__table .table tr td:last-child:has(.stickyLastRow),
.stripped__table .table thead th:last-child:has(.stickyLastRow) {
  text-align: right;
}

table thead th {
  text-transform: uppercase;
}

.search-group-for-table {
  max-width: 100%;
  min-width: 120px;
}

.search-group-for-table img {
  height: 15px;
  width: 15px;
}

table.dataTable thead > tr > td.sorting,
table.dataTable thead > tr > td.sorting_asc,
table.dataTable thead > tr > td.sorting_asc_disabled,
table.dataTable thead > tr > td.sorting_desc,
table.dataTable thead > tr > td.sorting_desc_disabled,
table.dataTable thead > tr > th.sorting,
table.dataTable thead > tr > th.sorting_asc,
table.dataTable thead > tr > th.sorting_asc_disabled,
table.dataTable thead > tr > th.sorting_desc,
table.dataTable thead > tr > th.sorting_desc_disabled {
  cursor: pointer;
  padding-right: 26px;
  position: relative;
}

table.dataTable thead > tr > td.sorting_asc:before,
table.dataTable thead > tr > td.sorting_desc:after,
table.dataTable thead > tr > th.sorting_asc:before,
table.dataTable thead > tr > th.sorting_desc:after {
  opacity: 1;
}

table.dataTable thead > tr th.sorting:after,
table.dataTable thead > tr th.sorting:before {
  line-height: 15px;
}

table.dataTable thead > tr > td.sorting:before,
table.dataTable thead > tr > td.sorting_asc:before,
table.dataTable thead > tr > td.sorting_asc_disabled:before,
table.dataTable thead > tr > td.sorting_desc:before,
table.dataTable thead > tr > td.sorting_desc_disabled:before,
table.dataTable thead > tr > th.sorting:before,
table.dataTable thead > tr > th.sorting_asc:before,
table.dataTable thead > tr > th.sorting_asc_disabled:before,
table.dataTable thead > tr > th.sorting_desc:before,
table.dataTable thead > tr > th.sorting_desc_disabled:before {
  bottom: 50%;
  content: "▲";
}

table.dataTable thead > tr > td.sorting:after,
table.dataTable thead > tr > td.sorting:before,
table.dataTable thead > tr > td.sorting_asc:after,
table.dataTable thead > tr > td.sorting_asc:before,
table.dataTable thead > tr > td.sorting_asc_disabled:after,
table.dataTable thead > tr > td.sorting_asc_disabled:before,
table.dataTable thead > tr > td.sorting_desc:after,
table.dataTable thead > tr > td.sorting_desc:before,
table.dataTable thead > tr > td.sorting_desc_disabled:after,
table.dataTable thead > tr > td.sorting_desc_disabled:before,
table.dataTable thead > tr > th.sorting:after,
table.dataTable thead > tr > th.sorting:before,
table.dataTable thead > tr > th.sorting_asc:after,
table.dataTable thead > tr > th.sorting_asc:before,
table.dataTable thead > tr > th.sorting_asc_disabled:after,
table.dataTable thead > tr > th.sorting_asc_disabled:before,
table.dataTable thead > tr > th.sorting_desc:after,
table.dataTable thead > tr > th.sorting_desc:before,
table.dataTable thead > tr > th.sorting_desc_disabled:after,
table.dataTable thead > tr > th.sorting_desc_disabled:before {
  display: block;
  font-size: 0.8em;
  line-height: 9px;
  opacity: 0.125;
  position: absolute;
  right: 10px;
}

table.dataTable thead > tr th.sorting:after,
table.dataTable thead > tr th.sorting:before {
  line-height: 15px;
}

table.dataTable thead > tr > td.sorting:after,
table.dataTable thead > tr > td.sorting_asc:after,
table.dataTable thead > tr > td.sorting_asc_disabled:after,
table.dataTable thead > tr > td.sorting_desc:after,
table.dataTable thead > tr > td.sorting_desc_disabled:after,
table.dataTable thead > tr > th.sorting:after,
table.dataTable thead > tr > th.sorting_asc:after,
table.dataTable thead > tr > th.sorting_asc_disabled:after,
table.dataTable thead > tr > th.sorting_desc:after,
table.dataTable thead > tr > th.sorting_desc_disabled:after {
  content: "▼";
  top: 50%;
}

table.dataTable thead > tr > td.sorting_asc:before,
table.dataTable thead > tr > td.sorting_desc:after,
table.dataTable thead > tr > th.sorting_asc:before,
table.dataTable thead > tr > th.sorting_desc:after {
  opacity: 0.6;
}

table.dataTable thead > tr > td.sorting:after,
table.dataTable thead > tr > td.sorting:before,
table.dataTable thead > tr > td.sorting_asc:after,
table.dataTable thead > tr > td.sorting_asc:before,
table.dataTable thead > tr > td.sorting_asc_disabled:after,
table.dataTable thead > tr > td.sorting_asc_disabled:before,
table.dataTable thead > tr > td.sorting_desc:after,
table.dataTable thead > tr > td.sorting_desc:before,
table.dataTable thead > tr > td.sorting_desc_disabled:after,
table.dataTable thead > tr > td.sorting_desc_disabled:before,
table.dataTable thead > tr > th.sorting:after,
table.dataTable thead > tr > th.sorting:before,
table.dataTable thead > tr > th.sorting_asc:after,
table.dataTable thead > tr > th.sorting_asc:before,
table.dataTable thead > tr > th.sorting_asc_disabled:after,
table.dataTable thead > tr > th.sorting_asc_disabled:before,
table.dataTable thead > tr > th.sorting_desc:after,
table.dataTable thead > tr > th.sorting_desc:before,
table.dataTable thead > tr > th.sorting_desc_disabled:after,
table.dataTable thead > tr > th.sorting_desc_disabled:before {
  display: block;
  font-size: 0.8em;
  line-height: 9px;
  opacity: 0.125;
  position: absolute;
  right: 10px;
  margin-bottom: 5px;
}

/* .table td,
.table th {
  border: none !important;
} */

tr.filters {
  background-color: var(--white) !important;
}

.filters th {
  padding: 5px 7px !important;
  background-color: var(--white) !important;
}

.dataTables_info {
  /* width: 50%; */
  float: left;
  font-size: 13px;
  line-height: 100%;
}

.dataTables_paginate {
  /* width: 50%;
  float: right; */
  text-align: right;
}

.dataTables_paginate a.paginate_button {
  padding: 4px 8px;
  border: 1px solid var(--light);
  font-weight: 400;
  font-size: 13px;
  line-height: 100%;
  color: var(--blue);
  cursor: pointer;
  margin-right: 5px;
}

.dataTables_paginate a.current {
  background: var(--blue);
  color: var(--white);
  border: none;
}

.dataTables_paginate a.paginate_button:hover {
  text-decoration: none;
}

.tblData_wrapper .dataTables_info {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--font-color);
}

/* .stripped__table table tr th {
  width: auto !important;
} */

/* .no-search {
  width: auto !important;
} */
/* .user-images {
  display: flex;
  align-items: center;
  margin: auto;
  margin-top: 13%;
} */
.user-images img {
  height: 20px;
  width: 20px;
}

.user-images img:not(:first-child) {
  margin-left: -10px;
}

table.dataTable thead > tr > th.sorting_asc:before {
  opacity: 6 !important;
}

table.dataTable thead > tr > th.sorting_desc:after {
  opacity: 6 !important;
}

.stripped__table .form-select {
  border: none !important;
  background-color: transparent !important;
  font-size: 12px !important;
  line-height: 120%;
  padding: 2px;
  height: 20px;
}

.stripped__table .form-select:focus {
  box-shadow: none;
  outline: none;
}

/* Login page css  */

.login-main {
  position: relative;
  background: #f7f7ff url("../Images/LogIn/loginbg.png") no-repeat top left;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  background-size: 50% 88%;
}

.login-right-main {
  background: #ffffff;
  box-shadow: 0px 0px 50px rgba(138, 149, 179, 0.2);
  border-radius: 10px;
  padding: 40px 48px;
  max-width: 650px;
  margin: auto;
}

.login-left {
  display: flex;
  align-items: center;
}

.login-form-title {
  text-align: center;
}

.login-form-title img {
  margin-bottom: 30px;
}

.login-form-title h2 {
  font-weight: 400;
  font-size: 30px;
  line-height: 35px;
  color: var(--font-color);
  margin-bottom: 20px;
}

.login-form .show-password .password-group {
  height: 60px;
}

.login-form-title p {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: rgb(32 40 66 / 50%);
  margin-bottom: 35px;
  max-width: 372px;
  margin-left: auto;
  margin-right: auto;
}

.login-form label {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 10px !important;
}

.login-form input {
  padding: 10px;
  font-weight: 400;
  font-size: 20px;
  line-height: 100%;
  height: 60px;
}

.login-form input::placeholder {
  color: #bcbec6;
}

.login-form .from-groups:not(:last-child) {
  margin-bottom: 30px;
}

.login-form .from-groups:last-child {
  margin-bottom: 20px;
}

.login-right-main .forgot-password p {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--blue);
  text-align: end;
  margin-bottom: 50px;
  cursor: pointer;
}

.login-right-main .login-buttons button {
  padding: 19px;
  width: 100%;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  text-decoration: none !important;
}

.login-right-main .login-buttons p {
  margin-bottom: 20px;
  margin-top: 20px;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: var(--font-color);
}

.login-right-main .login-buttons .loginGplusIcon {
  margin-right: 24px;
}

.login-right-main .red-btn:hover .loginGplusIcon {
  filter: brightness(0);
}

/* home page dashboard css  */

.dashbord-left-top-sec {
  border-radius: 20px;
  background: #e1e8ff;
  display: flex;
  justify-content: space-between;
  max-height: 200px;
}

.db-left-top-right-sec img {
  width: 100%;
  position: relative;
  bottom: -23px;
}

.dashbord-left-top-sec h1 {
  font-weight: 900;
  font-size: 40px;
  line-height: 47px;
  color: var(--font-color);
}

.dashbord-left-top-sec p {
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  color: var(--font-color);
}

.db-left-top-left-sec {
  padding: 58px 0 58px 58px;
}

.db-left-top-right-sec {
  padding-right: 56px;
}

.dbBoxes {
  padding: 30px;
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #cfd9f8;
  border-radius: 20px;
  align-items: flex-end;
  height: 200px;
}

.db-bottom-sec {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.dashboard-left-bottom-sec {
  margin-top: 30px;
}

.side-arrow img {
  background: rgb(53 101 252 / 20%);
  border-radius: 50%;
  padding: 10px;
}

.dbBoxImgTitle h3 {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: var(--font-color);
}

.dbBoxImgTitle h3 span {
  font-weight: 400;
}

.dbBoxImgTitle .dbBoxImg {
  margin-bottom: 20px;
}

.dbBoxes:hover {
  background: #cfd9f8;
  text-decoration: none;
}

.db-right-top {
  background: linear-gradient(
    180deg,
    var(--blue) 0%,
    rgba(255, 255, 255, 0) 57.67%
  );
  background-image: url(../Images/Home-dashboard/profilebg.png);
  background-repeat: no-repeat;
  padding: 62px 62px 50px 62px;
  text-align: center;
  border-radius: 20px;
  background-size: cover;
  margin-bottom: 30px;
}

.db-right-top-profile-img {
  height: 104px;
  width: 104px;
  border: 2px solid var(--white);
  border-radius: 50%;
  object-fit: cover;
  outline: 2px solid rgba(255, 255, 255, 0.2);
  outline-offset: 15px;
  margin-bottom: 10px;
  position: relative;
}

/* @keyframes movingOutline {
  0% {
    outline-offset: 0;
  }

  50% {
    outline-offset: 20px;
  }

  100% {
    outline-offset: 0;
  }
} */

/* .animate-outline {
  animation: movingOutline 2s linear infinite;
  Adjust the duration (2s) as needed
} */

.db-right-top .profileCall,
.db-right-top .profileMail {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  margin-bottom: 10px;
}

.db-right-top h3 {
  color: var(--white);
  font-weight: 900;
  font-size: 24px;
  line-height: 28px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.db-right-top p {
  margin: 0;
  color: var(--white);
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
}

.db-right-top .secondary-btn {
  color: var(--blue);
  border-radius: 10px;
  margin-top: 20px;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
}

.db-right-top .secondary-btn:hover {
  color: black;
  background: var(--white);
  border-color: #f7f7ff;
}

.db-right-bottom {
  background-image: url(../Images/Home-dashboard/temperaturebg.png);
  background-repeat: no-repeat;
  padding: 40px 30px 67px 30px;
  background-size: cover;
  border-radius: 20px;
}

.cityTemp {
  display: flex;
  justify-content: space-between;
  color: var(--white);
  margin-bottom: 10px;
  padding-right: 15px;
}

.dayTemp {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dayTemp:not(:last-child) {
  margin-bottom: 25px;
}

.dayTemp h4,
.dayTemp p {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 22px;
  line-height: 21px;
  color: var(--white);
}

.dayWithIcon {
  display: flex;
  align-items: center;
  grid-gap: 10px;
}

.dayViseTemp {
  background: rgb(28 52 114 / 35%);
  border-radius: 45px;
  padding: 32px 49px;
}

.home-dashboard-main .col-left {
  width: 70%;
}

.home-dashboard-main .col-right {
  width: 30%;
}

.cityTemp h3 {
  font-weight: 900;
  font-size: 28px;
  line-height: 33px;
  color: var(--white);
}

.cityTemp h1 {
  color: var(--white);
  font-weight: 900;
  font-size: 80px;
  line-height: 94px;
}

/* configuration page css  */

.asideConfigurationMain,
.CRM-aside-main {
  /* box-shadow: 0px 0px 50px rgba(138, 149, 179, 0.1); */
  position: fixed;
  left: 0;
  top: 81px;
  height: calc(100vh - 80px);
  z-index: 11;
  width: 306px;
  padding: 0 20px;
  background: #e1e8ff;
  overflow: auto;
}

.asideConfigurationMain .accordion,
.asideConfigurationMain .accordion button,
.asideConfigurationMain .accordion .accordion-body,
.CRM-aside-main .accordion,
.CRM-aside-main .accordion button,
.CRM-aside-main .accordion .accordion-body {
  background: transparent !important;
}

.accordion-item:first-of-type .accordion-button,
.accordion-item:last-of-type .accordion-button.collapsed,
.accordion-item:last-of-type,
.accordion-item:first-of-type {
  border-radius: 0;
  border: none !important;
  box-shadow: none !important;
}

.asideConfigurationMain .accordion .accordion-body,
.CRM-aside-main .accordion .accordion-body {
  padding: 0px 20px 20px 35px !important;
}

.configurationAccordionHeader img {
  margin-right: 13.5px;
  filter: brightness(0);
}

.configurationAccordionHeader button {
  padding: 10px 0 !important;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: var(--font-color);
}

.asideConfigurationMain .accordion-item,
.CRM-aside-main .accordion-item {
  border: none;
  border-bottom: 1px solid #adc0fe !important;
  background: transparent;
}

.asideConfigurationMain .configurationAccordionHeader button:after {
  flex-shrink: 0;
  width: 11px !important;
  height: 7px !important;
  content: "";
  right: 0 !important;
  top: 0 !important;
  background: transparent url(../Images/Configuration/accordionArrow.svg)
    no-repeat !important;
  transition: var(--bs-accordion-btn-icon-transition);
}

.asideConfigurationMain .accordion-button::after {
  display: none;
}

.asideConfigurationMain
  .accordion-item
  .configurationAccordionHeader.having-children
  button::after {
  display: block;
  flex-shrink: 0;
  width: 11px !important;
  height: 7px !important;
  content: "";
  right: 0 !important;
  top: 0 !important;
  background: transparent url(../Images/Configuration/accordionArrow.svg)
    no-repeat !important;
  transition: var(--bs-accordion-btn-icon-transition);
}

/* .CRM-aside-main
  .accordion-item:nth-child(1)
  .configurationAccordionHeader
  button::after,
.CRM-aside-main
.accordion-item:nth-child(7)
.configurationAccordionHeader
button::after,
.CRM-aside-main
.accordion-item:nth-child(8)
.configurationAccordionHeader
button::after {
    flex-shrink: 0;
    width: 11px !important;
    height: 7px !important;
    content: "";
    right: 0 !important;
    top: 0 !important;
    background: transparent url(../Images/Configuration/accordionArrow.svg) no-repeat !important;
    transition: var(--bs-accordion-btn-icon-transition);
}

.CRM-aside-main
  .accordion-item:nth-child(5)
  .configurationAccordionHeader
  button::after,
.CRM-aside-main
  .accordion-item:nth-child(8)
  .configurationAccordionHeader
  button::after {
  display: none;
} */

/* .asideConfigurationMain
  .accordion-item:nth-child(3)
  .configurationAccordionHeader
  button::after,
.asideConfigurationMain
  .accordion-item:nth-child(4)
  .configurationAccordionHeader
  button::after {
  display: none;
} */

.asideConfigurationMain button:focus:not(:focus-visible),
.CRM-aside-main button:focus:not(:focus-visible) {
  box-shadow: none;
}

.asideConfigurationMain .accordion-body ul,
.CRM-aside-main .accordion-body ul {
  list-style-type: none;
}

.asideConfigurationMain .accordion-body ul li:not(:last-child),
.CRM-aside-main .accordion-body ul li:not(:last-child) {
  margin-bottom: 10px;
}

.asideConfigurationMain .accordion-body ul li a,
.CRM-aside-main .accordion-body ul li a {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: rgb(32 40 66 / 80%);
  padding: 0 !important;
  border-bottom: none;
}

.asideConfigurationMain .accordion-body ul li a.active,
.asideConfigurationMain .accordion-body ul li a:hover,
.asideConfigurationMain .active,
.asideConfigurationMain a:hover,
.CRM-aside-main .accordion-body ul li a.active,
.CRM-aside-main .accordion-body ul li a:hover,
.CRM-aside-main .active,
.CRM-aside-main a:hover {
  color: var(--blue);
}

.asideConfigurationMain .accordion-button:not(.collapsed) {
  background: #e1e8ff;
  box-shadow: none;
}

.asideConfigurationMain .accordion-button:hover {
  color: var(--blue);
}

/* .asideConfigurationMain .accordion-button:not(.collapsed) img {
  filter: brightness(0);
}
.asideConfigurationMain .accordion-button.collapsed img {
  filter: brightness(1.9);
} */

.asideConfigurationMain a:hover {
  text-decoration: none !important;
  color: var(--blue);
}

.config-page-body,
.revenue-management-page-body,
.crm-page-body,
.operation-page-body {
  --bs-gutter-x: 1.5rem;
  /* margin: 80px 0 0 306px; */
  min-height: calc(100vh - 82px);
  padding: 30px calc(30px - calc(var(--bs-gutter-x) * 0.5));
  background: #fcfcfc;
  /* background: var(--white); */
  position: relative;
  width: calc(100vw - 306px);
  margin-left: auto;
  top: 0;
}

.config-page-body-without-sidebar,
.revenue-management-page-body-without-sidebar,
.crm-page-body-without-sidebar,
.operation-page-body-without-sidebar {
  /* --bs-gutter-x: 1.5rem;
  min-height: calc(100vh - 81px);
  padding: 30px calc(30px - calc(var(--bs-gutter-x) * 0.5));
  background: var(--white);
  position: relative;
  margin-left: auto;
  top: 80px; */
  /* margin: 80px 0 0 80px; */
  /* --bs-gutter-x: 1.5rem;
  min-height: calc(100vh - 81px);
  padding: 30px calc(30px - calc(var(--bs-gutter-x) * 0.5));
  position: relative;
  top: 80px;
  margin-left: auto;
  width: calc(100vw - 80px); */
  --bs-gutter-x: 1.5rem;
  margin-left: auto;
  min-height: calc(100vh - 81px);
  background: #fcfcfc;
  padding: 30px calc(30px - var(--bs-gutter-x) * 0.5);
  position: relative;
  top: 0;
  margin-left: auto;
  width: calc(100vw - 80px);
}

.mainRoot::-webkit-scrollbar {
  display: none !important;
  width: 0 !important;
}

.client-setting-heading h2 {
  font-weight: 700;
  font-size: 17px;
  line-height: 23px;
  color: var(--font-color);
  margin: 0;
}

.configurationMain .lblue-badge,
.configurationMain .lgreen-badge,
.configurationMain .active-badge {
  border-radius: 2px;
}

.configbasicInfoDetail {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  align-items: center;
}

.configbasicInfoDetail:last-child {
  padding: 15px 0 5px 0;
}

.configbasicInfoDetail:not(:last-child) {
  border-bottom: 1px solid #caced9;
}

.configbasicInfoDetail p {
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  color: var(--font-color);
  margin-bottom: 0;
}

.configbasicInfoDetail p span.dot-status {
  position: relative;
  color: var(--yellow);
}

.configbasicInfoDetail p span.dot-status::before {
  position: absolute;
  content: "";
  left: -15px;
  top: 7px;
  width: 8px;
  height: 8px;
  background: var(--yellow);
  border-radius: 50%;
}

.configurationBasicInfo {
  padding: 20px 20px 10px 20px;
  background: #f5f7ff;
  border: 1px solid #cfd9f8;
  border-radius: 20px;
  position: sticky;
  top: 81px;
  z-index: 1;
}

.configActiveSubscriptionPlanDetail {
  display: flex;
  justify-content: space-between;
}

.configActiveSubscription
  .configActiveSubscriptionPlan
  p
  span.dot-status-black {
  position: relative;
  color: var(--black);
}

.configActiveSubscriptionPlanRedText {
  padding-left: 40px;
  color: var(--red);
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
}

.configActiveSubscription
  .configActiveSubscriptionPlan
  p
  span.dot-status-black::before {
  position: absolute;
  content: "";
  left: -15px;
  top: 7px;
  width: 8px;
  height: 8px;
  background: var(--black);
  border-radius: 50%;
}

.configActiveSubscriptionTitle {
  display: flex;
  justify-content: space-between;
}

.configActiveSubscriptionTitle h3 {
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: var(--font-color);
}

.configActiveSubscription {
  padding: 30px 30px 10px 30px;
  border: 1px solid #cfd9f8;
  border-radius: 20px;
  margin-top: 30px;
}

.configActiveSubscriptionTitle button {
  border-radius: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  padding: 6px;
}

.configActiveSubscriptionPlan p {
  margin-bottom: 0;
}

.configActiveSubscriptionPlanDetail p:not(:last-child) {
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--font-color);
}

.configActiveSubscriptionPlan {
  border-bottom: 1px solid #caced9;
  padding: 30px 0 20px 0;
}

.configActiveSubscriptionBill h3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: var(--font-color);
  margin-top: 30px;
}

.configActiveSubscriptionBill p {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--font-color);
  margin-bottom: 0;
}

.configActiveSubscriptionBillDetail {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

.configActiveSubscriptionBillDetail:not(:last-child) {
  border-bottom: 1px solid #caced9;
}

.ConfigpropertiesWrapper {
  display: flex;
  align-items: center;
  background: #f5f7ff;
  border: 1px solid #bbcafe;
  border-radius: 10px;
  max-height: 202px;
  overflow: hidden;
}

.ConfigpropertiesImg,
.ConfigpropertiesWrapper .placeHolderConfig {
  width: 405px;
  height: 200px;
  object-fit: cover;
}

.ConfigpropertiesWrapper:not(:last-child) {
  margin-bottom: 20px;
}

.ConfigpropertiesContent {
  padding: 30px;
  width: calc(100% - 405px);
}

.ConfigpropertiesContentInner:not(:last-child) {
  grid-gap: 10px;
}

.ConfigpropertiesContentInner:last-child {
  /* justify-content: space-between; */
  margin-left: 8px;
  grid-gap: 40px;
}

.ConfigpropertiesContentInner {
  display: flex;
  align-items: center;
}

.ConfigpropertiesContentInner p {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
}

.ConfigpropertiesContentInner:not(:last-child) {
  margin-bottom: 15px;
}

/* .ConfigpropertiesImg {
  width: 379px;
  height: 202px;
} */
.ConfigpropertiesImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ConfigpropertiesTitle {
  margin-bottom: 25px;
}

.ConfigpropertiesTitle h3 {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: var(--font-color);
}

.client-setting-modal-inputs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.form-groups-radio {
  display: flex;
  flex-wrap: wrap;
}

.form-groups-radio-btn label {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
  color: var(--font-color);
}

.meghaDatePicker
  .react-datepicker-wrapper
  .react-datepicker__input-container
  input {
  font-size: 14px;
}

/* .form-groups.form-groups-radio-btn {
  margin-top: -6px;
  margin-bottom: 12px;
} */

.form-groups-radio-btn
  .form-groups-radio
  .form-radio:not(:last-child)
  .form-check-label {
  padding-right: 15px;
  border-right: 1px solid var(--font-color);
  margin-right: 15px;
}

.modal-header {
  padding: 12px 20px;
  background: #eaefff;
  position: sticky;
  top: 0;
  z-index: 9999;
}

.modal-body {
  max-height: calc(100vh - 270px);
  overflow: auto;
}

.modal-title {
  font-weight: 600;
  font-size: 17px;
  line-height: 17px;
  color: var(--font-color);
}

.modal-footer {
  border: none;
  background: var(--white);
  position: sticky;
  bottom: 0;
  z-index: 9999;
}

.client-setting-modal-inputs .flotingLableGroup:nth-child(odd),
.client-setting-modal-inputs .from-groups:nth-child(odd) {
  margin-right: 20px;
  margin-bottom: 10px;
}

.ClientSettingModalMain .label-radio {
  font-weight: 400;
  /* font-size: 16px;
  line-height: 19px; */
  color: var(--font-color);
}

.configurationBasicInfoTitle h3 {
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: var(--font-color);
}

/* General Term Setup page configuration css*/

.singleColumnTableHead {
  padding: 10px 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  color: var(--font-color);
  background: rgb(53 101 252 / 10%);
  border-radius: 5px;
  align-items: center;
}

.singleColumnTableBody {
  padding: 15px 20px;
  background: #f5f7ff;
  height: calc(80vh - 30px);
  overflow: auto;
}

.singleColumnTableBody p {
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  padding: 8px 0px;
}

.activeCategory {
  color: var(--blue) !important;
}

.allOperationAccountMain .singleColumnTableBody {
  padding: 0 0px 15px 20px;
}

/* .generalTermSetupMain .dataTables_info,
.generalTermSetupMain .dataTables_paginate,
.userManagementModalMain .dataTables_info,
.userManagementModalMain .dataTables_paginate,
.LoginLogsMain .dataTables_info,
.LoginLogsMain .dataTables_paginate,
.ActionLogsMain .dataTables_info,
.ActionLogsMain .dataTables_paginate {
  display: none;
} */
.generalTermSetup-heading {
  margin-bottom: 12px;
}

.singleColumnTable {
  height: calc(100% - 55px);
}

.generalTermSetupButtons {
  display: flex;
  justify-content: end;
  grid-gap: 5px;
}

.generalTermSetupsingleColumnTable .singleColumnTableBody {
  padding: 26px 20px 15px 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--font-color);
}

.singleColumnTableBody ul li:not(:last-child) {
  margin-bottom: 10px;
}

.generalTermSetup-heading h2 {
  font-weight: 700;
  font-size: 17px;
  margin: 5px 0 0 0;
  line-height: 17px;
  color: var(--font-color);
}

.allCLeanBoxesInner p {
  font-size: 13px;
  line-height: 100%;
}

/* .emailConfigurationMain .dataTables_info,
.roleManagementMain .dataTables_info {
  position: fixed;
  bottom: 22px;
}
.emailConfigurationMain .dataTables_paginate,
.roleManagementMain .dataTables_paginate {
  position: fixed;
  bottom: 22px;
  right: 25px;
} */
.EmailTempConfigModalFloatLabel .flotingLableGroup,
.EmailTempConfigModalFloatLabel .from-groups {
  width: 50%;
}

.EmailTempConfigModalFloatLabel {
  grid-gap: 20px;
}

.rdw-editor-wrapper {
  border: 1px solid #cfd9f8;
  border-radius: 5px;
  overflow: hidden;
}

.notranslate.public-DraftEditor-content {
  height: 150px;
  overflow: auto;
}

.public-DraftEditor-content {
  overflow: hidden;
}

.EmailTempConfigModalMain .modal-footer {
  border: none;
  margin-top: 30px;
}

.EmailTempConfigModalMain .textEditor label {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  padding-left: 15px;
  color: var(--font-color);
}

.rdw-option-wrapper {
  padding: 3px !important;
  min-width: 30px !important;
  height: 23px !important;
}

/* .employeeManagementMain .dataTables_info,
.employeeManagementMain .dataTables_paginate {
  margin-top: 44px;
} */

.AddEmployeeManagementModalMain
  .react-datepicker-popper[data-placement^="bottom"] {
  z-index: 1111;
}

.AddEmployeeManagementModalMain
  .react-datepicker-wrapper
  .react-datepicker__input-container
  input {
  width: 100%;
}

.AddEmployeeManagementModalMain .form-groups-radio-btn label {
  font-weight: 400;
}

.SingleDateSummaryModalPopoverMain {
  max-width: 700px;
}

.employee-detail-top-bg {
  background: var(--blue-900);
  padding: 30px;
  height: 210px;
}

.employee-img-name-email {
  display: flex;
  align-items: center;
  grid-gap: 25px;
}

.employee-name-email h3 {
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  color: #ffffff;
}

.employee-name-email p {
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: #ffffff;
}

.profile-address {
  display: flex;
  grid-gap: 10px;
  align-items: center;
  justify-content: right;
  margin-bottom: 10.5px;
}

.profile-address:last-child {
  /* padding-right: 8px; */
  margin-bottom: 0;
}

.profile-address p {
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  color: #ffffff;
  margin-bottom: 0;
}

/* .employee-detail-links {
  margin-top: 25px;
} */
.employee-detail-links a {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #cccccc;
}

.tab-one-content-inputs {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
}

/* 
.tab-one-content-inputs input {
  width: 100%;
} */

.react-datepicker-popper {
  z-index: 1111 !important;
}

.employeeDetailMain .tab-content {
  background: #ffffff;
  border-radius: 5px;
  padding: 20px 25px;
  margin-top: -40px;
  box-shadow: 0 2px 5px 1px rgba(64, 60, 67, 0.16);
  max-height: 100%;
}

.disabled input.form-control {
  background: #f5f7ff;
}

/* .employeeDetailMain {
  margin: -30px -18px;
} */
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background: var(--blue);
}

.nav-link:focus,
.nav-link:hover {
  color: var(--blue);
}

.DeleteItemModalMain .modal-content {
  width: 382px;
  margin: auto;
}

.DeleteItemModalMain .modal-body {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
}

.delete-modal-content-btns {
  display: flex;
  grid-gap: 15px;
  justify-content: center;
}

.delete-modal-content h2 {
  font-weight: 600;
  font-size: 18px;
  line-height: 100%;
  color: #111111;
  margin-bottom: 12px;
}

.delete-modal-content p {
  font-weight: 400;
  font-size: 15px;
  line-height: 100%;
  color: #111111;
  margin-bottom: 15px;
}

.delete-modal-content img {
  margin-bottom: 8px;
}

.filter-btn {
  padding: 8px;
  display: flex;
  min-height: 41px;
  align-items: center;
  justify-content: center;
}

.filter-btn img {
  margin: 0;
}

.filter-add-btns {
  display: flex;
  grid-gap: 10px;
}

.role-filter-modal .modal-dialog {
  position: absolute;
  top: 20%;
  right: 10%;
}

.role-filter-modal .modal-footer {
  justify-content: space-between;
}

.role-filter-modal .modal-content {
  width: 290px;
  max-height: 290px;
  padding: 15px 0;
}

.filter-area {
  position: relative;
}

.cotent-filter {
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  width: 290px;
  right: 0;
  padding: 26px 15px 15px 15px;
  z-index: 1111;
}

.buttons-filter {
  margin-top: 30px;
}

.buttons-filter button {
  width: 100%;
}

.buttons-filter button:not(:last-child) {
  margin-right: 5px;
}

.userNumberDeatailContentInner {
  display: flex;
  grid-gap: 10px;
}

span.user-area {
  position: relative;
}

.userNumberDeatailMain {
  position: absolute;
  left: 40px;
  top: 0;
  z-index: 1111;
  width: 580px;
  background: var(--white);
  box-shadow: 0px 1px 30px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.userNumberDeatailMain .modal-body {
  padding: 20px;
}

.userNumberDeatailContent {
  padding: 15px;
  background: rgba(53, 101, 252, 0.03);
  border: 1px solid #cfd9f8;
  border-radius: 10px;
}

.userNumberDeatailContent p {
  margin-bottom: 0;
}

.userNumberDeatailContentInner h3 {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: var(--font-color);
}

.userNumberDeatailContentInner p.userParaEmail {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: rgb(32 40 66 / 80%);
  margin-bottom: 10px;
}

.userNumberDeatailContentInner p.userParaWithNumber {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: rgba(32, 40, 66, 0.8);
}

.userNumberDeatailContentInner p.userParaWithNumber span {
  color: var(--blue);
}

.userParaWithProperties span {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  background: rgba(53, 101, 252, 0.12);
  border-radius: 5px;
  padding: 5px 8px;
  color: var(--font-color);
  margin-right: 5px;
}

.userNumberDeatailMain .modal-header .btn-close {
  padding: 8px;
  opacity: 1;
}

.userNumberDeatailImg {
  width: 15%;
}

.userNumberDeatailContent:not(:last-child) {
  margin-bottom: 10px;
}

.RoleMangementModalMain-inner {
  display: flex;
  grid-gap: 20px;
}

/* .RoleMangementModalMain .modal-body {
  padding: 31px 20px 20px 20px;
} */

.RoleMangementModalMain
  .modal-body
  .RoleMangementModalMain-inner-left
  .from-groups
  .form-select {
  width: 300px;
  /* margin-bottom: 20px; */
}

.RoleMangementModalMain-inner-right {
  width: 100%;
  margin-bottom: 12px;
}

/* .client-setting-modal-inputs .form-groups-radio-btn label:nth-child(1) {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: var(--font-color);
} */

.userManagementModalMain-innner {
  background: var(--light);
  padding: 30px;
  border-radius: 5px;
  position: relative;
}

/* .allInputsUserManagementModal input::placeholder {
  color: var(--white);
} */

/* .allInputsUserManagementModal .p-multiselect .p-multiselect-label {
  color: var(--white) !important;
} */

.userManagementModalMain-innner .user-closebtn {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.userManagementModalMain-innner-img {
  text-align: center;
}

.modal-90w {
  max-width: none !important;
  width: 85%;
}

/* .userManagementModalMain-innner-input {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
} */

/* .form-control, .form-select, .p-multiselect {
  background: transparent !important;
} */

/* .userManagementModalMain-innner input, .userManagementModalMain-innner .form-select {
  color: var(--white) !important;
} */

/* .userManagementModalMain-innner label {
  color: var(--white) !important;
} */

.userManagementModalMain-bottom {
  padding-top: 25px;
}

.userManagementModalMain-bottom .select__control {
  margin-bottom: 15px;
}

/* .userManagementModalMain-innner-input
  input:not([type="radio"], [type="checkbox"]):focus {
  background: var(--white);
} */

.userManagementModalMain .modal-content {
  margin-top: 75px;
}

.EditUserManagementModalMain-inputs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.EditUserManagementModalMain-inputs .flotingLableGroup:nth-child(odd) {
  margin-right: 20px;
}

.EditUserManagementModalMain-inputs .flotingLableGroup:nth-child(even) {
  margin-bottom: 10px;
}

.old-new-bottom pre {
  border: 1px solid rgb(205 205 205 / 50%);
  margin: 10px 0 0;
  padding: 10px;
}

/* .old-new-bottom-left {
  border-right: 1px solid rgb(205 205 205 / 50%);
  padding: 15px;
} */

/* .old-new-bottom-right, .old-new-bottom-left {
  padding: 15px;
} */

.old-new,
.old-new-bottom {
  display: flex;
  flex-direction: row;
  overflow: auto;
}

.old-new-bottom p {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--black);
  margin-bottom: 7px;
}

.old-new-bottom span {
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  color: var(--black);
}

.portfolioSetupMain .default-badge,
.propertySetupMain .default-badge {
  font-size: 14px;
  line-height: 15px;
}

/* .portfolioSetupMain tbody,
.portfolioSetupMain td,
.portfolioSetupMain tfoot,
.portfolioSetupMain th,
.portfolioSetupMain thead,
.portfolioSetupMain tr {
  width: 11%;
} */

/* .PortfolioSetupModalMain .from-groups input {
  background: #f5f7ff;
} */

/* #tblDataPropertySetup thead tr > th:nth-child(1),
#tblDataPropertySetup thead tr > th:nth-child(2),
#tblDataPropertySetup thead tr > th:nth-child(5),
#tblDataPropertySetup thead tr > th:nth-child(8) {
  width: 7% !important;
} */
.LoginLogsMain
  .stripped__table
  table
  thead
  tr
  th:last-child
  .search-group-for-table {
  margin-left: auto;
}

/* #tblDataEmployeeManagement thead tr th {
  width: 13% !important;
} */
/* .stripped__table table tbody tr:nth-child(odd) {
  background: #f5f6fc;
} */

/* .stripped__table table tbody tr:nth-child(even) {
  background: var(--white);
} */

/* setting page css  */

.allSettingsBox {
  /* display: flex;
  justify-content: space-between; */
  border-radius: 5px;
  border: 1px solid rgba(53, 101, 252, 0.2);
  background: rgba(53, 101, 252, 0.1);
  padding: 15px;
}

.ViewPropertySetupMain .allSettingsMain {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px;
}

.allSettingsMain {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px;
}

.crmDashboardMain .allSettingsMain {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 20px;
}

.rfpMain .allSettingsMain {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}

.allSettingsBox p {
  margin: 0;
}

.allSettingsBox p:nth-child(1) {
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: var(--font-color);
  margin-bottom: 12px;
}

.allSettingsBox p:nth-child(2) {
  /* font-weight: 700;
    font-size: 16px;
    line-height: 100%;
    margin-top: 5px;
    color: var(--blue); */

  color: var(--font-color);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.allSettingsBoxContent .valueForDashboard {
  color: var(--font-color);
  font-size: 32px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
  margin-bottom: 0 !important;
}

.allSettingsBoxContent .revLossForDashboard {
  color: var(--red) !important;
  font-size: 18px !important;
  font-style: normal !important;
  line-height: normal !important;
  font-weight: 400 !important;
}
.allSettingsBoxContent .subValueForDashboard {
  font-size: 18px !important;
  font-style: normal !important;
  line-height: normal !important;
  font-weight: 400 !important;
}

.allSettingsBox .titleForDashboard {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 100% !important;
  color: var(--font-color) !important;
}

.crmactivities-settings-wrapper {
  margin-top: 20px;
  margin-bottom: 20px;
}

.allSettingsBox p span {
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  color: #009a4f;
}

/* .allSettingsBoxContent {
  padding-top: 36px;
} */

.Allactions a {
  margin-right: 10px;
}

.allSettingsLocation {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  grid-gap: 19px;
}

.allSettingsLocation p {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: var(--black);
}

.crmDashboardMain .chart-data,
.ViewPropertySetupMain .chart-data {
  /* background: #f5f7ff; */
  background: var(--white);
  border: 1px solid #cfd9f8;
  border-radius: 5px;
  padding: 25px;
  height: 100%;
}

.allSettingsBoxImg img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.allSettingsBoxImg {
  width: 32px;
  height: 32px;
}

.chart-data-title {
  display: flex;
  justify-content: space-between;
}

.funnel-chart {
  position: relative;
  top: calc(50%);
  left: 0;
  transform: translateY(-50%);
  /* width: 300px; */
  width: 100%;
  max-width: 380px;
  /*---changed by hiren--------------*/
  margin: 0 auto 50px auto;
  overflow: hidden;
}

.funnel-chart::before {
  border-color: var(--white);
  border-top-color: rgb(245, 247, 255);
  border-right-color: rgb(245, 247, 255);
  border-top-color: rgb(255, 255, 255);
  border-right-color: rgb(255, 255, 255);
  border-top-color: white;
  border-right-color: white;
  border-style: solid;
  border-top-color: transparent;
  box-sizing: border-box;
  content: "";
  position: absolute;
  border-width: 0 4rem 35rem 0;
  border-right-color: transparent;
  left: 0;
  top: 0;
  z-index: 1;
}

.funnel-chart::after {
  /* border-color: #f5f7ff; */
  border-color: var(--white);
  border-top-color: rgb(255, 255, 255);
  border-left-color: rgb(255, 255, 255);
  border-top-color: white;
  border-left-color: white;
  border-style: solid;
  border-top-color: transparent;
  box-sizing: border-box;
  content: "";
  position: absolute;
  top: 0;
  border-width: 0 0 35rem 4rem;
  border-left-color: transparent;
  right: 0;
  z-index: 1;
}

.chart-data-title h5 {
  font-weight: 600;
  font-size: 17px;
  line-height: 100%;
  color: var(--font-color);
}

.chart-data-title p {
  font-weight: 400;
  font-size: 17px;
  line-height: 100%;
  text-align: right;
  margin: 0;
  color: var(--font-color);
}

.funnel-chart-inner {
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  margin: auto;
  height: 54px;
  color: var(--white);
  grid-gap: 15px;
}

.funnel-chart-inner p,
.funnel-chart-inner h5 {
  margin: 0;
  font-size: 15px;
}

.funnel-chart-inner1 {
  background: #032694;
  width: 100%;
  margin-bottom: 5px;
}

.funnel-chart-inner1:nth-child(2) {
  background: rgba(3, 38, 148, 0.9);
}

.funnel-chart-inner1:nth-child(3) {
  background: rgba(3, 38, 148, 0.8);
}

.funnel-chart-inner1:nth-child(4) {
  background: rgba(3, 38, 148, 0.7);
}

.funnel-chart-inner1:nth-child(5) {
  background: rgba(3, 38, 148, 0.6);
}

.funnel-chart-inner1:nth-child(6) {
  background: rgba(3, 38, 148, 0.5);
}

.funnel-chart-inner1:nth-child(7) {
  background: rgba(3, 38, 148, 0.4);
}

.funnel-chart-inner1:nth-child(8) {
  background: rgba(3, 38, 148, 0.3);
}

.funnel-chart-inner1:nth-child(9) {
  background: rgba(3, 38, 148, 0.2);
}

/* 
.funnel-chart-inner1:nth-child(3n + 1) {
  background: #ff791d;
}

.funnel-chart-inner1:nth-child(3n + 2) {
  background: #9c4e98;
}

.funnel-chart-inner1:nth-child(3n + 3) {
  background: #a49d9d;
} */

/* .funnel-chart-inner::after {
  position: absolute;
  z-index: -1;
  content: "";
  right: -10%;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: inherit;
  transform: skewX(-15deg);
} */

/* .funnel-chart-inner::before {
  z-index: -1;
  content: "";
  position: absolute;
  left: -10%;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: inherit;
  transform: skewX(15deg);
} */

.AnticipatedValueBarChartTitle h2 {
  font-weight: 600;
  font-size: 17px;
  line-height: 23px;
  color: var(--black);
}

.AnticipatedValueBarChartMain {
  background: var(--white);
  border: 1px solid #cfd9f8;
  border-radius: 5px;
  padding: 25px;
  margin-top: 20px;
}

/* .fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-left:before {
  content: "" !important;
  color: #3457dc;
  background: url(../Images/Configuration/left.png) no-repeat;
  width: 10px;
  height: 14px;
  top: 14px;
  left: 14px;
  position: absolute;
}
.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-right:before {
  content: "" !important;
  color: #3457dc;
  background: url(../Images/Configuration/right.png) no-repeat;
  width: 10px;
  height: 14px;
  top: 14px;
  left: 17px;
  position: absolute;
}
.fc-toolbar-chunk .fc-button-group button:not(:last-child) {
  margin-right: 10px;
} */
.fullCalendarMain {
  border: 1px solid #cfd9f8;
  border-radius: 20px;
  padding-top: 25px;
  margin-top: 25px;
  height: 495px;
}

/* .fullCalendarMain .fc-media-screen {
  height: 100%;
}
.fullCalendarMain .fc-toolbar-chunk h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  color: var(--font-color);
}
.fc-toolbar-chunk button.fc-today-button {
  display: none;
}
.fc.fc-theme-standard .fc-toolbar .fc-button {
  padding: 8px 12px !important;
  border-radius: 50% !important;
  background: #e1e6f5 !important;
  border: none !important;
} */
.no-bg-tr th {
  background: var(--white);
}

.crmDashboardMain .stripped__table table tbody tr.table-even,
.ViewPropertySetupMain .stripped__table table tbody tr.table-even {
  background: #f5f7ff !important;
}

.crmDashboardMain .stripped__table th:last-child,
.ViewPropertySetupMain .stripped__table th:last-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.crmDashboardMain .stripped__table .table-even td:nth-child(1),
.ViewPropertySetupMain .stripped__table .table-even td:nth-child(1) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.crmDashboardMain .stripped__table .table-even td:last-child,
.ViewPropertySetupMain .stripped__table .table-even td:last-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ViewPropertySetupMain .stripped__table {
  border: 1px solid #d7e0fe;
  border-radius: 10px;
}

.ViewPropertySetupMain .stripped__table {
  margin-top: 20px;
}

.crmDashboardMain .stripped__table tfoot,
.ViewPropertySetupMain .stripped__table tfoot {
  background: rgb(53 101 252 / 10%);
  border-radius: 0 0 5px 5px;
}

.ViewPropertySetupAllTables {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px;
}

.event-date .fc-day-top {
  background-color: rgba(53, 101, 252, 0.12) !important;
}

.event-high-priority {
  background: #ffb800 !important;
  border: none !important;
  border-radius: 3px !important;
  padding: 4px 6px !important;
}

.event-high-priority * {
  color: var(--black) !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: var(--font-color);
}

.event-medium-priority {
  background: #4357a7 !important;
  border: none !important;
  border-radius: 3px !important;
  padding: 4px 6px !important;
}

.event-medium-priority * {
  color: var(--white) !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: var(--font-color);
}

.event-low-priority {
  background: #9bdbff !important;
  border: none !important;
  border-radius: 3px !important;
  padding: 4px 6px !important;
}

.event-low-priority * {
  color: var(--black) !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: var(--font-color);
}

.headerCalendarWrapper .fc-scrollgrid-sync-table tr td.fc-day {
  height: 154px !important;
}

.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-block-event {
  border: none !important;
}

.EmailConfigurationDataTableMain {
  overflow-x: unset !important;
}

.EmailConfigurationDataTableMain .table-wrapper {
  overflow-x: auto;
}

.table-header {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 10px;
}

.table-pagination div {
  display: flex;
}

.react-datepicker-wrapper {
  width: 100%;
}

/* table#generalTermSetupTblData {
  width: 100% !important;
} */
/* Revenue management css  */
.asideConfigurationMain.revenue-management-aside-main {
  /* box-shadow: 0px 0px 50px rgba(138, 149, 179, 0.1); */
  position: sticky;
  left: 0;
  top: 81px;
  height: calc(100vh - 81px);
  z-index: 11;
  width: 306px;
  padding: 0 20px;
  float: left;
  background: #e1e8ff;
  /* background: #C9E6F7; */
  overflow: auto;
}

.mainRoot
  .asideConfigurationMain.revenue-management-aside-main::-webkit-scrollbar {
  display: none !important;
  width: 0 !important;
}

.revenue-management-aside-main li a:first-child {
  padding: 10px 0 !important;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: var(--font-color);
  /* border-bottom: 1px solid #83C6EC; */
  border-bottom: 1px solid #bbcafe;
}

.revenue-aside-links {
  display: flex;
  align-items: center;
  grid-gap: 13.5px;
}

.revenue-portfolio-title h4 {
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 0;
  line-height: 100%;
  color: var(--font-color);
}

.revenue-portfolio-main {
  /* background: #ffffff;
  border: 1px solid #cfd9f8;
  border-radius: 10px;
  padding: 12px; */
  margin-bottom: 15px;
  margin-top: 10px;
}

.revenue-portfolio-main .nav-item a {
  padding: 10px 24px !important;
  color: var(--font-color);
  font-size: 14px;
  font-weight: 400;
}

.revenue-portfolio-main .nav-pills .nav-item a {
  padding: 5px 20px !important;
}

.revenue-portfolio-main .nav-pills {
  justify-content: space-between;
  margin-top: 15px;
}

.revenue-portfolio-tab-content-img {
  align-items: center;
  grid-gap: 10px;
}

.revenue-portfolio-tab-content-img p {
  margin-bottom: 0;
  color: var(--font-color);
  font-size: 14px;
  line-height: 100%;
  font-weight: 400;
}

.revenue-portfolio-tab-content-img img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.revenue-portfolio-main .tab-content {
  align-items: center;
  padding-top: 5px;
}

.revenue-portfolio-tab-icons img {
  width: 15px;
  height: 15px;
}

.revenue-portfolio-main .tab-content:has(.activeCategory) {
  border: 1px solid var(--blue);
}

.revenue-portfolio-main .tab-content {
  border: 1px solid #caced9;
  padding: 8px 12px;
  border-radius: 5px;
}

.revenue-portfolio-tab-icons {
  grid-gap: 10px;
}

.tabComponentLinks {
  margin-bottom: 5px;
}

.propertysettingTabs .nav-item a {
  color: var(--font-color);
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
}

.propertysettingTabs {
  margin-bottom: 20px;
}

.chart-and-values {
  display: flex;
  grid-gap: 10px;
  max-height: 70px;
  align-items: center;
}

.RevenueManagementTblImg {
  display: flex;
  grid-gap: 10px;
  align-items: center;
}

.image-select.circularImage {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  object-fit: cover;
  border: none;
  padding: 0;
  margin-bottom: 0;
}

.stripped__table table tbody td .RevenueManagementTblImg img {
  max-height: 55px !important;
  max-width: 55px !important;
  height: 30px !important;
  width: 30px !important;
  border-radius: 50%;
}

.allSettingsMain {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px;
}

*::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #f5f5f5;
}

/* .table>:not(caption)>*>* {
  padding: unset;
} */
.cotent-filter img {
  width: 20px;
  height: 20px;
}

.table-wrapper::-webkit-scrollbar,
.table-responsive::-webkit-scrollbar,
.modal-body::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #f5f5f5;
  border-radius: 10px;
}

.snapshotDoughnutChartMain {
  margin-bottom: 20px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #e1e8ff;
  background-color: #e1e8ff;
}

.table-wrapper::-webkit-scrollbar-thumb,
.table-responsive::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #e1e8ff;
  background-color: #e1e8ff;
}

.modal-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #e1e8ff;
  background-color: #cad5f9;
}

#tblDataRevenueManagementDashboard tbody tr td {
  vertical-align: middle;
}

/* table#tblDataRevenueManagementDashboard {
  width: auto !important;
} */

.revenue-management-aside-main a.active span,
.revenue-management-aside-main a:hover span {
  color: var(--blue);
}

.revenue-management-aside-main .active img,
.revenue-management-aside-main a:hover img,
.CRM-aside-main .active img,
.CRM-aside-main a:hover img {
  filter: invert(32%) sepia(59%) saturate(2263%) hue-rotate(214deg)
    brightness(97%) contrast(103%);
}

.last-updated-date {
  color: var(--font-color);
  font-size: 12px;
  margin-top: 10px !important;
}

.last-updated-date span:first-child {
  margin-right: 5px;
}

.last-updated-date:hover svg path {
  stroke: var(--blue);
}

.heading-menu-btns {
  padding: 8px 8px 8px 8px !important;
}

.heading-menu-btns a {
  display: inline-flex;
}

.heading-menu-btns {
  background: #e1e8ff;
  border-radius: 5px;
  color: var(--blue);
  padding: 8.5px 16px 8.5px 12px;
  border: none;
  display: flex;
  align-items: center;
  max-height: 34px;
}

.heading-menu-btns img {
  height: 20px;
  width: 20px;
  object-fit: cover;
}

.heading-menu-btns:hover,
.heading-menu-btns:focus-visible,
.heading-menu-btns:focus,
.heading-menu-btns:active {
  background: #e1e8ff;
  color: var(--blue);
  border: none;
  box-shadow: none;
}

.generalTermSetup-heading-btns {
  display: flex;
  grid-gap: 10px;
  align-items: center;
}

.generalTermSetup-heading-btns img {
  margin: 0;
}

.snapShotHorizontalChart h4 {
  color: var(--font-color);
  font-size: 14px;
  font-weight: 600;
  padding: 20px 20px 0 20px;
}

.snapShotHorizontalChart,
.snapShotBarWithLineChart,
.ThreeBarAndLineChartWrapper {
  border-radius: 13px;
  border: 1px solid #cfd9f8;
  /* background: #f5f7ff; */
  background: var(--white);
  margin-top: 20px;
}

.snapShotBarWithLineChartWrapper {
  padding: 20px;
}

/* .snapShotHorizontalChartInner {
  height: 470px;
} */

/* .snapShotMain .ViewPropertySetupTable .stripped__table {
  border-radius: 10px;
  border: 1px solid #d7e0fe;
} */

/* .snapShotMain .ViewPropertySetupTable .table-striped tfoot {
  background: #ebf0ff;
  border-radius: 0px 0px 9px 9px;
} */

.barWithLineChartTable table thead th,
.barWithLineChartTable table tbody th {
  color: var(--font-color);
  font-size: 14px;
  font-weight: 600;
}

.barWithLineChartTable table tbody td {
  color: rgb(32 40 66 / 80%);
  text-align: center;
  font-size: 13px;
}

.barWithLineChartTable .container {
  padding: 0;
}

/* 
.barWithLineChartTable table thead tr th {
  background: transparent;
}

.barWithLineChartTable table tbody tr:nth-child(odd) td,
.barWithLineChartTable table tbody tr:nth-child(odd) th {
  background: var(--white);
}

.barWithLineChartTable table tbody tr:nth-child(even) td,
.barWithLineChartTable table tbody tr:nth-child(even) th {
  background: #f5f7ff;
}

.barWithLineChartTable table {
  margin: 0;
} */
/* .barWithLineChartTable table {
  padding: 2px;
} */
/* .barWithLineChartTable table tbody tr:last-child {
  border-radius: 0px 0px 19px 19px;
}

.barWithLineChartTable table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 20px;
}

.barWithLineChartTable table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 20px;
} */

/* .snapShotMain
  .ViewPropertySetupAllTables
  .stripped__table
  tfoot
  tr
  td:first-child {
  border-bottom-left-radius: 10px;
}

.snapShotMain
  .ViewPropertySetupAllTables
  .stripped__table
  tfoot
  tr
  td:last-child {
  border-bottom-right-radius: 10px;
} */

.ThreeBarAndLineChartWrapper {
  padding: 20px;
  height: calc(100% - 20px);
}

/* .DoughnutChartMain {
  display: flex;
  align-items: center;
} */

.snapshotDoughnutChartMain h2 {
  color: var(--font-color);
  font-size: 17px;
  font-weight: 600;
}

.snapshotDoughnutChartMain {
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #cfd9f8;
  /* background: #f5f7ff; */
  background: var(--white);
  margin-top: 20px;
}

.snapshotDoughnutChartMainInner canvas {
  cursor: pointer;
}

.snapshotDoughnutChartMainInner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

table#snapshotFirstTable {
  /* width: 2250px !important; */
  margin-top: 20px;
}

/* table#snapshotFirstTable thead tr:first-child th:first-child {
  width: 6% !important;
} */

table#snapshotFirstTable thead tr th {
  vertical-align: middle;
}

.colspan-5-spacing {
  margin-right: 5px;
}

.snapshotFirstTableContainer {
  overflow-x: auto;
  max-width: 100%;
  margin-top: 20px;
}

/* .diff-bg-tr {
  background: #e2e7f6;
} */

.ForecastAndBudgetMain .ViewPropertySetupTable .stripped__table {
  border-radius: 10px;
  border: 1px solid #d7e0fe;
}

.ForecastAndBudgetMain .ViewPropertySetupTable .table-striped tfoot {
  background: #ebf0ff;
  border-radius: 0px 0px 9px 9px;
}

.ForecastAndBudgetMain
  .ViewPropertySetupAllTables
  .stripped__table
  tfoot
  tr
  td:first-child {
  border-bottom-left-radius: 10px;
}

.ForecastAndBudgetMain
  .ViewPropertySetupAllTables
  .stripped__table
  tfoot
  tr
  td:last-child {
  border-bottom-right-radius: 10px;
}

.ForecastAndBudgetMain .ViewPropertySetupAllTables {
  display: grid;
  grid-template-columns: 98px 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}

.ForecastAndBudgetMain
  .ViewPropertySetupAllTables
  .react-datepicker__input-container
  input {
  width: 96px;
  border: none !important;
  padding: 0 0 0 15px !important;
  border-radius: 5px 5px 0 0 !important;
  background: #ebf0ff;
}

.ForecastAndBudgetMain
  .ViewPropertySetupAllTables
  .react-datepicker__input-container {
  position: relative;
}

.ForecastAndBudgetMain
  .ViewPropertySetupAllTables
  .react-datepicker__input-container:after {
  content: "";
  background: url(../Images/Revenue-management/down.svg) no-repeat center
    center/cover;
  position: absolute;
  top: 8px;
  right: 10px;
  width: 18px;
  cursor: pointer;
  height: 18px;
}

/* 
table#RatePlanAndCompanyAnalysisTable tfoot {
  border-radius: 5px;
  background: #ebf0ff;
} */

table#RatePlanAndCompanyAnalysisTable tfoot td {
  font-weight: 500;
}

.barWithLineChartTable table tbody td {
  text-align: left;
}

.RatePlanAndCompanyAnalysisMain .barWithLineChartTable .last-total * {
  font-weight: 600;
}

/* Operation css  */

/* .operation-page-body {
  --bs-gutter-x: 1.5rem;
  margin: 80px 0 0 306px;
  min-height: 100vh;
  padding: 30px calc(30px - calc(var(--bs-gutter-x) * 0.5));
  background: var(--white);
} */

.asideOperationMain
  .accordion-item:first-child
  .configurationAccordionHeader
  button::after {
  display: none !important;
}

.asideOperationMain
  .accordion-item:nth-child(3)
  .configurationAccordionHeader
  button::after,
.asideOperationMain
  .accordion-item:nth-child(4)
  .configurationAccordionHeader
  button::after {
  display: unset !important;
}

.revenue-portfolio-tab-icons button,
#tblDataRevenueManagementDashboard .cotent-filter a {
  border: none;
  background: none;
  padding: 0;
}

#tblDataRevenueManagementDashboard .cotent-filter {
  position: absolute;
  border-radius: 5px;
  background: #fff;
  box-shadow: -1px 2px 15px 0px rgba(0, 0, 0, 0.15);
  width: 160px;
  right: 80px;
  padding: 12px 15px;
  z-index: 1111;
  display: flex;
  margin-top: 10px;
}

#tblDataRevenueManagementDashboard .cotent-filter a:not(:last-child) {
  margin-right: 15px;
}

#snapshotSecondTable_wrapper .toolbar .dataTables_filter {
  text-align: end !important;
}

/* #snapshotSecondTable_wrapper .toolbar .header {
  margin: auto;
} */
#snapshotSecondTable_wrapper .toolbar {
  border-radius: 5px;
  background: rgb(184 200 252 / 60%);
  padding: 9px 15px;
  width: 100%;
  margin-top: 20px;
  /* min-width: 80vw; */
  position: sticky;
  left: 0;
}

#snapshotSecondTable_wrapper .toolbar .reservation-text {
  color: var(--font-color);
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  /* max-width: 100%; */
}

#snapshotSecondTable_wrapper .toolbar .reservation-content {
  display: flex;
  grid-gap: 40px;
  align-items: center;
}

#snapshotSecondTable_wrapper .toolbar .reservation-color-lines {
  position: relative;
}

/* .toolbar .dataTables_filter {
  max-width: 166px;
  background: var(--white);
  border: 1px solid #cfd9f8;
  border-radius: 3px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
} */
.toolbar .dataTables_filter label {
  position: relative;
}

.toolbar .dataTables_filter input {
  border-radius: 3px;
  background: #f0f4ff;
  border: none;
  padding-left: 30px;
}

.toolbar .dataTables_filter label::before {
  content: "";
  top: 6px;
  left: 10px;
  background: url(../Images/Configuration/search.svg) no-repeat;
  width: 20px;
  height: 20px;
  position: absolute;
}

.toolbar .dataTables_filter input:focus,
.toolbar .dataTables_filter input:focus-visible {
  border: none;
  box-shadow: none;
  outline: none;
}

#snapshotSecondTable_wrapper
  .toolbar
  .reservation-color-lines:first-child:before {
  content: "";
  position: absolute;
  top: 2px;
  left: -22px;
  height: 18px;
  width: 18px;
  color: var(--blue);
  background-color: var(--blue);
  border-radius: 2px;
}

#snapshotSecondTable_wrapper
  .toolbar
  .reservation-color-lines:nth-child(2):before {
  content: "";
  position: absolute;
  top: 2px;
  left: -22px;
  height: 18px;
  width: 18px;
  color: #ef7300;
  background-color: #ef7300;
  border-radius: 2px;
}

#snapshotSecondTable_wrapper
  .toolbar
  .reservation-color-lines:nth-child(3):before {
  content: "";
  position: absolute;
  top: 2px;
  left: -22px;
  height: 18px;
  width: 18px;
  color: #ea2b12;
  background-color: #ea2b12;
  border-radius: 2px;
}

table#strategyTable thead th {
  white-space: nowrap;
}

/* table#LengthOfStayTable {
  width: 2800px !important;
} */

table#strategyTable thead tr th {
  vertical-align: middle;
}

table#strategyTable .react-datepicker-wrapper {
  max-width: 110px;
}

table#strategyTable .from-groups .form-select,
table#LyAndBudgetTable .from-groups .form-select {
  /* border: none !important; */
  font-weight: 600;
  font-size: 14px;
  line-height: 36px;
  padding: 0 20px;
  color: var(--font-color);
}

table#strategyTable
  .react-datepicker-wrapper
  .react-datepicker__input-container
  input {
  max-width: 90px !important;
  border-radius: 3px;
  background: transparent;
  border: 1px solid #3565fc80 !important;
  padding: 5px;
  height: 30px;
}

.react-datepicker-wrapper .react-datepicker__input-container input:focus,
.react-datepicker-wrapper
  .react-datepicker__input-container
  input:focus-visible {
  outline: none;
  border: none;
  box-shadow: none;
}

/* .stripped__table #only-year-table.table thead th {
  padding: 0 !important;
  border-radius: 10px 10px 0 0;
  background: #ebf0ff;
} */

.stripped__table #only-year-table.table tbody th {
  /* padding: 8px 20px; */
  color: var(--font-color);
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}

.stripped__table #only-year-table.table tbody td {
  text-align: center;
}

/* .only-year-table-main.stripped__table {
  border: 1px solid #d7e0fe !important;
  border-radius: 10px;
} */

table#LengthOfStayTable .radio-container {
  display: flex;
  justify-content: center;
  grid-gap: 15px;
  align-items: center;
}

table#LengthOfStayTable .radio-container * {
  color: var(--font-color);
  font-size: 14px;
  font-weight: 400;
}

.no-bg-color {
  background: transparent !important;
}

/* table#RatePlanAndCompanyAnalysisTable {
  width: 1300px !important;
} */
table#LengthOfStayTable thead tr th,
table#LyAndBudgetTable thead tr th,
table#GroupEventTable thead tr th,
table#snapshotSecondTable thead tr th,
table#SegmentDrillDownTable thead tr th {
  vertical-align: middle;
}

table#SegmentDrillDownTable th,
table#SegmentDrillDownTable td,
table#strategyTable th,
table#strategyTable td,
table#LyAndBudgetTable th,
table#LyAndBudgetTable td,
table#LengthOfStayTable th,
table#LengthOfStayTable td {
  text-align: center;
}

/* table#GroupEventTable,
table#SegmentDrillDownTable,
table#strategyTable,
table#snapshotFirstTable,
table#LyAndBudgetTable,
table#LengthOfStayTable,
table.actualRateTbl {
  border-collapse: unset !important;
}

table#LengthOfStayTable,
table#SegmentDrillDownTable,
table#GroupEventTable,
table#strategyTable,
table#snapshotFirstTable,
table#LyAndBudgetTable,
table.actualRateTbl {
  border-spacing: 5px !important;
}

table.actualRateTbl th,
table.actualRateTbl td,
table#SegmentDrillDownTable th,
table#SegmentDrillDownTable td,
table#GroupEventTable th,
table#GroupEventTable td,
table#snapshotFirstTable th,
table#snapshotFirstTable td,
table#LyAndBudgetTable th,
table#LyAndBudgetTable td,
table#strategyTable th,
table#strategyTable td,
table#LengthOfStayTable th,
table#LengthOfStayTable td {
  border-radius: 5px !important;
} */

/* table#LyAndBudgetTable {
  width: 2070px !important;
} */

table#GroupEventTable {
  /* width: 2000px !important; */
  text-align: center;
}

table#SegmentDrillDownTable thead th {
  white-space: nowrap;
}

.stripped__table table .form-select {
  border: none !important;
  color: var(--font-color);
  font-size: 14px;
  /* font-weight: 600;
  padding: 14px 15px 12px 15px !important; */
  /* min-width: 160px; */
}

.allTableTogatherWrapper .form-select {
  height: unset;
}

.toolbar-wrapper {
  display: flex;
  justify-content: space-between;
}

.toolbar-wrapper .header {
  width: calc(100%);
}

.fullCalendarMain .viewProperty_calender_main .calender_buttons {
  flex-direction: row-reverse !important;
  padding-top: 0 !important;
  box-shadow: 0 0px 1px 0 rgb(32 33 36 / 28%);
}

.viewProperty_calender_main {
  border: none !important;
  background: transparent !important;
  box-shadow: none !important;
  height: 100% !important;
  margin-top: 0 !important;
}

.meeting-catering-calendar {
  height: 100%;
}

/* .meeting-catering-calendar .calender_body {
        padding: 0;
    } */

/* .calender_buttons .btn_css {
    padding: 8px 8px !important;
    border-radius: 50% !important;
    background: #e1e6f5 !important;
    border: none !important;
}

.calender_buttons .text_center h4 {
    color: var(--font-color) !important;
    font-size: 20px !important;
    font-weight: 600 !important;
}

.calender_buttons .btn_css svg {
    stroke: var(--blue);
} */

.viewProperty_calender_main::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #f5f5f5;
}

.viewProperty_calender_main::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #e1e8ff;
  background-color: #e1e8ff;
}

.tblRadioGroup {
  display: flex;
  gap: 10px;
}

/* .main-cell:has(.event_details) {
    background: #f3d7d6 !important;
}

p.event_details {
    background: transparent !important;
    color: #ea2b12 !important;
    border: none !important;
    font-size: 14px !important;
    font-weight: 400 !important;
}

.calender_body .disable {
    opacity: unset !important;
}

.has-event {
    color: red !important;
    font-size: 30px !important;
} */

.crmDashboardMain .stripped__table .table thead th {
  white-space: nowrap;
}

.crmDashboardTable h4 {
  color: var(--font-color);
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 18px;
  margin-top: 20px;
}

.dashboardLast7DaysActivityTitle h4 {
  color: #8f93a0;
  font-size: 17px;
  font-weight: 700;
}

.dashboardLast7DaysActivityTitle h4 a {
  color: rgba(32, 40, 66);
  font-size: 17px;
  font-weight: 700;
}

.crmDashboardMain .chart-data a {
  height: 100%;
  width: 100%;
}

.DashboardLast7DaysActivityMain table tbody td span.seeMoreDetail {
  color: var(--blue);
  cursor: pointer;
}

.marginRight {
  padding-right: 6px !important;
}

.ProspectDashboardTitleWraper h2 {
  color: #8f93a0;
  font-size: 17px;
  font-weight: 700;
  margin: 0;
}

.ProspectDashboardTitleWraper {
  align-items: center;
}

h2 .tab-title-main {
  color: rgba(32, 40, 66);
  font-size: 17px;
  font-weight: 700;
  margin: 0;
}

.secondary-title {
  color: rgba(32, 40, 66);
  font-size: 17px;
  font-weight: 700;
  margin: 20px 0 15px 0;
}

.top-center-tabs.nav-pills {
  justify-content: center;
  align-items: center;
}

.top-center-tabs {
  margin-top: -39px !important;
}

/* table#prospectTabContentTable {
  width: 2000px !important;
} */

/* .stripped__table .table tr td:last-child,
.stripped__table .table thead th:last-child {
  position: sticky;
  right: 0;
  z-index: 111;
} */
/* .stripped__table .table thead th:last-child:has(.search-group-for-table) {
  background: #e1e7f9;
} */
.stickyLastRow {
  position: sticky;
  right: 0;
  z-index: 3;
  /* background: var(--white) !important;*/
  /* border-right: 1px solid #d7e0fe; */
}

/* .stickyLastRow::after,table:has(.stickyLastRow ) thead tr.filters th:last-child:after{
  content: "";
  position: absolute;
  height: 100%;
  width: 0;
  border-left: 1px solid #d7e0fe ;
  top: 0;
  left: 0;
} */
td:has(.stickyLastRow),
table:has(.stickyLastRow) thead tr.filters th:last-child {
  position: sticky;
  right: 0;
  z-index: 5;
  background: var(--white) !important;
  background-color: var(--white) !important;
}

.ProspectDashboardTitleTab .nav-pills .nav-item a:not(.active) {
  color: var(--font-color);
}

.client-setting-modal-inputs.custom-radio {
  float: right;
  grid: unset;
  position: relative;
  top: 45px;
  margin-bottom: 11px;
}

.nav-underline {
  width: 100%;
  border-bottom: 1px solid hsl(226deg 35% 19% / 15%);
}

.openProspectTabsAndRadios {
  margin-top: -40px;
}

.nav-underline .nav-link.active {
  color: var(--blue);
  font-weight: 500;
}

.nav-underline .nav-link:not(.active) {
  color: var(--font-color);
}

.contact-info-inline-inputs {
  display: flex;
  grid-gap: 10px;
  margin-bottom: 15px;
}

.stripped__table table tr td {
  vertical-align: middle;
}

.custom-modal-body {
  padding-bottom: 0;
}

.input-group input:focus {
  box-shadow: none;
}

.stripped__table table .add-btn-plus {
  padding: 3px 5px;
  font-size: 12px;
}

.add-btn-plus img {
  margin-right: 6px;
}

.box-shadow-custom .modal-content {
  box-shadow: 0px 0px 17px 0px rgba(15, 11, 8, 0.5);
}

.p-multiselect-panel {
  z-index: 9999 !important;
}

.small-size-add-btn {
  padding: 3.5px 10px 3.5px 5px;
}

.stripped__table table .p-multiselect {
  border: none !important;
  background: transparent;
}

/* .image-select {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid #cfd9f8;
  margin-bottom: 20px;
} */
.westModernLocationTabsTabs .nav-underline {
  justify-content: space-between;
}

.westModernLocationTabsHeader {
  border-radius: 5px;
  background: hsl(226deg 97% 60% / 10%);
  padding: 10px;
}

.westModernLocationTabsHeader h4 {
  color: var(--font-color);
  font-size: 14px;
  font-weight: 600;
  line-height: 100%;
  text-transform: uppercase;
  margin: 0;
}

.westModernLocationTabs {
  background: #fafbff;
  /* min-height: 410px; */
}

.westModernLocationTabsTabs {
  padding: 12px;
}

.westModernLocationTabsInner p {
  margin-bottom: 5px;
  color: var(--font-color);
  font-size: 13px;
  font-weight: 300;
  line-height: 100%;
}

.westModernLocationTabsInner span {
  color: var(--font-color);
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
}

.westModernLocationActivitySingle p {
  margin: 0;
}

.westModernLocationActivitySingleTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-bottom: 10px; */
}

.westModernLocationActivitySingleTitle p {
  color: var(--blue);
  font-size: 15px;
  font-weight: 500;
  line-height: 100%;
}

.locationWithBagIcon p {
  color: var(--font-color);
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
}

.dataUserAll {
  justify-content: space-between;
}

.dateUserPriority img {
  width: 20px;
  height: 20px;
}

.westModernactivityInnerUser {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  margin-bottom: 10px;
}

/* .westModernLocationActivitySingleTitle span {
  padding: 10px;
} */

.locationWithBagIcon {
  margin-bottom: 10px;
  align-items: center;
  grid-gap: 8px;
}

.westModernLocationActivitySingle {
  background: #fafbff;
  padding: 5px 12px;
  border-radius: 5px;
  margin-top: 5px;
}

.westModernactivityInnerUser p,
.westModernactivityInnerNotes p {
  color: var(--font-color);
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
}

.westModernactivityInnerNotes {
  align-items: center;
  grid-gap: 8px;
}

.AccountsCharts .legends-list {
  display: none;
}

.accountSingleChart {
  display: flex;
  position: relative;
  align-items: center;
  grid-gap: 40px;
  background: #ebf0ff;
  padding: 10px;
  justify-content: center;
}

.accountSingleChart canvas {
  height: 194px;
  width: 194px;
}

.AccountsCharts {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 12px;
}

.chartPercentagePosition {
  position: absolute;
  top: 43%;
  left: 26%;
}

.chartPercentagePosition h4 {
  color: #2e4183;
  font-size: 32px;
  font-weight: 500;
  margin: 0;
}

.singleChartPercentageDetail h3 {
  font-size: 17px;
  font-weight: 400;
  margin: 0;
}

.singleChartPercentageDetail h2 {
  color: var(--blue);
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}

.AccountsCharts {
  margin-top: 15px;
}

.inlineInput {
  display: flex;
  justify-content: space-between;
  grid-gap: 10px;
}

.simpleCheckbox {
  grid-gap: 15px;
  align-items: center;
}

.localAddressTitle h3 {
  margin-bottom: 12px;
  color: var(--font-color);
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}

.simpleCheckbox input {
  margin: 0;
}

.simpleCheckbox input:focus {
  box-shadow: none;
}

.simpleCheckbox label span {
  color: var(--font-color);
  font-size: 14px;
  font-weight: 400;
}

.checkboxWithLabel {
  grid-gap: 5px;
}

.simpleCheckbox h3 {
  color: var(--font-color);
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}

.simpleCheckbox {
  margin-bottom: 15px;
}

textarea.form-control:focus {
  box-shadow: none;
  border: 1px solid #cfd9f8 !important;
}

.form-radio label {
  font-weight: 400;
  margin-top: 10px;
}

.RFPInformationAll {
  display: flex;
  justify-content: space-between;
}

.RFPInformationInner {
  display: flex;
  grid-gap: 8px;
}

.RFPInformationInner img {
  width: 20px;
  height: 20px;
}

.RFPWrapper h4 {
  color: var(--blue);
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 10px;
}

.RFPInformationInner p,
.contactNumber p {
  color: var(--font-color);
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

.allAccountContacts {
  padding: 12px;
  max-height: 465px;
  overflow: scroll;
}

.RFPWrapper,
.singleAccountContact {
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
  padding: 10px;
  margin-bottom: 5px;
}

.singleAccountContactNumber {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contactNumber {
  display: flex;
  align-items: center;
  grid-gap: 8px;
}

.contactNumber img,
.popupInner img {
  width: 17px;
  height: 17px;
}

.singleAccountContact h3 {
  color: var(--blue);
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  margin-bottom: 10px;
}

.accountSmallPopup,
.accountSmallContactPopup {
  position: absolute;
  display: none;
  border-radius: 5px;
  background: #fafbff;
  box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.2);
  padding: 10px;
  margin-left: -10px;
  margin-top: 25px;
  /* min-width: 240px; */
}

.accountSmallContactPopup {
  margin-left: 0;
  margin-top: -10px;
}

.popupInner {
  display: flex;
  grid-gap: 8px;
  align-items: center;
}

.popupInner:not(:last-child) {
  margin-bottom: 8px;
}

.popupInner p {
  margin-bottom: 0;
  color: var(--font-color);
  font-size: 13px;
  font-weight: 400;
}

.uploadFiles {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
  margin-bottom: 25px;
}

.cloudForUpload {
  text-align: center;
  border-radius: 5px;
  border: 1px dashed #a7bcff;
  background: #fff;
  padding: 25px;
  width: 100%;
  /* min-width: 422px; */
}

.uploadedFilesInner img:not(.deleteDocumentIcon) {
  width: 35px;
  height: 35px;
}

.cloudForUpload p {
  margin: 0;
  font-size: 13px;
}

.uploadFiles h3,
.allDocuments h3 {
  color: var(--font-color);
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 15px;
}

.allDocuments {
  padding: 12px;
}

.uploadedFiles {
  display: flex;
  grid-gap: 12px;
}

.bookingBadge.badge {
  color: var(--font-color);
  font-size: 14px;
  font-weight: 400;
  border-radius: 5px;
  background: #edf0f6 !important;
  padding: 6px 15px;
  margin-right: 10px;
}

.uploadedFilesInner {
  position: relative;
  border-radius: 5px;
  background: #fafbff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
  padding: 12px;
  width: fit-content;
}

img.deleteDocumentIcon {
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
}

img.downloadDocumentIcon {
  position: absolute;
  top: 12px;
  right: 40px;
  height: 20px !important;
  width: 20px !important;
  cursor: pointer;
}

.uploadedFilesInner p {
  color: var(--font-color);
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
}

.uploadedFilesInner img {
  margin-bottom: 22px;
}

.bookingBadgeAndDate {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bookingBadgeAndDate span {
  color: rgb(32 40 66 / 80%);
  font-size: 14px;
  font-weight: 400;
}

/* .stripped__table .table tr.odd td:last-child {
  background: #f5f6fc;
}

.stripped__table .table tr.even td:last-child {
  background: #fff;
}

.stripped__table .table thead .filters th:last-child {
  background: var(--white);
} */

.accountInformationMain {
  height: 100%;
}

.checkboxWithLabel input {
  margin: 0;
}

.checkboxWithLabel label {
  color: var(--font-color);
  font-size: 14px;
  font-weight: 400;
}

.displacementCalcWrapper {
  border-radius: 5px;
  background: #f6f8ff;
  padding: 15px;
}

.calculateBtnTools {
  margin-top: 40px;
}

.companyProfileMain .checkboxWithLabel label {
  font-size: 16px;
}

.companyProfileMain {
  border-radius: 5px;
  background: #f6f8ff;
  padding: 15px;
}

.crmSettingContactCloud {
  border-radius: 5px;
  border: 1px dashed rgba(53, 101, 252, 0.2);
  background: #fff;
  padding: 40px;
  height: 120px;
  width: 120px;
}

.crmSettingContactTitleLogo {
  color: var(--font-color);
  font-size: 15px;
  font-weight: 600;
  /* margin-bottom: 10px; */
}

.companyProfileMain button {
  margin-top: 40px;
}

.form-select:disabled {
  background-color: transparent !important;
}

.form-check-input:focus {
  box-shadow: none;
}

/* .crmSettingsMain .ProspectDashboardTitleTab .nav-pills {
  margin-top: -31px;
} */

.ConfigpropertiesContentInner span {
  margin-left: 5px;
}

.placeHolderConfig {
  text-align: center;
  width: 379px;
  height: inherit;
  border-right: 1px solid #bbcafe;
}

.placeHolderConfig img {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}

.stripped__table table tbody td img {
  max-width: 25px;
  max-height: 25px;
  border-radius: 50%;
}

.image-select {
  width: 226px;
  height: 168px;
  border-radius: 10px;
  cursor: pointer;
  padding: 5px;
  margin-bottom: 20px;
  border: 1px solid #cfd9f8;
  object-fit: scale-down;
  display: inline-block;
}

.popuptitle {
  font-weight: 700;
  font-size: 17px;
  line-height: 23px;
  color: var(--black);
  margin: 10px 0;
  border-bottom: 1px solid #eaefff;
  padding-bottom: 5px;
}

.border-right {
  border-left: 1px solid #c0c7df;
}

.modal-open .mainRoot {
  filter: blur(3px);
}

.notranslate.public-DraftEditor-content {
  padding-left: 10px;
}

textarea.form-control {
  border-color: #cfd9f8;
  font-size: 14px;
  padding: 5px;
}

.userManagementModalMain-innner-img .image-select {
  background: var(--white);
}

/* .allInputsUserManagementModal .form-groups-radio .form-radio label::before {
  background: var(--white);
} */

.allInputsUserManagementModal
  input:not([type="radio"], [type="checkbox"]):focus {
  background: var(--white);
}

/* .allInputsUserManagementModal
  .form-groups-radio
  .form-radio
  input[type="radio"]:checked
  ~ label::after,
.allInputsUserManagementModal .form-groups-radio .form-radio label::after {
  border-color: var(--white);
} */

.employee-image {
  width: 110px;
  height: 110px;
  border: 1px solid white;
  padding: 5px;
  border-radius: 50%;
}

.employee-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.dataTables_empty {
  text-align: center !important;
}

.notificationMain p.notification-name {
  margin: 0;
  color: #787878;
  font-size: 14px;
  font-weight: bold;
}

.notificationMain p.notification-date {
  margin: 0;
  color: #787878;
  font-size: 14px;
  font-weight: 400;
}

.notificationMain p.notification-detail {
  margin: 0;
  color: #787878;
  font-size: 14px;
  font-weight: 400;
}

.notificationMain h4 {
  margin: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 23px;
  color: var(--font-color);
}

.notification-ttile-detail span {
  color: blue;
}

.notification-association-type {
  display: flex !important;
}

.notification-association-type p {
  padding: 0 5px !important;
  border-radius: 5px !important;
  font-size: 14px !important;
  font-weight: 700;
}

.notification-wrapper {
  border-bottom: 1px solid #caced9;
  padding: 12px 10px;
}

.notification-name-time {
  display: flex;
  gap: 5px;
  align-items: center;
  /* justify-content: space-between; */
}

.notification-canvas.offcanvas.offcanvas-end.show {
  background: #f5f7ff;
}

.read-more-device {
  color: var(--blue);
  cursor: pointer;
}

.next-cancel-btn {
  display: flex;
  gap: 5px;
  margin-top: 40px;
}

h2.addHotelTabTitle {
  color: var(--font-color);
  font-size: 18px;
  font-weight: 600;
}

.tax-setup-table select.form-select,
.meeting-room-table select.form-select,
.meeting-room-table2 select.form-select,
.guest-room-table select.form-select {
  border: none !important;
  margin: 0;
  padding: 0;
  box-shadow: none;
  background-color: transparent;
}

.meeting-room-table th select.form-select {
  text-transform: uppercase;
  color: var(--font-color);
  font-size: 14px;
  font-weight: 600;
  width: 120px;
}

.AddHotelsMain td select.form-select {
  color: var(--font-color);
  font-size: 14px;
  font-weight: 400;
}

/* .tax-setup-table table {
  border-collapse: collapse;
  border-radius: 5px !important;
} */
/* .tax-setup-table table th,
.tax-setup-table table td,
.meeting-room-table table th,
.meeting-room-table table td {
  border: 1px solid #dddfe3 !important;
  text-align: center;
  vertical-align: middle;
} */
/* .tax-setup-table table th:first-child {
  padding-left: 12px;
  text-align: start;
} */
/* .meeting-room-table thead tr:first-child th {
  background: #f5f7ff;
  text-align: start;
}
.meeting-room-table img {
  cursor: pointer;
}
.tax-setup-table th {
  line-height: 37px;
}
.tax-setup-table th,
.meeting-room-table th,
.meeting-room-table2 th,
.guest-room-table th {
  color: var(--font-color);
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  background: #eff2ff;
} */
.selectWithDeleteIcon {
  display: flex;
  /* justify-content: space-between; */
  /* padding-right: 10px;
  padding-left: 10px; */
  align-items: center;
}

.selectWithDeleteIcon img {
  /* border-left: 1px solid black;
  padding-left: 15px; */
  cursor: pointer;
}

.meeting-room-table td:has(.generalTermSetup-heading-btns),
.guest-room-table td:has(.generalTermSetup-heading-btns) {
  text-align: end;
}

.meeting-room-table .add-btn-plus,
.guest-room-table .add-btn-plus {
  padding: 8px 16px 8px 12px;
  font-size: 16px;
  font-weight: 500;
  max-height: 35px;
}

.tdWithAddBtn {
  padding: 5px !important;
}

/* .meeting-room-table2 table th,
.meeting-room-table2 table td {
  border: 1px solid #dddfe3 !important;
  text-align: center;
  vertical-align: middle;
}
.guest-room-table table th,
.guest-room-table table td {
  border: 1px solid #dddfe3 !important;
  vertical-align: middle;
} */
.meeting-room-table2 h2 {
  color: var(--font-color);
  font-size: 18px;
  font-weight: 600;
  margin-top: 25px;
  margin-bottom: 10px;
}

.blueBorder {
  border-color: var(--blue);
}

table td {
  color: var(--font-color);
  font-size: 14px;
  font-weight: 400;
}

.CRMHotelInfoMain h5 {
  color: #3565fc;
  font-size: 18px;
  font-weight: 700;
  line-height: 100%;
  margin-bottom: 6px;
  margin-top: 10px;
}

.CRMHotelInfoMain .allhotelInfoMain span {
  color: var(--font-color);
  font-size: 15px;
  font-weight: 400;
  line-height: 100%;
}

.allHotelInfoBox {
  background: #ffffff;
  border: 1px solid #cfd9f8;
  border-radius: 10px;
  padding: 12px;
}

.allhotelInfoMain {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(209px, 1fr));
}

.allhotelInfoMain .allSettingsBoxImg {
  float: right;
}

.hotelInfoTab {
  padding: 16px 12px 0 12px;
}

.hotelContectDetailMain {
  padding: 12px;
}

.mergeDocsDetailMain span,
.hotelContectDetailMain span {
  color: var(--font-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  margin-bottom: 8px;
}

.mergeDocsDetailMain p .hotelContectDetailMain p {
  color: var(--font-color);
  font-size: 16px;
  font-weight: 400;
  line-height: 100%;
  margin: 0;
}

.hotelContectDetailMain h4 {
  color: #000;
  font-size: 15px;
  font-weight: 500;
  line-height: 100%;
  margin-bottom: 10px;
}

.contactOwnerInner p {
  font-size: 14px;
  line-height: 100%;
  margin: 0;
}

.hotelContectDetailMain {
  height: 550px;
  overflow: auto;
}

.mergeDocsRadios {
  padding: 12px;
}

.mergeDocInfoTab {
  padding: 0 12px 0 12px;
}

.mergeDocsDetailMain {
  height: 300px;
  overflow: auto;
  padding: 16px;
}

.guestRoomTbl table thead th {
  border: 1px solid #dddfe3 !important;
  background: #eff2ff;
}

.guestRoomTbl table tbody td {
  border: 1px solid #dddfe3 !important;
}

.meetingRoomTbl table *,
.guestRoomTbl table * {
  font-size: 12px;
  line-height: 100%;
}

.meetingRoomTbl table thead th {
  border: 1px solid #dddfe3 !important;
  background: #eff2ff;
  text-align: center;
}

.meetingRoomTbl table tbody td {
  border: 1px solid #dddfe3 !important;
  text-align: center;
}

.allInputsUserManagementModal .form-control:disabled,
.allInputsUserManagementModal .form-select:disabled {
  background: var(--white);
}

/* .allInputsUserManagementModal .form-control:disabled::placeholder {
  color: var(--white) !important;
} */

/* .allInputsUserManagementModal .p-disabled .p-multiselect-label {
  color: white !important;
} */

.allInputsUserManagementModal .p-disabled,
.allInputsUserManagementModal .p-component:disabled {
  opacity: 1 !important;
  background-color: transparent !important;
  height: 30px;
}

.form-control:focus {
  box-shadow: none;
}

.allInputsUserManagementModal .form-check-input:disabled {
  background: grey;
  border: none;
}

.allInputsUserManagementModal .form-check-input:disabled ~ .form-check-label {
  color: var(--font-color);
  opacity: unset;
}

.listOfDocs h4 {
  color: var(--font-color);
  font-size: 18px;
  font-weight: 500;
  line-height: 100%;
  margin-bottom: 10px;
  margin-top: 20px;
}

.singleListItemDocs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.06);
  margin-bottom: 5px;
}

.listItemImgAndName {
  display: flex;
  align-items: center;
  grid-gap: 12px;
}

.listItemImgAndName p {
  color: var(--font-color);
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  margin: 0;
}

p.docSize {
  color: var(--font-color);
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
  margin: 0;
}

.UploadDocumentModalMain .cloudForUpload {
  border: 1px dashed #a7bcff;
  background: rgba(53, 101, 252, 0.03);
}

.salesChecklistMain {
  border-radius: 5px;
  background: #f6f8ff;
  padding: 13px;
}

.foodAndBevTitle h4 {
  color: var(--font-color);
  font-size: 18px;
  font-weight: 600;
  line-height: 100%;
  margin-bottom: 10px;
}

.taxInfoTable {
  padding: 12px;
}

.nav-underline {
  grid-column-gap: 25px !important;
}

.CRMAccountRulesMain .accordion-header button {
  color: #1c1c1c;
  font-size: 15px;
  font-weight: 600;
  line-height: 100%;
  padding: 12px 12px;
  border-radius: 5px !important;
  background: #ebf0ff;
  text-transform: uppercase;
}

.CRMAccountRulesMain .accordion-header {
  border-radius: 5px !important;
}

.CRMAccountRulesMain .accordion-item {
  margin-bottom: 10px;
  border: none;
}

.accordion-button::after {
  width: 17px;
  height: 17px;
  background-size: contain;
}

.underlineSpan {
  text-decoration: underline;
  font-weight: 500;
  color: rgba(32, 40, 66, 0.8);
}

.numberAndName h6 {
  color: var(--font-color);
  font-size: 15px;
  font-weight: 600;
  line-height: 100%;
  margin: 0;
}

.accountRuleBodyDescription p {
  color: rgba(32, 40, 66, 0.8);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin: 0;
}

.accountRuleBodyDescription {
  position: relative;
}

.accountRuleBodyDescription::before {
  content: "";
  background: url("../Images/CRM/rightArrow.svg") no-repeat;
  background-size: contain;
  width: 18px;
  height: 8px;
  position: absolute;
  top: 6px;
  left: -30px;
}

h6.editAccountRuleModalTitile {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
  text-transform: capitalize;
}

.activitiesPill {
  border-bottom: 1px solid #c2d0fe;
  padding-bottom: 15px;
  margin-top: -35px !important;
}

button.viewChangeBtn {
  filter: brightness(0);
  background-color: rgba(32, 40, 66, 0.05);
}

button.viewChangeBtn:hover {
  filter: brightness(1);
  background-color: #e1e8ff;
}

.rateTitle h3 {
  color: var(--font-color);
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
  margin: 0;
}

.EditRFPModalMain .modal-content {
  height: 800px;
}

.viewChangeBtn:focus {
  background: rgba(32, 40, 66, 0.05);
}

.gridViewSingleMain {
  min-width: 280px;
  margin-top: 20px;
}

.gridViewSingleMain:not(:last-child) {
  margin-right: 20px;
}

.singlegridmain {
  border-radius: 5px;
  border: 1px solid rgba(53, 101, 252, 0.2);
  background: rgba(245, 247, 255, 0.5);
  padding: 12px;
  margin-bottom: 10px;
  width: 280px;
}

.singleGridViewTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding-bottom: 14.5px; */
  border-bottom: 1px solid #df2020;
  margin-bottom: 15px;
  padding-bottom: 10px;
}

.singlegridTitile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 11px;
}

.singlegridmain h3 {
  color: var(--blue);
  font-size: 16px;
  font-weight: 500;
  line-height: 100%;
  margin-bottom: 0;
}

.singleGridViewTitle h3 {
  color: var(--font-color);
  font-size: 15px;
  font-weight: 600;
  line-height: 100%;
  margin: 0;
}

.singleGridViewTitle .default-badge {
  border-radius: 50% !important;
  padding: 5px 8px !important;
}

.singlegridinner {
  display: flex;
  align-items: center;
}

.singlegridinner:not(:last-child) {
  margin-bottom: 12px;
}

.singlegridinner img {
  margin-right: 8px;
  height: 20px;
  width: 20px;
}

.singlegridinner p {
  margin: 0;
  color: var(--font-color);
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
}

.AllGridViewMain {
  display: flex;
}

.AllGridViewMain {
  overflow-x: auto;
  /* height: calc(100vh - 275px); */
}

.CopyEditDeleteBtns {
  border-radius: 5px;
  background: #fff;
  box-shadow: -1px 2px 20px 0px rgba(0, 0, 0, 0.16);
  padding: 10px;
  display: inline-grid;
  float: right;
  position: relative;
  top: -40px;
}

.CopyEditDeleteBtns img {
  cursor: pointer;
}

.CopyEditDeleteBtns img:not(:last-child) {
  margin-bottom: 12px;
}

.singleCheckBox div {
  display: flex;
  align-items: center;
}

.singleCheckBox input {
  margin: 0;
  height: 15px;
  width: 15px;
  border-color: rgba(32, 40, 66, 0.5);
}

.singleCheckBox .form-check-input:checked {
  background-color: #009a4f;
  border-color: #009a4f;
}

.caculationSection h5 {
  color: var(--font-color);
  font-size: 17px;
  font-weight: 600;
  line-height: 100%;
  margin-top: 20px;
  margin-bottom: 12px;
}

.caculationSectionInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  background: #f6f8ff;
  padding: 8px;
  margin-bottom: 5px;
}

.caculationSectionInner p {
  margin: 0;
  color: var(--font-color);
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
}

.caculationSectionInner p span {
  color: #3565fc;
  font-size: 16px;
  font-weight: 700;
  line-height: 100%;
}

p.availableRoomsCalc {
  border-bottom: 1px solid #c7d4fe;
  text-align: center;
  padding-bottom: 12px;
  color: var(--font-color);
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
  /* margin-bottom: 20px; */
}

.caculationSectionConclusion {
  border-radius: 5px;
  border: 1px solid rgba(53, 101, 252, 0.2);
  background: #ebf0ff;
  padding: 12px;
}

.caculationSectionConclusionInner p {
  color: var(--font-color);
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
  margin-bottom: 8px;
}

.caculationSectionConclusionInner h6 {
  color: #f45200;
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
  margin-bottom: 3px;
}

.caculationSectionConclusionInner {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
}

.RoleMangementModalMain-inner-right table thead th:not(:first-child),
.RoleMangementModalMain-inner-right table tbody td:not(:first-child) {
  text-align: center;
  vertical-align: middle;
}

.RoleMangementModalMain-inner-right .stripped__table .table tr td:last-child,
.RoleMangementModalMain-inner-right
  .stripped__table
  .table
  thead
  th:last-child {
  position: unset;
}

/* table#GroupEventTable thead th,
table#LyAndBudgetTable thead th,
table#LengthOfStayTable thead th,
table#snapshotFirstTable thead th,
table#snapshotSecondTable thead th {
  white-space: nowrap;
} */

/* .border_right_inner{
  position: relative;
}
.border_right_inner_wrapper::after{
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  width: 10px;
  height: 100%;
  border-right: 1px solid red;
} */
.configurationAccordionHeader button:has(a.active) {
  box-shadow: none;
}

table tbody tr:last-child {
  border-bottom: transparent;
}

.reportModalProfiles {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  padding-left: 10px;
}

.reportModalProfiles:not(:last-child) {
  margin-bottom: 10px;
}

.reportModalProfiles img {
  height: 34px;
  width: 34px;
  border-radius: 50%;
}

.reportModalProfiles p {
  color: var(--font-color);
  font-size: 16px;
  font-weight: 400;
  line-height: 100%;
  margin-bottom: 0;
}

.weekDaysInitials {
  border-radius: 5px;
  border: 1px solid rgba(53, 101, 252, 0.2);
  padding: 3px 5px;
  font-size: 13px;
  color: var(--font-color);
  font-weight: 400;
  line-height: 100%;
  cursor: pointer;
}

.allweekDaysInitials {
  display: flex;
  justify-content: space-between;
  grid-gap: 10px;
}

.allweekDaysInitials .selected {
  background: var(--blue);
  color: var(--white);
}

.CustomReoccurance .modal-content {
  min-width: 338px;
}

.CustomReoccurance .modal-dialog.modal-sm.modal-dialog-centered {
  justify-content: center !important;
}

.btn-close {
  width: 6px;
  height: 6px;
  opacity: 1;
}

.CustomReoccurance h3 {
  color: var(--font-color);
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
}

.OnRadioAndInput {
  display: flex;
  justify-content: space-between;
}

.CRMReportDetailBeforeViewSection .select__control {
  padding: 1.5px 3px 1.5px !important;
  border: 1px solid #cfd9f8 !important;
  border-radius: 5px !important;
}

.CRMReportDetailBeforeViewSection .select__control input {
  border: none !important;
}

.checkboxWithLabel input {
  height: 17px;
  width: 17px;
}

.CRMReportDetailBeforeViewSectionbtns {
  display: flex;
  justify-content: end;
  align-items: center;
  grid-gap: 10px;
}

.CRMReportDetailBeforeViewSection {
  border-radius: 5px;
  background: #f6f8ff;
  padding: 12px;
}

.weeklyReportWithChartHeader1 {
  color: var(--font-color);
  font-size: 17px;
  font-weight: 600;
  line-height: 100%;
  margin-top: 25px;
  margin-bottom: 10px;
}

.weeklyReportWithChartHeader2 {
  color: #3565fc;
  font-size: 17px;
  font-weight: 700;
  line-height: 100%;
  text-decoration: underline;
  margin-bottom: 12px;
}

.weeklyReportWithChart .legends-list {
  display: none;
}

.CRMReportDetailMain
  .react-datepicker-wrapper
  .react-datepicker__input-container
  input {
  background: var(--white) !important;
}

.CRMReportDetailRightInner {
  padding: 12px;
}

.CRMReportDetailRightInner .bookingBadge.badge {
  padding: 7.5px 15px;
}

.CRMReportDetailRightInner .bookingBadge.badge:not(:last-child) {
  margin-right: 5px;
}

.CRMReportDetailRightSectionMain .westModernLocationTabs {
  min-height: 278px !important;
}

.CRMReportDetailLeftMain {
  overflow: auto;
  max-height: 810px;
}

.CRMReportDetailLeftMain::-webkit-scrollbar {
  display: none;
}

.CRMReportDetailLeftMain::-webkit-scrollbar-thumb {
  display: none;
}

.CRMReportDetailLeftMain {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.default-badge.bookingBadge.badgeClicked.badge.bg-primary {
  background: var(--blue) !important;
  color: var(--white);
}

.CRMActivityViewRightSectionMain .activityViewObjective {
  max-height: 290px !important;
  min-height: 290px !important;
}

.CRMActivityViewLeftSectionMain .activityViewObjective {
  max-height: 600px !important;
  min-height: 600px !important;
}

.operationPropertyTopMain {
  border-radius: 10px;
  border: 1px solid rgba(53, 101, 252, 0.2);
  background: #ebf0ff;
  padding: 10px;
}

.operationPropertyInner p,
.operationPropertyInner p span {
  color: var(--font-color);
  font-size: 14px;
  font-weight: 600;
  line-height: 100%;
  margin-bottom: 0;
}

.operationPropertyInner span {
  color: var(--font-color);
  font-size: 15px;
  font-weight: 300;
  line-height: 100%;
}

.operationPropertyInner,
.operationPropertySecondInner {
  display: flex;
  align-items: flex-start;
  grid-gap: 10px;
}

.operationPropertyInner img {
  margin-top: 3px;
}

.operationPropertyTopSecondary {
  border-radius: 10px;
  border: 1px solid rgba(53, 101, 252, 0.2);
  background: rgba(245, 247, 255, 0.2);
  padding: 10px;
}

.operationPropertySecondSec {
  border-radius: 10px;
  border: 1px solid rgba(53, 101, 252, 0.2);
}

.operationPropertySecondInner span {
  color: var(--font-color);
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
}

.operationPropertySecondInner p {
  color: var(--font-color);
  font-size: 15px;
  font-weight: 700;
  line-height: 100%;
  margin: 0;
}

.operationPropertyTopMain img,
.operationPropertyTopSecondary img,
.operationPropertySecondSecInner img {
  width: 17px;
  height: 17px;
  object-fit: contain;
}

.operationPropertySecondSecInner {
  padding: 12px;
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fit, MinMax(150px, 1fr));
}

.snapshotDoughnutChartMain.operationPropertyDoughnutChartMain
  .DoughnutChartMain
  .legends-list {
  display: flex;
  justify-content: space-between;
  margin-left: 0 !important;
  margin-top: 30px;
}

.snapshotDoughnutChartMain.operationPropertyDoughnutChartMain
  .DoughnutChartMain
  .legends-list
  li:not(:last-child) {
  margin-right: 20px;
}

.snapshotDoughnutChartMain.operationPropertyDoughnutChartMain
  .DoughnutChartMain {
  display: block;
}

.operationPropertyDoughnutChartMain .snapshotDoughnutChartMainInner {
  margin-top: 0 !important;
}

.snapshotDoughnutChartMain.operationPropertyDoughnutChartMain {
  padding: 15px !important;
  margin: 0;
}

.operationPropertyDoughnutChartMain canvas {
  margin: 0 auto;
}

.verticalBarChartMainTitle h3 {
  color: var(--font-color);
  font-size: 17px;
  font-weight: 600;
  line-height: 100%;
}

.verticalBarChartMain {
  border-radius: 10px;
  border: 1px solid rgba(53, 101, 252, 0.2);
  background: rgba(53, 101, 252, 0.05);
  padding: 20px;
}

.operationPropertyDoughnutChartMain canvas {
  max-height: 312px;
}

.waveChartMainWrapper {
  border-radius: 20px;
  border: 1px solid rgba(53, 101, 252, 0.2);
  background: #fff;
  padding: 20px;
}

.horizontal-menu-option {
  display: none !important;
}

.horizontal-menu-option.active {
  display: flex !important;
  z-index: 3;
}

.propertySettingSettingsMain {
  border-radius: 10px;
  background: rgba(53, 101, 252, 0.05);
  padding: 15px;
}

.form-switch .form-check-input {
  outline: 0;
  border: none !important;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  background-color: #dddddd;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgb(234, 43, 18)'/></svg>");
  height: 22px;
  width: 40px;
  margin-top: 1px;
}

.form-switch .form-check-input:checked {
  background-color: var(--green);
  border-color: var(--green);
  border: none;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(255,255,255,1.0)'/></svg>");
}

.assignmentTabMain,
.rateShopInner {
  border-radius: 10px;
  background: rgba(53, 101, 252, 0.05);
  padding: 20px;
}

h2.rateShopMainTitle {
  color: var(--font-color);
  font-size: 17px;
  font-weight: 700;
  line-height: 100%;
  /* margin-bottom: 12px; */
}

table.userForcastTable thead th,
table.budgetTable thead th,
table.barConfigTable thead th {
  vertical-align: middle;
}

.chart-and-values canvas {
  height: 30px !important;
  width: 60px !important;
}

.verticalChartMainWrapper {
  height: 344px;
}

.occupancyCardMain {
  border-radius: 6px;
  border: 1px solid rgba(53, 101, 252, 0.2);
  padding: 5px;
  display: flex;
  align-items: center;
  grid-gap: 12px;
}

.occupancyCardLeft {
  border-radius: 5px;
  background: rgba(53, 101, 252, 0.05);
  padding: 8px;
  max-height: 80px;
  text-align: center;
  /* min-width: 70px; */
}

.occupancyCardLeft p.occupancyCardRoomType {
  color: var(--font-color);
  font-size: 12px;
  font-weight: 400;
  line-height: 100%;
  margin-top: 5px;
  margin-bottom: 0;
  cursor: pointer;
}

.occupancyCardLeft p.occupancyCardRoomID {
  color: var(--font-color);
  font-size: 13px;
  font-weight: 500;
  line-height: 100%;
  padding-bottom: 8px;
  margin: 0;
  border-bottom: 1px solid var(--font-color) 15;
}

.occupancyCardLeft p.occupancyCardRoom {
  color: var(--font-color);
  font-size: 13px;
  font-weight: 300;
  line-height: 100%;
  margin-bottom: 5px;
}

.occupancyCardRight p.occupancyEmail {
  color: rgba(32, 40, 66, 0.8);
  font-size: 13px;
  font-weight: 400;
  line-height: 100%;
  margin-bottom: 0;
}

.occupancyCardRight p.occupancyContactNumber {
  color: rgba(32, 40, 66, 0.8);
  font-size: 13px;
  font-weight: 400;
  line-height: 100%;
  margin-bottom: 5px;
}

.occupancyCardRight p.occupancyCardHotelName {
  color: var(--font-color);
  font-size: 14px;
  font-weight: 600;
  line-height: 100%;
  margin-bottom: 5px;
}

.occupancyModalInnerMain {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-gap: 20px;
}

.ArrivalAndDepartureMain
  .react-datepicker-wrapper
  .react-datepicker__input-container
  input {
  padding: 7.5px;
}

.dateWithButton {
  grid-gap: 10px;
}

/* 
.ArrivalAndDepartureMain .react-datepicker__input-container::after {
  top: 7px;
} */

.arrivalLeftMain h3 {
  color: #009a4f;
  font-size: 15px;
  font-weight: 700;
  line-height: 100%;
  margin-bottom: 10px;
}

.singleColumnTable .accordion-item,
.singleColumnTable .accordion-header button {
  background: transparent;
  background-color: transparent;
  border: none;
  box-shadow: none;
  padding-left: 0;
}

.singleColumnTable .accordion-header button {
  padding-bottom: 5px;
  color: var(--font-color);
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
}

.singleColumnTable .accordion-header button::after {
  flex-shrink: 0;
  width: 11px !important;
  height: 7px !important;
  content: "";
  right: 0 !important;
  top: 0 !important;
  background: transparent url(../Images/Configuration/accordionArrow.svg)
    no-repeat !important;
  transition: var(--bs-accordion-btn-icon-transition);
}

.singleColumnTable .accordion-item .accordion-body,
.singleColumnTable .accordion-item .accordion-body ul {
  padding-left: 0;
}

.singleColumnTable .accordion-item .accordion-body ul li {
  list-style: none;
  color: var(--font-color);
  font-size: 12px;
  font-weight: 400;
  line-height: 100%;
}

.singleColumnTable .accordion-header button:focus {
  color: var(--blue);
  font-weight: 600;
}

.allFloorContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(69px, 1fr));
  grid-gap: 6px;
}

.roomsInPropertyInner .tab-content {
  margin-top: 15px;
  height: 201px;
  overflow-y: auto;
}

.cleanBoxes {
  width: 14px;
  height: 14px;
}

.allCLeanBoxesWrapper,
.allCLeanBoxesInner {
  display: flex;
  align-items: center;
}

.allCLeanBoxesInner p {
  margin-bottom: 0;
}

.roomsInPropertyInner {
  position: relative;
  padding: 0 15px 15px 15px;
}

.allCLeanBoxesWrapper {
  position: absolute;
  right: 15px;
  top: 10px;
  line-height: 43px;
}

.allCLeanBoxesInner .cleanBoxes {
  margin-right: 6px;
}

.todaysChecklistMain h2 {
  color: var(--font-color);
  font-size: 17px;
  font-weight: 700;
  line-height: 100%;
  margin-top: 25px;
  margin-bottom: 12px;
}

.scheduleCheckListModal .modal-body {
  height: 800px;
  overflow-y: auto;
}

.housekeepersCardInner {
  display: flex;
  grid-gap: 10px;
  align-items: center;
}

.houseKeepingDate {
  display: flex;
  grid-gap: 5px;
  align-items: center;
}

.houseKeepingDate p {
  margin: 0;
}

.housekeepersCardInner p,
.houseKeepingProgress p {
  margin: 0;
  color: rgba(32, 40, 66, 0.8);
  font-size: 12px;
  font-weight: 400;
  line-height: 100%;
}

.housekeepersCardInner p span {
  color: var(--blue);
}

.houseKeepingProgress p {
  margin-bottom: 5px;
}

.houseKeepingProgressLabel,
.houseKeepingDateTime {
  display: flex;
  justify-content: space-between;
}

.houseKeepingProgressLabel {
  margin-top: 15px;
}

.housekeepersCardMain {
  border-radius: 5px;
  border: 1px solid rgba(53, 101, 252, 0.2);
  padding: 10px;
}

.houseKeepingDateTime img {
  width: 15px;
  height: 15px;
}

.housekeepersImg img {
  width: 100%;
  height: 100%;
}

.housekeepersImg {
  background: #e2e7f6;
  border-radius: 50%;
  width: 35px;
  height: 35px;
}

.housekeepersName h6 {
  color: var(--font-color);
  font-size: 15px;
  font-weight: 600;
  line-height: 100%;
  margin-bottom: 5px;
}

.housekeepingCardList span {
  font-size: 13px;
  line-height: 100%;
}

.houseKeepingDate p {
  color: var(--font-color);
  font-size: 12px;
  font-weight: 400;
  line-height: 100%;
}

.houseKeepingDate {
  margin-top: 15px;
}

.orangeprogressbar,
.darkProgressBar {
  height: 5px;
}

.darkProgressBar .progress-bar {
  background: #28477d;
}

.orangeprogressbar .progress-bar {
  background: #ed6d2c;
}

.allHousekeepingCardMain {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(238px, 1fr));
  grid-gap: 11px;
}

.TaskAssignedtoUsersCardMain {
  border-radius: 5px;
  border: 1px solid rgba(53, 101, 252, 0.2);
  padding: 12px;
}

.housekeepingCardList ul {
  padding-left: 15px;
}

.housekeepingCardList ul li {
  color: var(--font-color);
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
}

.housekeepingCardList ul li:not(:last-child) {
  margin-bottom: 10px;
}

.housekeepingCardList ul li:first-child::marker {
  color: var(--blue);
}

.housekeepingCardList ul li:nth-child(2)::marker {
  color: #00bc8b;
}

.housekeepingCardList ul li:last-child::marker {
  color: #ff9e01;
}

.allTaskAssignedCardMain {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(197px, 1fr));
  grid-gap: 10px;
}

.houseKeepingMain .DoughnutChartMain {
  display: unset;
}

.houseKeepingMain .legends-list {
  display: none;
}

.summaryChart {
  background: #fafbff;
  padding: 12px;
  border-radius: 0 0 5px 5px;
}

.summaryChart .DoughnutChartMain div {
  max-width: 190px;
}

.housekeepingCardList ul li .progressLi span:last-child {
  color: #009a4f;
  font-size: 15px;
  font-weight: 600;
  line-height: 100%;
}

.summaryChart div:first-child {
  display: flex;
  justify-content: center;
}

.bottomOfSummary .tab-content {
  height: 222px;
  overflow: auto;
  scrollbar-width: none;
}

.bottomOfSummary {
  border-radius: 5px;
  background: #fafbff;
  padding: 12px;
}

.bottomOfSummary .occupancyCardLeft .occupancyCardRoomID {
  border-bottom: none;
  padding-bottom: 0;
}

.bottomOfSummary .occupancyCardLeft .occupancyCardRoomType {
  margin-top: 0;
}

.bottomOfSummary .occupancyCardLeft {
  padding: 14px;
}

.bottomOfSummary .allFloorContainer {
  grid-template-columns: repeat(auto-fill, 64px);
  justify-content: center;
}

.RoomAttendantMain .todaysChecklistMain h2 {
  margin-top: 20px;
}

.AddChecklistModalMain .modal-content .multiselector .searchWrapper * {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: var(--font-color);
}

.AddChecklistModalMain .modal-content .multiselector .searchWrapper {
  border: 1px solid #cfd9f8;
  font-size: 16px;
}

.AddChecklistModalMain .modal-body {
  min-height: 739px;
}

.houseKeepingRightSection {
  position: sticky;
  right: 0;
  top: 81px;
}

.bottomOfSummary .tab-content::-webkit-scrollbar {
  display: none;
}

.ScheduleChecklistTop {
  border-radius: 5px;
  background: #fafbff;
  padding: 15px 12px;
}

.ScheduleChecklistTop span {
  color: var(--font-color);
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
}

.ScheduleChecklistTop p {
  color: var(--font-color);
  font-size: 13px;
  font-weight: 300;
  line-height: 100%;
  margin-bottom: 0;
}

.RoasterManagementMain .weekDaysInitials {
  padding: 0px 0px;
  font-size: 12px;
  font-weight: 400;
  width: 19px;
  height: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.roasterManagementInitials.allweekDaysInitials {
  justify-content: unset;
}

.AddRoasterManagementModalMain .modal-body {
  height: 739px;
}

.houseKeepingSchduleHeadEndMain {
  display: flex;
  justify-content: end;
  align-items: flex-end;
  grid-gap: 17px;
}

.emptyBoxForColor {
  height: 12px;
  width: 12px;
  background: #ed6d2c;
  border-radius: 3px;
}

.houseKeepingSchduleHeadEndInner {
  display: flex;
  align-items: center;
  grid-gap: 8px;
}

.houseKeepingSchduleHeadEndInner p {
  margin: 0;
  font-size: 12px;
}

.displacementCalcWrapper button {
  font-size: 14px;
  line-height: 100%;
}

.ViewListingMain .accordion-button:not(.collapsed) {
  background: transparent !important;
  box-shadow: none;
}

.ViewListingMain .accordionInnerContent {
  padding: 4px 10px;
}

.ViewListingMain .accordion-item button.accordion-button {
  background-color: transparent !important;
  padding: 5px 10px !important;
  font-size: 14px;
}

.ViewListingMain .accordion-item {
  border-bottom: 1px solid #bac9fe !important;
  background: transparent;
}

.ViewListingMain .accordion-item p {
  cursor: move;
  font-size: 13px;
  line-height: 100%;
  margin: 0;
  position: relative;
  padding-left: 20px;
}

.ViewListingMain .accordion-item p::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 15px;
  height: 15px;
  background-size: contain !important;
  background: url("../Images/Action Icons/dragIcon.svg") no-repeat;
}

.ViewListingMain .accordion-button:not(.collapsed) {
  color: var(--blue);
  border-bottom: 1px solid #bac9fe !important;
}

.ViewListingMain .accordion {
  border: 1px solid #bbcafe;
  border-radius: 10px;
  min-height: calc(100vh - 180px);
  position: sticky;
  left: 0;
  top: 0;
}

.gridSelectionBox {
  padding: 6px 6px;
  background: var(--light-bg);
  height: 100%;
  width: 100%;
}

.gridSelectionBoxWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 3px;
  border-radius: 5px;
  width: 110px;
  height: 55px;
  cursor: pointer;
}

.allGridBoxWrapper {
  width: 100%;
  height: auto;
  grid-gap: 30px;
}

.allGridBoxWrapperWithTitle {
  padding: 20px;
  border: 2px dashed #d5d5d5;
  margin-top: 20px;
}

.gridSelectionBox.gridSelectionBoxSm {
  width: 50px;
}

.pac-container.pac-logo {
  z-index: 9999;
}

.dragableRowWrapper {
  position: relative;
  border: 1px solid #bbcafe;
  border-radius: 10px;
  padding: 25px 15px 15px 15px;
}

.dragableRowWrapper:not(:first-child) {
  margin-top: 30px;
}

.dragableRowWrapper > div {
  border: 1px dashed #d5d5d5;
  min-height: 60px;
}

/* .removeRowBtn {
  position: absolute;
 top: -13px;
  left: 50%;
  transform: translate(-50%);
} */
.removeRowBtnMain {
  position: absolute;
  background: #e1e8ff;
  width: 100px;
  display: flex;
  flex-direction: row;
  padding: 0;
  top: 0;
  transform: translate(-50%);
  left: 50%;
  grid-gap: 2px;
  border: none !important;
  justify-content: center;
  min-height: 20px !important;
  align-items: center;
  clip-path: polygon(0 0, 100% 0, 90% 100%, 10% 100%);
}

.removeRowBtnMain img {
  height: 20px;
  width: 20px;
}

/*   .removeRowBtnMain img:first-child {
            cursor: move;
        }*/

/*.dragableRowWrapper > div > div {
    padding: 10px;
}*/

/* .dragableRowWrapper button.btn-close:root{
  --bs-btn-close-bg : url("")
} */
button.close-button {
  background: url("../Images/Action Icons/crossIcon.svg") no-repeat center;
  border: none;
  background-size: contain;
  width: 20px;
  height: 20px;
}

/* button.close-button::after {
  content: " ";
  position: absolute;
  right: 0;
  top: 0;
  
} */
button.close-button:hover {
  background-color: transparent;
}

.accordionInnerContent {
  border: 1px dashed #bbcafe;
  padding: 10px 10px;
  border-radius: 5px;
  cursor: move;
}

.accordionInnerContent:not(:last-child) {
  margin-bottom: 5px;
}

.ViewListingMain .accordion-button::after {
  width: 15px !important;
  height: 15px !important;
  background-size: contain;
}

.ViewListingMain .accordion-body {
  padding: 10px;
}

.houseKeepingSchduleHeadEndMain
  .houseKeepingSchduleHeadEndInner:last-child
  .emptyBoxForColor {
  background: #28477d;
}

.border-bottom-with-padding {
  padding-bottom: 20px;
  border-bottom: 1px solid var(--font-color) 26;
}

.HouseKeppingSchedualBoxMain {
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 10px;
  grid-gap: 10px;
  max-width: 204px;
  min-height: 55px;
}

/* .HouseKeppingSchedualBoxImg {
  border-radius: 34px;
  background: rgba(255, 255, 255, 0.1);
} */
.HouseKeppingSchedualBoxContent p {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  margin: 0;
}

.HouseKeppingSchedualBoxContent h6 {
  color: var(--white);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  margin-bottom: 4px;
}

.tableWithoutBorder * {
  border: none !important;
}

.tableWithoutBorder {
  border: none !important;
  margin-top: 20px;
}

table.tableWithoutBorder td,
table.tableWithoutBorder th {
  background-color: transparent;
}

.tableWithoutBorder tbody tr {
  position: relative;
  z-index: 1;
}

.tableWithoutBorder tbody tr::after {
  content: "";
  height: 1px;
  background-color: #00000033;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  z-index: -1;
}

.blankHouseKeppingSchedualBox {
  cursor: pointer;
}

.AddOperationScheduleModalMain .modal-body {
  min-height: 330px;
}

.AddCostCalculatorConfigModalMain .from-groups input {
  width: 35%;
  margin: auto;
}

span.costCalcTdSpan {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  text-transform: capitalize;
}

.customWidthInput input {
  width: 65px;
  margin: auto;
  height: 30px;
  font-size: 12px;
  border: 1px solid rgba(53, 101, 252, 0.2);
}

td.customWidthInput {
  padding: 3.5px;
}

table.CostCalculationTable thead th img {
  cursor: pointer;
}

.ShowMoreContent .occupancyCardLeft {
  position: relative;
}

.ShowMoreContent .occupancyCardLeft::after {
  content: "";
  position: absolute;
  background: url("../Images/Operation/closeIcon.svg") no-repeat;
  width: 20px;
  height: 20px;
  top: -8px;
  right: -8px;
  cursor: pointer;
}

.HouseKeepingStatusCardMain {
  border-radius: 5px;
  border: 1px solid rgba(53, 101, 252, 0.2);
  background: rgba(245, 247, 255, 0.5);
  padding: 6px;
}

.HouseKeepingStatusCardMain p {
  margin: 0;
}

.HouseKeepingStatusWithLock img {
  background: #009a4f;
  padding: 3px;
  width: 20px;
  border-radius: 5px;
  height: 20px;
}

.HouseKeepingStatusWithLock {
  display: flex;
  grid-gap: 5px;
}

.HouseKeepingStatusWithLock p {
  margin: 0;
  color: var(--font-color);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  border-radius: 35px;
  background: #daf9e8;
  padding: 6px 10px;
}

.HouseKeepingStatusCardInnerTop > div:first-child > p {
  color: var(--font-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  margin: 0;
}

.HouseKeepingStatusCardInnerDoorSection,
.HouseKeepingStatusCardInnerBedSection {
  display: flex;
  grid-gap: 5px;
  align-items: center;
}

.HouseKeepingStatusCardInnerBottom {
  display: flex;
  grid-gap: 48px;
}

.HouseKeepingStatusCardInnerTop {
  margin-bottom: 7px;
}

.OperationStatusMain .singleGridViewTitle {
  padding-bottom: 10px;
}

.HouseKeepingStatusCardInnerBottom p {
  color: var(--font-color);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

.HouseKeepingStatusCardInnerDoorSection img {
  width: 15px;
  height: 15px;
}

/* 18-08-2023 */

.ReserveReconciliationTableWrapper,
.AccountReconciliationTableWrapper {
  border: 1px solid #e1e8ff;
  border-radius: 10px;
}

.ReserveReconciliationHeaderWrapper,
.AccountReconciliationHeaderWrapper {
  min-height: 44px;
  background: #e1e8ff;
  border-radius: 10px 10px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}

.AccountReconciliationHeaderWrapper .UploadDataFieldMain,
.ReserveReconciliationHeaderWrapper .UploadDataFieldMain {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: #ffffff;
  max-height: 70px;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  grid-gap: 5px;
}

.AccountReconciliationHeaderWrapper .UploadDataFieldMain p,
.ReserveReconciliationHeaderWrapper .UploadDataFieldMain p {
  margin: 0;
  color: #3565fc;
  font-size: 14px;
  font-weight: 500;
}

.ReserveReconciliationHeaderWrapper .from-groups .form-select {
  border: none !important;
  background-color: transparent;
  outline: none !important;
  min-width: 150px;
}

.ReserveReconciliationBodyWrapper,
.AccountReconciliationBodyWrapper {
  padding: 12px;
}

.ReserveReconciliationFooterWrapper,
.AccountReconciliationFooterWrapper {
  margin-top: 40px;
  padding: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 5px;
}

.AccountReconciliationTableWrapper {
  height: 100%;
}

.AccountReconciliationHeaderWrapper h4 {
  color: var(--font-color);
  font-size: 15px;
  font-weight: 600;
  line-height: 100%;
  text-transform: uppercase;
  margin: 0 0 0 10px;
}

.AccountDetailsMain {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  grid-gap: 12px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(32, 40, 66, 0.1);
}

.businessDtailsHeaderWrapper span {
  font-size: 14px;
  line-height: 100%;
}

.AccountDetailsImg {
  width: 50px;
  height: 50px;
  padding: 10px;
  border-radius: 50%;
  border: 1px solid rgba(32, 40, 66, 0.1);
}

.AccountDetailsName p {
  margin: 0;
  font-size: 14px;
}

.AccountDetailsDeleteIcon {
  cursor: pointer;
}

.ledgerSummaryWrapper {
  padding: 10px 0;
}

.ledgerSummaryWrapper p {
  color: var(--font-color);
  font-size: 14px;
  font-weight: 300;
  line-height: 100%;
}

.ledgerSummaryWrapper h4 {
  color: var(--font-color);
  font-size: 16px;
  font-weight: 600;
  line-height: 120%;
}

.businessDtailsHeaderWrapper span {
  white-space: nowrap;
}

.minWidth200 {
  min-width: 200px;
}

.occupancyCardMain .occupancyCardLeft {
  width: 70px;
}

/* --------------app.css Hiren-------------------------- */

/* .Occupancymain {
  min-height: 470px;
} */

.tab_wrap_remove {
  flex-wrap: nowrap;
}

/* 08-08-2023 */

.operationMaintenanceFirstSec .allFloorContainer > div {
  border-radius: 5px;
}

.operationMaintenanceFirstSec .roomsInPropertyInner .tab-content {
  height: auto;
  margin-bottom: 12px;
}

h2.commonHeadingH2 {
  color: var(--font-color);
  font-size: 17px;
  font-weight: 700;
  line-height: 100%;
}

h2.commonHeadingH2.maintenanceMainHeading {
  margin-top: 30px;
  line-height: 32px;
}

.maintenanceMainTabSec.nav-pills {
  justify-content: end;
  margin-top: -40px;
  margin-bottom: 20px;
}

.maintenanceMainTabSec .nav-link {
  padding: 6px 16px;
  line-height: 120%;
}

.maintenanceMainTabInnerSec {
  grid-template-columns: repeat(auto-fit, minmax(245px, 1fr));
}

.maintenanceMainTabInnerSec .allSettingsBoxContent {
  padding-top: 20px;
}

.maintenanceTableMain {
  margin-top: 15px;
}

.orange-badge {
  color: #ed6d2c;
  border-radius: 5px;
  background-color: rgba(237, 109, 44, 0.12) !important;
}

.tomato-badge {
  color: #eb2d59;
  border-radius: 5px;
  background-color: #fce5eb !important;
}

.dblue-badge {
  color: #28477d;
  border-radius: 5px;
  background-color: rgba(40, 71, 125, 0.12) !important;
}

/* modal fix height css */

/* .AddOpenProspectModalMain .tab-content {
  min-height: 460px;
} */

.AddAccountModalMain .tab-content,
.AddOpenProspectModalMain .tab-content {
  max-height: 470px;
  overflow-y: auto;
  overflow-x: hidden;
}

.tabContent {
  max-height: 470px;
  overflow-y: auto;
  overflow-x: hidden;
}

.AddAccountModalMain .tab-content::-webkit-scrollbar,
.AddOpenProspectModalMain .tab-content::-webkit-scrollbar {
  display: none;
}

.AddHotelsMain table input[type="checkbox"] {
  width: 15px;
  height: 15px;
}

/* 09-08-2023 */
.OperationTaskTableLastTdDesign > div {
  grid-gap: 4px;
  min-width: 36px;
}

.OperationTaskTableLastTdDesign {
  grid-gap: 12px;
}

.OperationTaskGridViewAllMain {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  grid-gap: 20px;
}

.OperationTaskGridViewAllMain .TaskGridViewWrapper {
  min-width: 295px;
}

.taskIconInformationMain {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 20px;
}

.taskIconInformationHigh,
.taskIconInformationNormal,
.taskIconInformationLow {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 6px;
}

.taskIconInformationHigh p,
.taskIconInformationNormal p,
.taskIconInformationLow p {
  margin: 0;
}

.TaskGridViewCardInnerWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.TaskGridViewCardInnerWrapper:not(:first-child) {
  margin-top: 5px;
}

.TaskGridViewCardInnerWrapper:not(:first-child) > div:last-child {
  width: 60px;
}

.TaskGridViewCardInnerWrapper:last-child > div:last-child {
  width: auto;
}

.taskIconInformationMain img {
  width: 18px;
  height: 18px;
}

.taskIconInformationMain p {
  font-size: 14px;
  line-height: 100%;
}

.OperationTaskGridViewCardWrapper {
  background: rgba(245, 247, 255, 0.5);
  border: 1px solid rgba(53, 101, 252, 0.2);
  border-radius: 5px;
  box-sizing: border-box;
  padding: 10px;
  margin-bottom: 10px;
}

.OperationTaskGridViewCardWrapper .TaskGridViewCardName {
  color: var(--font-color);
  font-family: "Roboto";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
}

.OperationTaskGridViewCardWrapper span {
  font-family: "Roboto";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.OperationTaskGridViewCardWrapper .TaskGridViewCardInnerWrapperData {
  display: flex;
  flex-direction: row;
  grid-gap: 8px;
  align-items: center;
}

.TaskGridViewCardInnerWrapperData img {
  width: 16px;
  height: 16px;
}

/* 10/08/2023 */

.uploadTaskImgMain {
  width: 120px;
  height: 120px;
  border-radius: 5px;
  border: 1px dashed rgba(53, 101, 252, 0.2);
  background: rgba(53, 101, 252, 0.03);
  display: flex;
  justify-content: center;
  align-items: center;
}

.taskModalTableAddBtn {
  background: #e1e8ff;
  border-radius: 5px;
  color: var(--blue);
  padding: 0;
  border: none;
  display: flex;
  align-items: center;
}

.AddAddTaskModalMain .stripped__table .form-select {
  border: 1px solid #cfd9f8 !important;
  font-size: 16px !important;
  padding: 10px;
}

/* 11/08/2023 */

.ItemNameModalMain,
.GroupNameModalMain {
  width: 330px;
  height: auto;
  border-radius: 5px;
  box-shadow: 0 1px 30px 0 rgba(0, 0, 0, 0.06);
}

.ItemNameModalMain .ItemNameModalHeader,
.GroupNameModalMain .GroupNameModalHeader {
  border-radius: 5px 5px 0 0;
  background: #ebf0ff;
  display: flex;
  padding: 7px 10px;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.ItemNameModalMain .ItemNameModalHeader h2,
.GroupNameModalMain .GroupNameModalHeader h2 {
  font-family: "Roboto";
  font-size: 17px;
  font-weight: 600;
  line-height: 100%;
  margin: 0;
}

.GroupMemberName:not(:last-child) {
  margin-bottom: 5px;
}

.GroupMemberName span,
.ItemMemberName span.roomNo {
  font-size: 15px;
  line-height: 100%;
  margin-top: 5px;
}

.GroupMemberName span,
.ItemMemberName span {
  font-size: 14px;
  line-height: 100%;
}

.GroupMemberName img {
  width: 35px;
  height: 35px;
}

.ItemNameModalMain .ItemNameModalBody,
.GroupNameModalMain .GroupNameModalBody {
  padding: 10px;
  border-bottom: 1px solid var(--light-bg);
  margin-bottom: 12px;
}

.ItemNameModalMain .ItemNameModalBody {
  display: grid;
  grid-template-columns: repeat(auto-fit, minMax(70px, 1fr));
  grid-gap: 10px;
}

.GroupNameModalMain .GroupNameModalBody .GroupMemberName {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 10px;
}

.ItemNameModalMain .ItemNameModalBody .ItemMemberName {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  border-radius: 5px;
  background: #eeeeee;
}

.ItemNameModalMain .ItemNameModalBody .ItemMemberName .roomNo {
  font-weight: bold;
}

.GroupNameWrapper {
  position: relative;
}

.modal-fullscreen .modal-body {
  max-height: 100%;
}

.modal-open:has(.EmailTempConfigModalMain) .viewListingModalMain {
  filter: blur(3px);
}

/* --------------app.css Hiren end-------------------------- */

.diffBgSelect .form-select {
  width: 128px;
  border-radius: 5px;
  background-color: rgba(32, 40, 66, 0.05);
  border: none !important;
  color: var(--font-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  padding: 6px 16px;
}

.rateShopRightMain {
  border-radius: 5px;
  /* background: url("../Images/Revenue-management/prop1.png") no-repeat; */
  width: 100%;
  aspect-ratio: 3 / 2;
  position: relative;
  height: 160px;
  background-size: 100% 100%;
  border: 1px solid #66666615;
}

.rateShopRightMain:not(:last-child) {
  margin-bottom: 10px;
}

.rateShopRightMain p {
  margin: 0;
  color: var(--white);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  text-align: center;
  border-radius: 0px 0px 5px 5px;
  background: #00000080;
  padding: 10px;
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
}

.rateShopRightMain .singleCheckBox {
  position: absolute;
  top: 7px;
  left: 7px;
}

.rateShopRightMain .singleCheckBox input {
  width: 16px;
  height: 16px;
  border-radius: 2px;
  border: 1px solid var(--blue-300);
  background-color: transparent;
}

.rateShopRightMain .singleCheckBox .form-check-input:checked {
  background-color: #43c4dd;
  border-color: #43c4dd;
}

.rateShopRightMain img {
  position: absolute;
  top: 7px;
  right: 7px;
}

.rateShopRightMainContainer {
  margin-top: 15px;
}

/* ------------------------------------------------------------------------------------------Calendar temp----------------------------------------------------------------------------------------------------------------- */

.calendarTickets img {
  cursor: pointer;
}

.ToolsSingleTicketPopUpMain {
  /* width: 400px; */
  width: 400px;
  max-height: 400px;
  overflow: auto;
}

.ToolsSummaryPopUpMain {
  width: 926px;
}

/* 
.dateAndBin {
  margin-bottom: 8px;
} */

.ToolsSingleTicketPopUpMain .GroupNameModalBody {
  padding: 20px;
  background: var(--white);
}

.dateAndBin p {
  margin: 0;
  color: #666;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

.ToolsSingleTicketPopUpMain h4 {
  margin-bottom: 10px;
  color: var(--font-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
}

.dateAndBin {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: rgb(32 40 66 / 80%);
}

p.ToolsSingleTicket {
  color: rgb(32 40 66 / 80%);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  margin-bottom: 10px !important;
}

.dateAndBinMain:not(:last-child) p.ToolsSingleTicket {
  border-bottom: 1px solid rgba(32, 40, 66, 0.3);
  padding-bottom: 20px;
}

.dateAndBinMain {
  border-bottom: 1px solid var(--light-bg);
  margin-bottom: 15px;
}

.dateWithArrow p {
  color: var(--font-color);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  text-align: center;
  margin: 0;
}

.dateWithArrow {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 25px;
  margin-bottom: 20px;
}

.dateWithArrow img {
  cursor: pointer;
  height: 20px;
  width: 20px;
}

.myRateCardsMain {
  border-radius: 10px;
  background: #ebf0ff;
  padding: 10px;
}

.myRateCardsMain p:not(:last-child) {
  margin-bottom: 5px;
}

.myRateCardsMain p {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  text-align: center;
  margin: 0;
}

.myRateCardsMain p:last-child {
  font-size: 16px;
  font-weight: 600;
}

.dateWithArrowBottom {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
  grid-gap: 10px;
  margin-bottom: 20px;
}

.ToolsSummaryPopUpMain .GroupNameModalBody {
  padding: 20px 25px;
}

.ToolsSummaryPopUpTable .stripped__table table {
  border: 1px solid rgba(53, 101, 252, 0.5);
}

.RateShopTableViewMain .houseKeepingSchduleHeadEndInner p {
  color: var(--font-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
}

.RateShopTableViewTable .dropdown-toggle::after {
  background: url("../Images/Revenue-management/verticalThreeDots.svg")
    no-repeat !important;
  border: none;
  height: 20px;
  width: 20px;
  top: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
  right: 0;
}

.RateShopTableViewTable .dropdown-toggle {
  position: relative;
}

.RateShopTableViewTable .Allactions.active .vertical-menu-option {
  position: absolute;
  right: 40%;
  top: 0;
  z-index: 5;
}

.RateShopTableViewTable .stickyLastRow:has(.vertical-menu-option) {
  z-index: 5 !important;
}

.RateShopTableViewTable .Allactions.active img {
  margin-right: 0;
}

.RateShopTableViewTable .Allactions.active {
  position: relative;
}

.vertical-menu-option {
  border-radius: 10px;
  background: #fff;
  box-shadow: 1px 2px 8px 0px rgba(0, 0, 0, 0.2);
  padding: 13px;
  min-width: 190px;
  text-align: left;
}

.vertical-menu-option p:not(:first-child) {
  padding-top: 13px;
}

.vertical-menu-option p:not(:last-child) {
  padding-bottom: 13px;
  border-bottom: 1px solid rgba(32, 40, 66, 0.3);
}

.vertical-menu-option p {
  color: var(--font-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  margin: 0;
}

/* .RateShopTableViewTable table tr {
  position: relative;
  z-index: 0;
}

.RateShopTableViewTable table tr:has(.vertical-menu-option) {
  position: unset;
  z-index: unset;
} */

.ticketAlarmReload img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.customFileInput {
  display: inline-block;
}

.inputBox .image-select {
  width: 118px;
  height: 118px;
}

.customFileInput input[type="file"] {
  display: none;
}

.customFileInput label {
  cursor: pointer;
}

.customFileInput span {
  color: var(--font-color);
  font-size: 18px;
  font-weight: 600;
  line-height: 100%;
  display: block;
  margin-bottom: 11px;
}

.insightOffcanvasMain.offcanvas-header {
  background: var(--light);
  height: 40px;
}

.insightTickParagraph {
  border-radius: 10px;
  background: rgba(53, 101, 252, 0.05);
  padding: 10px 10px 10px 5px;
  margin-bottom: 5px;
}

.insightTickTitle {
  display: flex;
  grid-gap: 10px;
  padding-left: 10px;
  align-items: center;
  position: relative;
  border-left: 2px solid var(--blue);
}

.insightDate {
  display: flex;
  justify-content: end;
  font-size: 11px !important;
}

.insightTickTitle p {
  margin: 0;
  color: var(--font-color);
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
}

.insightOffcanvasWrapper.offcanvas.offcanvas-end.show {
  width: 500px;
}

.nav-underline .nav-item button {
  font-size: 14px;
  line-height: 100%;
}

.insightsAccordion h5 {
  color: var(--font-color);
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
  margin-top: 25px;
  margin-bottom: 15px;
}

.insightsAccordion .accordion-header button {
  color: #1c1c1c;
  font-size: 14px;
  font-weight: 600;
  line-height: 100%;
  padding: 10px 12px;
  border-radius: 5px !important;
  background: #ebf0ff;
  text-transform: uppercase;
}

.insightsAccordion .accordion-item {
  margin-bottom: 5px;
  border: none;
}

/* .offcanvas.offcanvas-end:has(.insightOffcanvasMain) {
  top: 80px;
} */

.revenue-management-aside-main li div.revenue-aside-links:first-child {
  padding: 10px 0 !important;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: var(--font-color);
  border-bottom: 1px solid #bbcafe;
  cursor: pointer;
}

.insightsAccordion .accordion-header .accordion-button::after {
  width: 15px;
  height: 15px;
  background-size: contain;
}

.dropdown-item {
  font-size: 13px;
  padding: 3px 10px;
}

.dropdown-menu.show {
  padding: 5px 0;
}

.insightNotesInnerContent {
  width: 86%;
}

.insightNotesInnerEma h5 {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: 100%;
  margin-bottom: 3px;
}

.insightNotesInnerContent p {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: 100%;
  margin: 0;
}

/* .insightNotesInnerEma p:last-child{
  margin-top: 10px;
} */
.insightNotesInnerBottom {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: 100%;
  margin-top: 10px;
  margin-bottom: 0;
}

.insights-notes-detail p {
  color: var(--font-color);
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
  margin-top: 10px;
  margin-bottom: 0;
}

.insightNotesInnerLogo {
  width: 40px;
  height: 40px;
  /* padding: 10px; */
  background: rgba(53, 101, 252, 0.05);
  border-radius: 50%;
}

.insightNotesInnerLogo img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.insightNotesMain:not(:last-child) p.insightNotesInnerBottom {
  padding-bottom: 15px;
  border-bottom: 1px solid var(--font-color);
}

.insightNotesInnerBottom {
  padding-bottom: 15px;
  border-bottom: 1px solid var(--font-color);
}

.insightNotesMain:not(:first-child) {
  padding-top: 20px;
}

/*.widget-container .widget-container-menu {
  right: 43px;
  position: absolute;
  z-index: 99;
  display: none;
  margin-top: 4px;
}

.widget-container .widget-container-menu .heading-menu-btns {
  padding: 5px !important;
}

.widget-container:hover .widget-container-menu {
  display: flex !important;
}*/

.widget-container {
  position: relative !important;
}

.widget-container .widget-container-menu {
  right: 5px;
  position: absolute;
  z-index: 99;
  display: none;
  margin-top: -25px;
  padding-bottom: 5px;
}

.widget-container .widget-container-menu .heading-menu-btns {
  padding: 2px !important;
}

.widget-container:hover .widget-container-menu {
  display: flex !important;
}

.widget-container-1 {
  position: relative !important;
}

.widget-container-1 .widget-container-menu {
  right: 5px;
  position: absolute;
  z-index: 99;
  display: none;
  margin-top: -25px;
  padding-bottom: 5px;
}

.widget-container-1 .widget-container-menu .heading-menu-btns {
  padding: 2px !important;
}

.widget-container-1:hover .widget-container-menu {
  display: flex !important;
}

.widget-container-2 {
  position: relative !important;
}

.widget-container-2 .widget-container-menu {
  right: 5px;
  position: absolute;
  z-index: 99;
  display: none;
  margin-top: -25px;
  padding-bottom: 5px;
}

.widget-container-2 .widget-container-menu .heading-menu-btns {
  padding: 2px !important;
}

.widget-container-2:hover .widget-container-menu {
  display: flex !important;
}

.widgetDetailModal .close-button {
  display: none;
}

.meghaDatePicker .react-datepicker-popper,
.meghaDatePicker .react-datepicker {
  width: 600px !important;
}

.meghaDatePicker .react-datepicker__month-container {
  width: 50% !important;
}

.meghaDatePicker
  .react-datepicker
  .react-datepicker__month-container
  .react-datepicker__month {
  min-height: 195px;
}

.meghaDatePicker
  .react-datepicker
  .react-datepicker__month-container:not(:last-child)
  .react-datepicker__month,
.meghaDatePicker
  .react-datepicker
  .react-datepicker__month-container:not(:last-child)
  .react-datepicker__day-names {
  border-right: 1px solid #9ab2fd !important;
}

.meghaDatePicker .react-datepicker__navigation {
  top: 4px;
}

.meghaDatePicker .react-datepicker__day-names,
.meghaDatePicker .react-datepicker__week {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.meghaDatePicker
  .react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  border-bottom-color: #9ab2fd;
}

.meghaDatePicker
  .react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  border-bottom-color: var(--white);
}

.meghaDatePicker .react-datepicker__navigation-icon::before {
  border-color: #9ab2fd;
}

.meghaDatePicker .react-datepicker__day-names {
  background: var(--light);
  border-top: 1px solid #9ab2fd;
}

.meghaDatePicker .react-datepicker__header {
  text-align: center;
  background-color: var(--white);
}

.meghaDatePicker .react-datepicker__month {
  margin: 0;
}

.meghaDatePicker .react-datepicker__day--keyboard-selected:hover,
.meghaDatePicker .react-datepicker__month-text--keyboard-selected:hover,
.meghaDatePicker .react-datepicker__quarter-text--keyboard-selected:hover,
.meghaDatePicker .react-datepicker__year-text--keyboard-selected:hover,
.meghaDatePicker
  .react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .meghaDatePicker .react-datepicker__month-text--in-range,
    .meghaDatePicker .react-datepicker__quarter-text--in-range,
    .meghaDatePicker .react-datepicker__year-text--in-range
  ) {
  background-color: #9ab2fd;
}

.meghaDatePicker .react-datepicker__day--keyboard-selected,
.meghaDatePicker .react-datepicker__month-text--keyboard-selected,
.meghaDatePicker .react-datepicker__quarter-text--keyboard-selected,
.meghaDatePicker .react-datepicker__year-text--keyboard-selected {
  background-color: var(--light);
  color: var(--blue);
}

.editorWrapper {
  overflow-y: auto;
}

.editorToolBar {
  position: sticky;
  top: 0;
  z-index: 9999 !important;
}

.only_icon_calendar .react-datepicker-wrapper {
  width: 30px;
  margin-left: 5px;
}

.only_icon_calendar .react-datepicker-wrapper input {
  padding: 5px 10px;
}

.only_icon_calendar .react-datepicker__input-container:after {
  background-color: #ffffff;
}

.ViewPropertySetupTable table th,
.ViewPropertySetupTable table td {
  padding: 5px 5px;
  vertical-align: middle;
}

.ViewPropertySetupTable .RoomsPercentilesData {
  grid-gap: 10px;
  display: flex;
  padding: 10px;
}

.ViewPropertySetupTable table#strategyTable thead th {
  white-space: pre-wrap;
}

.borderTd {
  border: 1px solid black;
  border-right: none;
}

.borderTdLeft {
  border: 1px solid black;
  border-left: none;
}

.widget-table-header-input {
  padding: 2px !important;
  font-size: 14px !important;
}

.widget-table-header-input.react-datepicker__input-container::after {
  height: 16px !important;
  width: 16px !important;
}

/* calendar main css -------------------------- */
.fullCustomCalendarTable thead th {
  background: transparent;
  color: #111111;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  text-transform: uppercase;
  border: none;
  padding: 0;
}

.fullCustomCalendarTable tbody td {
  position: relative;
  border-radius: 0px 0px 5px 5px;
  background-color: rgb(235 240 255 / 40%);
  /* width: 164px; */
  height: 130px;
  border-top: 2px solid #b5c6ff;
  /* border-bottom: 6px solid #37d0a3; */
  padding: 8px;
  z-index: 1;
}

.fullCustomCalendarTable tbody td.today {
  background-color: rgb(255 250 223);
}

.fullCustomCalendarTable tbody td.todayDate p {
  font-weight: bold !important;
  /* border: 2px solid; */
  border-radius: 100%;
  width: 25px;
  height: 25px;
  font-size: 18px;
  background-color: black !important;
  color: white !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  background-color: black !important;
  color: white !important;
}

.fullCustomCalendarTable table {
  border-collapse: separate;
  border-spacing: 12px;
}

.main-date-box-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-date-box-inner img {
  cursor: pointer;
}

.main-date-box-inner p {
  margin-bottom: 0;
  color: var(--font-color);
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 100%;
}

.main-date-box-all-icons {
  display: flex;
  justify-content: center;
  grid-gap: 5px;
}

.all-icons-group-icon img {
  margin-right: 6px;
}

.all-icons-group-icon {
  color: var(--font-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  border-radius: 30px;
  background: rgba(181, 198, 255, 0.4);
  padding: 2px 6px;
}

/* td.main-date-box-with-bg {
  background-image: url("../Images/Revenue-management/groupTickets.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  cursor: pointer;
  position: relative;
} */
td.main-date-box-with-bg.sold-out-date {
  background-image: url("../Images/Revenue-management/soldout.svg");
  background-size: 60px 60px;
  border-bottom: 6px solid #cbcbcb;
  background-repeat: no-repeat;
  background-position: center;
}

td.color-opacity {
  /* background:linear-gradient(to right, red , yellow); */
}

.month-name-with-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 5px;
  /* margin-left: 50%; */
  margin-bottom: 30px;
}

.month-name-with-arrow button {
  background: transparent;
  border: none;
}

.month-name-with-arrow h4 {
  color: var(--font-color);
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  margin-bottom: 0;
  text-align: center;
  width: 160px;
}

.allDateDetailPercentInner p {
  font-size: 14px;
  line-height: 100%;
}

.sold-out-date .main-date-box-all-icons {
  display: none;
}

td.main-date-box-with-bg.disableDate {
  opacity: 0.7;
  color: var(--font-color);
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 100%;
  background-image: none;
  cursor: not-allowed;
  background: #f6f6f6;
}

.revenueOnSameDate {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  color: var(--black);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.allDateDetailPercent {
  /* display: flex;*/
  justify-content: end;
  align-items: center;
  grid-gap: 17px;
}

.fullCustomCalendarMainWrapper {
  margin-bottom: 15px;
  margin-top: 10px;
}

.offcanvas-title.h5 {
  font-size: 17px;
}

.allDateDetailPercentInner p {
  margin-bottom: 0;
}

.allDateDetailPercentInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 8px;
  /*This 2 line Added By Satish*/
  padding-left: 10px;
  float: right;
}

.allDateDetailPercentInner div {
  width: 14px;
  height: 14px;
  border-radius: 3px;
  /* background: #009a4f; */
}

td.main-date-box-with-bg.border-bottom-yellow {
  border-bottom: 6px solid #ffb800;
}

td.main-date-box-with-bg.border-bottom-red {
  border-bottom: 6px solid #ea2b12;
}

td.main-date-box-with-bg.border-bottom-grey {
  border-bottom: 6px solid #cbcbcb;
}

td.main-date-box-with-bg.border-bottom-green {
  border-bottom: 6px solid #37d0a3;
}

.backgroundForSyncEvent td {
  background-color: #e2f1fd !important;
}

.grpNameRightSingle .form-radio label::before,
.grpNameRightSingle .form-radio label::after {
  top: 9px;
}

.GroupNameModalBodyRightMain {
  border-left: 1px solid #d7e0fe;
}

.grpNameRightSingle .form-radio:not(:last-child) {
  border-bottom: 1px solid #d7e0fe;
  padding-bottom: 10px;
  margin-bottom: 12px;
}

.grpNameRightSingle .form-radio label {
  margin-top: 0;
  font-size: 14px;
}

.GroupNameModalBodyRightInner h5 {
  color: var(--font-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  margin-bottom: 15px;
}

.dateAndBin img {
  cursor: pointer;
}

.ToolsSingleTicketMain {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
}

.fullCustomCalendarTable tr {
  z-index: 1;
}

/* .GroupNameModalBodyRightInner {
    padding-left: 8px;
} */
.nestedTableRowBlock {
  display: block;
}

.nestedTableRowNone {
  display: none;
}

.fullCustomCalendarTable .vertical-menu-option {
  z-index: 2;
}

.fullCustomCalendarTable table {
  position: relative;
}

/* temp calendar-------------- */
.temp-calendar-td {
  width: 100%;
  height: 100%;
  position: relative;
}

.heatmap-main .only_icon_calendar .react-datepicker-wrapper {
  width: unset;
}

.only_icon_calendar
  .react-datepicker-wrapper
  .react-datepicker__input-container
  input {
  padding: 0 !important;
  border: none !important;
}

.heatmap-main .only_icon_calendar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  grid-gap: 25px;
}

.heatmap-main .only_icon_calendar.form-radio label {
  margin-top: 0;
}

.heatmap-main {
  width: 100%;
  justify-content: center;
  grid-gap: 25px;
}

.offcanvas-header .btn-close {
  padding: 0;
  width: 25px;
  height: 25px;
  opacity: 1;
}

.singleDateSummaryModalMain {
  width: 100%;
  padding: 0;
  background: white;
  border-radius: 10px;
  height: 350px;
  box-shadow: 0px 0px 50px rgba(138, 149, 179, 0.4);
}

.horizontal-menu-option {
  width: 700px;
}

.singleDateSummaryModalMain .GroupNameModalHeader {
  padding: 10px 10px;
  position: sticky;
  top: 0;
  left: 0;
  background: #ebf0ff;
  z-index: 1;
}

.singleDateSummaryModalMain .GroupNameModalHeader h2 {
  font-size: 16px;
}

.singleDateSummaryModalMain .btn-close {
  width: 10px;
  height: 10px;
  opacity: 1;
}

.singleDateSummarySpan {
  position: relative;
}

/* .singleDateSummarySpan::before {
  content: "";
  position: absolute;
  top: 0;
  left: 100%;
  background: url("../Images/Revenue-management/rightFilled.svg") no-repeat;
  background-size: cover;
  height: 20px;
  width: 20px;
} */
.ViewPropertySetupTable table tbody tr:has(.singleDateSummarySpan) > td {
  background: #a7d6ff !important;
}

.singleDateSummaryModalMain p {
  margin-bottom: 0;
}

.singleDateSummaryModalMain table {
  border-width: 0 !important;
}

.perbackground {
  position: relative;
  z-index: 1;
}

.perbackground .bgdiv {
  height: 100%;
  right: 0;
  position: absolute;
  z-index: -1;
  opacity: 0.5;
  top: 0px;
}

.dragableRowWrapper .horizontal-resize {
  resize: horizontal;
  overflow: auto;
  position: relative;
}

.dragableRowWrapper .horizontal-resize[isresize="false"] {
  resize: unset !important;
  overflow: unset !important;
}

.icon-drag {
  cursor: move;
}

.icon-remove,
.icon-add {
  cursor: pointer;
}

.generalTermSetup-heading.component-option {
  margin-bottom: 0px;
}

.removeRowBtnMain.col-remove {
  background: #eaf1fb !important;
  width: 35px;
}

button.setting-button {
  background: url("../Images/Configuration/filter-icon.svg") no-repeat center;
  border: none;
  background-size: contain;
  width: 20px;
  height: 20px;
}

button.setting-button:hover,
button.setting-button:focus,
button.setting-button:focus-visible,
button.setting-button:active {
  --bs-btn-active-bg: transparent;
  background-color: transparent;
}

button.close-button:hover,
button.close-button:focus,
button.close-button:focus-visible,
button.close-button:active {
  --bs-btn-active-bg: transparent;
  background-color: transparent;
}

/* .ViewPropertySetupTable .table-responsive {
    max-height: 100vh;
    overflow: auto;  
  }
  
  .ViewPropertySetupTable table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .ViewPropertySetupTable table thead {
    position: sticky;
    top: 0; 
    background-color: white; 
    z-index: 2;
  }
   */
/* .ViewPropertySetupTable
  .react-datepicker-wrapper
  .react-datepicker__input-container
  input {
  border: none !important;
} */
.ViewPropertySetupTable .react-datepicker__input-container::after {
  width: 15px;
  height: 15px;
}

.allTableTogather {
  overflow-x: auto;
  display: flex;
  justify-content: space-between;
  /* border: 1px solid #e1e8ff;
  border-radius: 10px; */
  /* border-width: 1px 0 1px 0; */
}

.allTableTogather .ViewPropertySetupTable {
  overflow: unset;
  width: 100%;
  border: none !important;
  border-width: 0 0 0 0 !important;
  border-radius: 0;
}

.allTableTogather .ViewPropertySetupTable .stripped__table .form-select {
  border: none !important;
  background-color: transparent !important;
  font-size: 11px !important;
  padding: 0px 2px;
  line-height: 100%;
  font-weight: 600;
}

.ViewPropertySetupTable table * {
  font-size: 11px !important;
  line-height: 100%;
}

.ViewPropertySetupTable:not(
    .only_icon_calendar
      .react-datepicker-wrapper
      .react-datepicker__input-container
      input
  )
  .react-datepicker-wrapper
  .react-datepicker__input-container
  input::-webkit-input-placeholder {
  font-size: 12px;
}

.ViewPropertySetupTable:not(
    .only_icon_calendar
      .react-datepicker-wrapper
      .react-datepicker__input-container
      input
  )
  .react-datepicker-wrapper
  .react-datepicker__input-container
  input {
  font-size: 11px;
  padding: 1px 3px;
  line-height: 100%;
}

#tblMstReservation .react-datepicker-wrapper {
  max-width: 350px;
}

.ViewPropertySetupTable thead .form-select {
  font-weight: 600;
  font-size: 11px !important;
  line-height: 100%;
}

/* ________________________________________________________________________________________________________________ */

/* .ViewPropertySetupTable .sticky-header {
  position: sticky;
  top: 81px;
  background: white;
  z-index: 9999;
}
.ViewPropertySetupTable table:has(.sticky-header) {
  position: sticky;
  top: 81px;
  background: white;
}
.ViewPropertySetupTable:has(:not(.sticky-header)) {
  overflow-x: auto;
  border: 1px solid var(--light-bg);
} */
/* .ViewPropertySetupTable:has(.sticky-header) {
  overflow: auto;
  height: 100vh;
} */
/* .ViewPropertySetupTable > div:has(.sticky-header) {
    overflow-x: auto;
  }
  .ViewPropertySetupTable > div :has(:not(.sticky-header)) {
    overflow: hidden;
  } */
.sticky-header {
  position: sticky;
  top: 81px;
  background: white;
  z-index: 99;
}

/* ________________________________________________________________________________________________________________ */

.RateShopConfigHeader {
  border-radius: 5px;
  background: #e8edfc;
  padding-left: 5px;
}

.RateShopConfigHeader .form-select {
  background-color: transparent;
  border: none !important;
  outline: none;
  color: var(--font-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  text-transform: uppercase;
  max-width: 160px;
}

.RateShopConfigHeader .form-select option {
  text-transform: none;
}

.RateShopConfigLeftTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 10px;
}

.RateShopConfigLeftTop h5 {
  color: var(--black);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  margin-bottom: 0;
}

.RateShopConfigLeftTop img {
  width: 16px;
  height: 16px;
}

.ViewPropertySetupTable table thead th {
  background-color: var(--light);
}

.RateShopConfigBottomInneContent p:last-child,
.RateShopConfigBottomInneContent span {
  color: var(--font-color);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  text-transform: lowercase;
  margin: 0;
}

.RateShopConfigBottomInneContent .form-select {
  padding: 7px 10px;
  color: var(--font-color);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  min-width: 90px;
  height: unset;
}

.RateShopConfigBottomInneContent span {
  border-radius: 5px;
  border: 1px solid rgba(53, 101, 252, 0.2);
  background: var(--white);
  padding: 7px 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.RateShopConfigBottomInneContent p:first-child {
  color: var(--font-color);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  text-transform: uppercase;
  margin: 0;
  margin-right: 10px;
}

.RateShopConfigBottom {
  border-radius: 10px;
  border: 1px solid #d7e0fe;
  min-height: 209px;
  position: relative;
}

.RateShopConfigBottomInneContent,
.RateShopConfigBottomInner {
  display: flex;
  align-items: center;
}

.RateShopConfigBottomInner {
  grid-gap: 25px;
}

.RateShopConfigBottomInner {
  border-bottom: 1px solid rgba(32, 40, 66, 0.15);
  padding: 5px 10px;
  max-height: 38px;
}

.RateShopConfigBottomBtns {
  position: absolute;
  bottom: 0;
  right: 0;
}

.RateShopConfigcolorWithCode {
  display: flex;
  align-items: center;
  grid-gap: 8px;
}

.RateShopConfigcolorWithCode img {
  width: 26px;
  height: 26px;
}

.RateShopConfigBottomInneContent .RateShopConfigcolorWithCode span {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: #009a4f;
  margin: 0;
  border: none;
  padding: 0;
}

.colorWithCode {
  display: flex;
  align-items: center;
  grid-gap: 6px;
  border-radius: 5px;
  border: 1px solid rgba(53, 101, 252, 0.2);
  background: #fff;
  padding: 7px;
}

.singleDateSummaryModalMain table * {
  font-size: 11px !important;
}

.asideConfigurationMain.revenue-management-aside-main *,
.asideConfigurationMain * {
  font-size: 14px !important;
  line-height: 100% !important;
}

.textEditor span {
  font-size: 14px;
  line-height: 100%;
}

.form-check-label {
  font-size: 14px;
  line-height: 100%;
}

.employeeDetailCheckboxes {
  display: flex;
  align-items: baseline;
  grid-column-gap: 10px;
  margin-bottom: 10px;
}

.DeviceDetailModalMain-inputs {
  font-size: 16px;
  line-height: 150%;
}

.allGridBoxWrapperWithTitle h6 {
  font-size: 14px;
  line-height: 100%;
}

.form-check {
  min-height: unset;
}

.RoomsPercentilesData p {
  margin: 0;
}

.RoomsPercentilesData div p {
  padding: 3px;
}

.RoomsPercentilesData {
  align-items: center;
}

.RoomsPercentilesData * {
  font-size: 14px;
  line-height: 100%;
}

.month-name-with-arrow button img {
  width: 15px;
  height: 15px;
  object-fit: contain;
}

.nav-pills .nav-link {
  font-size: 14px;
  padding: 5px 10px;
}

.crmSettingsMain .top-center-tabs.nav-pills {
  margin-top: -25px !important;
}

span.Customlabel {
  font-size: 12px;
  line-height: 100%;
}

.actionLogModalPara {
  font-size: 14px;
  margin: 0;
  line-height: 120%;
}

.allTableTogatherWrapper .ViewPropertySetupTable {
  border: none;
}

.allTableTogather-widget,
.ViewPropertySetupTable.monthSelectTable {
  border: 1px solid #cfd9f8;
  border-radius: 10px;
}

.allTableTogatherWrapper,
.allTableTogather {
  grid-gap: 5px;
}

/* .popUpOfHeatMap .singleDateSummaryModalMain .stripped__table {
  border-radius: 10px;

} */
/* .popUpOfHeatMap .singleDateSummaryModalMain table.heatmapBorder,.popUpOfHeatMap .singleDateSummaryModalMain .stripped__table th,.popUpOfHeatMap .singleDateSummaryModalMain .stripped__table td {
  border: 1px solid #cfd9f8;
  margin: 10px;
}
.popUpOfHeatMap .singleDateSummaryModalMain table.heatmapBorder {
  border-radius: 10px;
  border-spacing: 5px;
} */
.singleDateSummaryModalContent {
  padding: 15px;
}

/* .singleDateSummaryModalContent .ViewPropertySetupTable {
  border: none;
} */
.ForecastHorizontalChartInner {
  border: 1px solid #cfd9f8;
  border-radius: 10px;
  padding: 10px;
}

.snapShotHorizontalChart .ForecastHorizontalChartInner {
  border: none;
}

.legends-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 15px;
}

.legends-list li span:last-child {
  font-size: 13px;
  line-height: 100%;
}

.legends-list li {
  display: flex;
  align-items: center;
}

/* .ViewPropertySetupTable tr.singleDateSummmaryTr td p{
  color: var(--blue);
} */
/* ___________________________________________________________________________________________________________________________________________________________________________ */

.eventmodalBtn img {
  cursor: pointer;
}

.eventDetailInner2 img {
  margin-right: 5px;
}

.eventDetailInner2 {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  border-bottom: 1px solid rgba(32, 40, 66, 0.15);
  padding-bottom: 20px;
}

.eventDetailInner2 p {
  color: var(--font-color);
  font-size: 12px;
  font-weight: 400;
  line-height: 100%;
  padding: 5px;
  border-radius: 3px;
  background: #d1dbff;
  margin: 0;
}

.eventDetailInner p {
  color: var(--font-color);
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
  margin-bottom: 10px;
}

.eventDetailInner h4 {
  color: var(--font-color);
  font-size: 22px;
  font-weight: 500;
  line-height: 100%;
  margin-bottom: 10px;
}

.RmEventDetailCardMain {
  padding: 15px;
  border-radius: 10px;
  background: #f3f6ff;
}

.eventDetailInner3 span {
  color: var(--font-color);
  font-size: 12px;
  font-weight: 500;
  line-height: 100%;
  margin-bottom: 5px;
}

.eventDetailInner3 {
  padding-bottom: 15px;
  padding-top: 15px;
  border-bottom: 1px solid rgba(32, 40, 66, 0.15);
}

.eventDetailInner3 p {
  color: var(--font-color);
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
  margin: 0;
}

.eventDetailInner4 {
  display: flex;
  align-items: center;
  grid-gap: 15px;
  border-bottom: 1px solid rgba(32, 40, 66, 0.15);
  padding-bottom: 15px;
  padding-top: 15px;
}

.eventDetailInner4 p,
.eventDetailInner5 p {
  color: var(--font-color);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  margin: 0;
}

.eventDetailInner4 h5 {
  color: var(--font-color);
  font-size: 15px;
  font-weight: 600;
  line-height: 100%;
  margin-bottom: 0;
}

.eventDetailInner4 .eventDetailInnerCircular {
  padding: 15px;
  border-radius: 50%;
  border: 2px solid #a8bdff;
  text-align: center;
}

.eventDetailInner4 .eventDetailInnerCircular:first-child {
  border: 2px solid #ffa9a9;
}

.eventDetailInner5 {
  display: flex;
  align-items: center;
  grid-gap: 15px;
  padding-top: 15px;
}

.eventDetailInner5 span {
  color: var(--font-color);
  font-size: 12px;
  font-weight: 500;
  line-height: 100%;
}

.gmap_canvas {
  overflow: hidden;
  height: 106px;
  width: 170px;
}

.gmap_canvas iframe {
  position: relative;
  z-index: 2;
}

.eventDetailFooterBtn {
  display: flex;
  align-items: center;
  grid-gap: 6px;
}

.ToolsEventModalInner {
  position: relative;
}

/* .ToolsEventModalInner button.btn-close {
  position: absolute;
  top: 15px;
  right: 15px;
} */

.RmEventFilterMain .offcanvas-title.h5 {
  color: var(--font-color);
  font-size: 18px;
  font-weight: 600;
  line-height: 100%;
}

.offcanvas-header {
  background: #ebf0ff;
}

.RmEventFilterAccordion .accordion-body {
  background: #fcfcfc;
}

.RmEventFilterAccordion {
  margin-top: 15px;
}

.RmEventFilterAccordion .accordion-item {
  background: #fcfcfc;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.08);
  border: none;
}

.RmEventFilterAccordion .accordion-header {
  border: 1px solid #dedfe3;
  border-width: 1px 0 1px 0;
}

.RmEventFilterAccordion:has(.accordion-button:not(.collapsed)) .accordion-body {
  border: 1px solid #dedfe3;
  border-width: 0 0 1px 0;
}

.RmEventFilterAccordion:has(.accordion-button:not(.collapsed))
  .accordion-header {
  border-width: 1px 0 0 0;
}

.RmEventFilterAccordion .accordion-button:not(.collapsed) {
  background: #fcfcfc;
  padding: 16px 5px 0px;
  box-shadow: none;
}

.RmEventFilterAccordion .accordion-header button {
  color: var(--font-color);
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
  padding: 16px 5px;
}

button.clearAllBtn {
  border: none;
  background: transparent;
  color: var(--blue);
  font-size: 12px;
  font-weight: 600;
  line-height: 100%;
}

.RmEventFilterAccordionInner .search-group-for-table.input-group {
  max-width: 300px;
}

.RmEventFilterAccordionWrapper .form-check-label {
  color: var(--font-color);
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
}

.RmEventFilterBottomPartInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
}

.RmEventFilterBottomPartInner span {
  color: #291f1f;
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
}

.RmEventFilterMain .progress {
  height: 8px;
}

.RmEventFilterBottomPart .progress-bar {
  position: relative;
}

.RmEventFilterMainWrapper .circular-point {
  content: " ";
  width: 22px;
  height: 22px;
  position: absolute;
  top: 50%;
  left: calc(100% - 11px);
  /* Adjust the position as needed */
  background: url("../Images/Revenue-management/progressDot.svg") no-repeat;
  background-size: contain;
  transform: translateY(-50%);
}

.RmEventFilterBottomPartProgressLabels span {
  color: #291f1f;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.RmEventFilterBottomPartProgressLabels {
  padding-bottom: 15px;
  border-bottom: 1px solid #dedfe3;
}

.RmEventFilterBottomAttendance h6 {
  color: var(--font-color);
  font-size: 13px;
  font-weight: 500;
  line-height: 100%;
  margin-bottom: 10px;
}

.RmEventFilterBottomAttendance {
  padding-top: 15px;
  padding-bottom: 15px;
}

.RmEventFilterBottomFooterButton {
  display: flex;
  justify-content: end;
  grid-gap: 5px;
  align-items: center;
  position: fixed;
  bottom: 15px;
  right: 20px;
  background: var(--white);
  z-index: 999;
}

.RmEventFilterMain .offcanvas-body {
  position: relative;
}

.RmEventFilterLocation span,
.RmEventFilterLocation p {
  color: var(--font-color);
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
}

.RmEventFilterLocation {
  margin-top: 10px;
}

.RmEventFilterMain .accordion-body {
  padding: 10px 5px;
}

.RmEventFilterLocationInner img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.RmEventFilterMainWrapper {
  overflow-y: auto;
  height: calc(100vh - 130px);
}

.RmEventFilterMainWrapper::-webkit-scrollbar {
  display: none;
}

.RmEventFilterMainWrapper .form-switch {
  padding-left: 35px;
}

.RmEventFilterAccordion .accordion-item:last-child {
  border-bottom: 1px solid #dedfe3 !important;
}

.operation-portfolio-main .tab-content:not(:last-child) {
  border-bottom: 1px solid #caced9;
  padding-bottom: 5px;
}

.operation-portfolio-main {
  background: #ffffff;
  border: 1px solid #cfd9f8;
  border-radius: 10px;
  padding: 12px;
}

.operation-portfolio-main .nav-item a {
  padding: 10px 24px !important;
  color: var(--font-color);
  font-size: 14px;
  font-weight: 400;
}

.operation-portfolio-main .nav-pills .nav-item a {
  padding: 5px 20px !important;
}

.operation-portfolio-main .nav-pills {
  justify-content: space-between;
  margin-top: 15px;
}

.operation-portfolio-main .tab-content {
  align-items: center;
  padding-top: 5px;
}

.revenue-portfolio-tab-icons img {
  width: 15px;
  height: 15px;
}

.RmToolEventCardMain {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  grid-gap: 10px;
  padding: 4px 6px;
  border-radius: 3px;
}

.RmToolEventCardMain p {
  color: var(--font-color);
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
  margin: 0;
}

.WeekViewDayMain h5 {
  color: #111111;
  font-size: 16px;
  font-weight: 500;
  line-height: 100%;
  text-transform: uppercase;
  margin-top: 30px;
  margin-bottom: 12px;
}

.WeekViewDayMain h5 span {
  color: var(--blue);
}

.WeekViewDayEventWrapper {
  border: 1px solid #d7e0fe;
  border-top: 2px solid #b5c6ff;
  padding: 10px;
  border-radius: 0px 0px 5px 5px;
  background: #fff;
  height: calc(100vh - 230px);
  overflow-y: auto;
}

.WeekViewDayEventWrapper::-webkit-scrollbar {
  display: none;
}

.WeekViewDayEventWrapper h6 {
  color: var(--font-color);
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 100%;
  margin-bottom: 10px;
}

.WeekViewDayMain {
  min-width: 204px;
  max-width: 204px;
}

.WeekViewDayEventCardWrapper .RmToolEventCardMain:not(:last-child) {
  margin-bottom: 5px;
}

.RmEventsWeekViewInner {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  grid-gap: 18px;
  width: 100%;
  overflow: auto;
}

.WeekViewDayEventCardWrapper .RmToolEventCardMain {
  cursor: pointer;
}

.RmEventListingPageInner {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(498px, 1fr));
  grid-gap: 20px;
  margin-top: 30px;
}

.horizontal-menu-option.active.singleDayGroupSummary
  .singleDateSummaryModalMain {
  width: 400px;
  height: 250px !important;
}

.horizontal-menu-option.active.singleDayGroupSummary .YieldModalMain {
  width: 400px;
  height: 140px !important;
}

.horizontal-menu-option.active.singleDayGroupSummary {
  max-width: 400px;
}

table.table .no-border-break input {
  height: 20px;
  font-size: 12px;
}

.stickycolumn {
  position: sticky;
}

th.stickycolumn {
  background-color: rgb(241, 244, 252) !important;
}

.fullCustomCalendarTable .vertical-menu-option {
  border-radius: 0px 10px 10px 10px !important;
}

.dropDownTrColor td {
  /* background-color: "#A7D6FF" !important; */
  background-color: rgb(167, 214, 255) !important;
}

.singleDateSummmaryTr td {
  background-color: rgb(167, 214, 255) !important;
}

.singleDateSummaryModalContent tr.active td {
  background-color: rgb(167, 214, 255) !important;
}

tr.activeRow td {
  background-color: rgb(167, 214, 255) !important;
}

.iconcolor {
  filter: brightness(0) !important;
}

.reminderModalMain .GroupNameModalBody .card-body {
  padding: 8px;
}

.reminderModalMain .GroupNameModalBody p.ToolsSingleTicket {
  font-size: 14px !important;
  margin-bottom: 10px;
}

.reminderModalMain .GroupNameModalBody .card-header {
  color: var(--blue);
  padding: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  /* margin-bottom: 10px; */
  text-transform: capitalize;
  /* background-color: var(--light); */
  border: none;
  border: none;
}

.reminderModalMain .card {
  border: none;
  box-shadow: 0 3px 5px -2px var(--blue-300);
}

/* .GroupNameModalBody .card-body{
    min-height: 150px;
} */
.GroupNameModalBody:not(:last-child) {
  margin-bottom: 20px;
}

.reminderModalMain .modal-body {
  max-height: 400px;
  overflow: auto;
}

/* #tblDataRevenueManagementDashboard thead th .search-group-for-table input {
  background-color: transparent !important;
} */
/* #tblDataRevenueManagementDashboard thead th .search-group-for-table {
  background-color: transparent !important;
  border-color: #83c6ec;
} */
/* #tblDataRevenueManagementDashboard thead th {
  background-color: #c9e6f7 !important;
  border-bottom: 1px solid #83c6ec;
} */
table#tblDataRevenueManagementDashboard {
  padding: 0;
  /* border-color: #83c6ec; */
}

.ViewPropertySetupTable table#tblDynamicPickup thead th,
.ViewPropertySetupTable table#tblWidgetPickup thead th {
  z-index: 1;
}

th.stickycolumn {
  z-index: 99 !important;
}

.ViewPropertySetupTable table#tblDynamicPickup thead tr:first-child th,
.ViewPropertySetupTable table#tblWidgetPickup thead tr:first-child th {
  position: sticky !important;
  top: 0 !important;
  /* z-index: 999; */
  /* background: white !important; */
}

.ViewPropertySetupTable table#tblDynamicPickup thead th,
.ViewPropertySetupTable table#tblWidgetPickup thead th {
  background: #f2f4fc !important;
}

.ViewPropertySetupTable table#tblDynamicPickup thead,
.ViewPropertySetupTable table#tblWidgetPickup thead {
  position: sticky !important;
  top: 0 !important;
  z-index: 999;
}

.tblDynamicPickupSticky {
  max-height: 80vh !important;
  overflow-y: auto !important;
}

.tblTopMsSticky {
  max-height: 288px !important;
  overflow-y: auto !important;
}

.maxHeight400 {
  max-height: 360px !important;
  overflow-y: auto !important;
}

.modal-body.my-tab-content-height .footer-buttons {
  bottom: 0;
  padding-bottom: 20px;
}

.modal-body.my-tab-content-height {
  height: 500px;
  overflow: auto;
  min-height: 500px;
  padding-bottom: 0;
}

.my-tbl-header-sticky tr th {
  background-color: white !important;
}
/*.ViewPropertySetupTable:has(#tblDynamicPickup) .table-responsive,
.ViewPropertySetupTable:has(#tblWidgetPickup) .table-responsive {
  height: 800px !important;
  overflow-y: auto !important;
}*/
.boldText {
  font-weight: bold !important;
  background: #f2f4fc;
}

.event-strip-with-bg {
  display: flex;
  /* align-items: center; */
  grid-gap: 7px;
  background: #edf1ff;
  padding: 4px;
}

.event-strip-with-bg:not(:last-child) {
  margin-bottom: 3px;
}

.event-strip-with-bg img {
  width: 14px;
  height: 14px;
  object-fit: contain;
}

.event-strip-with-bg p {
  color: #202842;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

p.more_btn.event_details {
  color: #202842;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  margin-top: 6px;
}

.event-list-modal-list.event-strip-with-bg {
  padding: 10px;
}

.redText {
  color: red !important;
}

.greenText {
  color: #44b144 !important;
}

.redBorderTd {
  border: 1px solid red;
}

.blackBorderTd {
  border: 1px solid black;
}

.fc-daygrid-day {
  height: 100px;
}

.event-fullcalendar .fc-header-toolbar .fc-toolbar-chunk div {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 5px;
}

.event-fullcalendar .fc-header-toolbar .fc-toolbar-title {
  color: var(--font-color);
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  margin-bottom: 0;
  text-align: center;
  width: 160px;
}

/*.event-fullcalendar .fc-header-toolbar .fc-prev-button {
    background: url(../Images/Revenue-management/preMonth.svg) no-repeat;
}

.event-fullcalendar .fc-header-toolbar .fc-next-button {
    background: url(../Images/Revenue-management/nextMonth.svg) no-repeat;
}
*/
.event-fullcalendar .fc-header-toolbar .fc-prev-button,
.event-fullcalendar .fc-header-toolbar .fc-next-button {
  background: transparent !important;
  border: none !important;
  color: grey !important;
  /*font-weight: bold;*/
}

.event-fullcalendar .fc-scrollgrid {
  border: none !important;
}

.event-fullcalendar .fc-col-header {
  border-collapse: separate;
  border-spacing: 12px;
}

.event-fullcalendar .fc.fc-theme-standard .fc-view-harness th {
  background: transparent !important;
  border-color: transparent !important;
}

.event-fullcalendar .fc-col-header thead th.fc-col-header-cell {
  background: white !important;
  color: #111111;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  text-transform: uppercase;
  border: none;
  padding: 0;
  text-align: left;
}

.event-fullcalendar .fc-scrollgrid-sync-table {
  border-collapse: separate;
  border-spacing: 12px;
}

.event-fullcalendar .fc-scrollgrid-sync-table tbody td {
  position: relative;
  border-radius: 0px 0px 5px 5px;
  /* width: 164px; */
  height: 150px;
  border-top: 2px solid #b5c6ff !important;
  padding: 8px;
  border-spacing: 0;
  background-color: #fff;
}

.event-fullcalendar .fc-scrollgrid-sync-table tbody td .fc-daygrid-day-frame {
  width: 100%;
  height: 100%;
  position: relative;
}

.event-fullcalendar .fc-daygrid-day-top {
  margin-bottom: 0;
  color: var(--font-color);
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 100%;
  flex-direction: unset;
}

.event-fullcalendar
  .fc.fc-theme-standard
  .fc-view-harness
  .fc-event.fc-daygrid-block-event {
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
}

.event-fullcalendar
  .fc.fc-theme-standard
  .fc-view-harness
  .fc-popover
  .fc-popover-header
  .fc-popover-close::before {
  content: "\2573";
}

.event-fullcalendar
  .fc.fc-theme-standard
  .fc-view-harness
  .fc-popover
  .fc-popover-header {
  border: 1px solid #dee2e6;
  padding: 0.25rem;
  background: #e1e8ff;
  color: #343a40;
}

.event-fullcalendar .fc.fc-theme-standard .fc-view-harness .fc-view {
  background: transparent !important;
}

.event-fullcalendar
  table.fc-scrollgrid.fc-scrollgrid-liquid
  tr.fc-scrollgrid-section.fc-scrollgrid-section-body.fc-scrollgrid-section-liquid {
  border-width: 0 !important;
}

.event-fullcalendar
  table.fc-scrollgrid.fc-scrollgrid-liquid
  tr.fc-scrollgrid-section.fc-scrollgrid-section-body.fc-scrollgrid-section-liquid
  > td {
  border-width: 0 !important;
}

.event-fullcalendar tr.fc-scrollgrid-section.fc-scrollgrid-section-header > th {
  border-width: 0 !important;
}

.filter-icon-position {
  position: absolute;
  /* top: 20px;
  right: 75px; */
  top: -40px;
  right: 40px;
  cursor: pointer;
}

.funnelChart .table > :not(caption) > * > * {
  padding: 2px !important;
  border-bottom-width: 0 !important;
}

.responsiveFilterDiv {
  height: 200px;
  overflow-y: auto;
}

.overflow-css {
  max-height: 70vh;
}

.monthlyPaceChart .sunburst-viz .text-contour {
  stroke: none;
}

.sunburst-viz .tooltip {
  opacity: 1 !important;
}

.rateIq-calender thead th {
  background: transparent;
  color: #111111;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  text-transform: uppercase;
  border: none;
  padding: 0;
}

.rateIq-calender tbody td {
  position: relative;
  border-radius: 0px 0px 5px 5px;
  height: 105px;
  /* border-bottom: 6px solid #37d0a3; */
  padding: 5px;
  z-index: 1;
  border: 1.2px solid rgba(32, 40, 66, 0.12);
  background: #fff;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.03);
  /* border-top: 2px solid #E4E5E8; */
}

.rateIq-calender tbody td.today {
  background-color: rgb(255 250 223);
}

.rateIq-calender table {
  border-collapse: separate;
  /* border-spacing: 12px; */
  -webkit-border-horizontal-spacing: 5px;
  -webkit-border-vertical-spacing: 15px;
}

.rateIq-calender .revenueOnSameDate {
  /* font-size: 16px !important; */
  top: 39px !important;
}

.rateIq-calender .subdetails-container {
  position: absolute;
  bottom: -11px;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  color: var(--black);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  width: 100%;
  text-align: center;
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 3px;
}

.rateIq-calender .subdetails-container .subdetails {
  border-radius: 3px;
  background: rgba(53, 101, 252, 0.08);
  padding: 5px;
}

.rateIq-calender .subdetails-container .subdetails:last-child {
  border-radius: 3px;
  background: rgba(234, 43, 18, 0.08);
}

.noteFile {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  cursor: pointer;
  padding: 5px;
  margin-bottom: 20px;
  border: 1px solid #cfd9f8;
  object-fit: scale-down;
  display: inline-block;
}

.multiFile {
  height: 100px !important;
  width: 100px !important;
  position: relative;
}

.multiFileContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.fileIcon {
  height: 16px;
  width: 16px;
  cursor: pointer;
}

.fileDiv {
  display: flex;
  margin-top: 10px;
  align-items: center;
  column-gap: 5px;
  flex-wrap: wrap;
}

.closeFile {
  height: 20px !important;
  /* position: absolute;
        right: -5px;
        top: -7px; */
}

.closeIconDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #6288fd;
  position: absolute;
  right: -5px;
  top: -7px;
}

.content-inside-calendar p {
  font-size: 18px;
  color: var(--black);
  font-weight: 500;
  line-height: 100%;
  display: inline-block;
}

.content-inside-calendar {
  display: flex;
  align-items: center;
  justify-content: end;
  grid-gap: 20px;
  padding-right: 15px;
}

.react-datepicker__children-container {
  width: 100% !important;
}

.content-inside-calendar button {
  padding: 6px 15px !important;
  border-radius: 10px !important;
}

.header-dropdown-new .blue-colored-select {
  background-color: #e1e8ff;
  border-radius: 5px;
  color: var(--blue);
  /* padding: 8.5px 16px 8.5px 12px; */
  width: auto;
  border: none;
  display: flex;
  align-items: center;
}

.header-dropdown-new .blue-colored-select option {
  background: var(--white);
  color: var(--black) !important;
}

.allSettingsMain.three-all-settings-main {
  grid-template-columns: repeat(3, 1fr);
}

.widgetNoteListDiv {
  height: calc(100vh - 130px);
  overflow-y: auto;
}

.noteactionbutton {
  position: absolute;
  bottom: 10px;
}

.widget-note-add-btn-plus {
  background: #e1e8ff;
  border-radius: 5px;
  color: var(--blue);
  padding: 6.5px 16px 6.5px 12px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  line-height: 100%;
}

.widget-note-add-btn-plus img {
  margin-right: 6px;
}

.widget-note-add-btn-plus:hover {
  background: #e1e8ff;
  color: var(--blue);
  border: none;
}

.widget-note-add-btn-plus:focus,
.widget-note-add-btn-plus:focus-visible,
.widget-note-add-btn-plus:active,
.widget-note-add-btn-plus:focus-visible {
  box-shadow: none !important;
  outline: none !important;
  background: #e1e8ff;
  color: var(--blue);
}

.thirtyOutlook-widget-container {
  position: relative !important;
}

.thirtyOutlook-widget-container .thirtyOutlook-widget-container-menu {
  right: 5px;
  position: absolute;
  z-index: 99;
  display: none;
  margin-top: -25px;
  padding-bottom: 5px;
}

.thirtyOutlook-widget-container
  .thirtyOutlook-widget-container-menu
  .heading-menu-btns {
  padding: 2px !important;
}

.thirtyOutlook-widget-container:hover .thirtyOutlook-widget-container-menu {
  display: flex !important;
}

.thirtyOutlook-drop-widget-container {
  position: relative !important;
}

.thirtyOutlook-drop-widget-container .thirtyOutlook-drop-widget-container-menu {
  right: 5px;
  position: absolute;
  z-index: 99;
  display: none;
  margin-top: -25px;
  padding-bottom: 5px;
}

.thirtyOutlook-drop-widget-container
  .thirtyOutlook-drop-widget-container-menu
  .heading-menu-btns {
  padding: 2px !important;
}

.thirtyOutlook-drop-widget-container:hover
  .thirtyOutlook-drop-widget-container-menu {
  display: flex !important;
}

.sixtyOutlook-widget-container {
  position: relative !important;
}

.sixtyOutlook-widget-container .sixtyOutlook-widget-container-menu {
  right: 5px;
  position: absolute;
  z-index: 99;
  display: none;
  margin-top: -25px;
  padding-bottom: 5px;
}

.sixtyOutlook-widget-container
  .sixtyOutlook-widget-container-menu
  .heading-menu-btns {
  padding: 2px !important;
}

.sixtyOutlook-widget-container:hover .sixtyOutlook-widget-container-menu {
  display: flex !important;
}

.sixtyOutlook-drop-widget-container {
  position: relative !important;
}

.sixtyOutlook-drop-widget-container .sixtyOutlook-drop-widget-container-menu {
  right: 5px;
  position: absolute;
  z-index: 99;
  display: none;
  margin-top: -25px;
  padding-bottom: 5px;
}

.thirtyOutlook-drop-widget-container
  .sixtyOutlook-drop-widget-container-menu
  .heading-menu-btns {
  padding: 2px !important;
}

.sixtyOutlook-drop-widget-container:hover
  .sixtyOutlook-drop-widget-container-menu {
  display: flex !important;
}

.ninentyOutlook-widget-container {
  position: relative !important;
}

.ninentyOutlook-widget-container .ninentyOutlook-widget-container-menu {
  right: 5px;
  position: absolute;
  z-index: 99;
  display: none;
  margin-top: -25px;
  padding-bottom: 5px;
}

.ninentyOutlook-widget-container
  .ninentyOutlook-widget-container-menu
  .heading-menu-btns {
  padding: 2px !important;
}

.ninentyOutlook-widget-container:hover .ninentyOutlook-widget-container-menu {
  display: flex !important;
}

.ninentyOutlook-drop-widget-container {
  position: relative !important;
}

.ninentyOutlook-drop-widget-container
  .ninentyOutlook-drop-widget-container-menu {
  right: 5px;
  position: absolute;
  z-index: 99;
  display: none;
  margin-top: -25px;
  padding-bottom: 5px;
}

.ninentyOutlook-drop-widget-container
  .ninentyOutlook-drop-widget-container-menu
  .heading-menu-btns {
  padding: 2px !important;
}

.ninentyOutlook-drop-widget-container:hover
  .ninentyOutlook-drop-widget-container-menu {
  display: flex !important;
}

/* .header-asof-date {
  width: 120px !important;
} */

.btn-download-all {
  position: absolute;
  right: 10px;
  top: 5px;
  padding: 3px !important;
}

.btn-email-all {
  position: absolute;
  right: 40px;
  top: 5px;
  padding: 3px !important;
}

.btn-archive-all {
  position: absolute;
  right: 70px;
  top: 5px;
  padding: 3px !important;
}

.singleTodo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.todoDetail {
  font-size: 14px;
  margin: 0;
  line-height: 120%;
}

.rateIq-calender .disableDate .subdetails-container .subdetails {
  background: #a4a4a414;
  color: #a4a4a4 !important;
}

.disableDate .main-date-box-inner img {
  filter: grayscale(1);
}

.calendar-lightgreenbg {
  /* class use for light grren bgcolor */
  background-color: #e0f2e9;
}

.calenderleft-border {
  border-left: 1px solid #20284280;
}

.gobtn {
  display: flex;
  align-items: end;
}

.gobtn .gobtnwidth {
  padding: 11px 37px;
}

.time-picker-with-clock .react-datepicker__input-container::after {
  background: url("../Images/onlyClock.svg") no-repeat !important;
  background-size: contain !important;
}

/* .crmDashboardMain-bot-section
  .meghaDatePicker
  .react-datepicker__month-container {
  width: 100% !important;
}

.crmDashboardMain-bot-section .react-datepicker__month-container {
  float: unset;
} */

.crmDashboardMain-bot-section .allSettingsMain {
  margin-bottom: 20px;
}

.crmDashboardMain-bot-section {
  border-radius: 10px;
  border: 1px solid rgba(53, 101, 252, 0.2);
  padding: 20px;
  background: #fafbff;
  margin-top: 20px;
}

button.default-btn.secondary-btn.white-button.btn.btn-primary {
  background: white;
  border: none;
}

.insightNotesMain-inner-wrapper .accordion-header,
.insightNotesMain-inner-wrapper .accordion-header button {
  width: 50px;
  height: 50px;
  border-radius: 50% !important;
  background: var(--light-bg);
  justify-content: center;
}

.insightNotesMain-inner-wrapper .accordion-button::after {
  display: none;
}

.insightNotesMain-inner-wrapper .accordion-button {
  font-weight: 600;
  font-size: 14px;
}

.insights-notes-single-notes-inner {
  display: flex;
  align-items: flex-start;
  grid-gap: 10px;
}

.insights-notes-single-notes:not(:last-child) {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid var(--light);
}

.insightNotesMain-inner-wrapper .accordion-item:not(:last-child) {
  margin-bottom: 15px;
}

.insightNotesMain-inner-wrapper .accordion-body {
  padding: 10px 10px 10px 10px;
  margin-left: 25px;
  margin-top: 10px;
  position: relative;
  border-left: 1px solid var(--light-bg);
}

/* .insightNotesMain-inner-wrapper .accordion-body::after{
  content: "";
  background: var(--blue);
  width: 1px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
} */

.offcanvas-body::-webkit-scrollbar {
  display: none;
}

.insightOffcanvasWrapper.offcanvas.offcanvas-end.show
  .offcanvas-body
  ul.nav.nav-underline {
  background: white;
  position: sticky;
  top: 0;
  z-index: 3;
  padding-top: 10px;
}

.insightOffcanvasWrapper.offcanvas.offcanvas-end.show .offcanvas-body {
  padding-top: 0;
}

.insightNotesInnerEmaDate p {
  font-size: 12px;
}

.rateiq-img-visible {
  display: block !important;
}

.rateiq-img-hide {
  visibility: hidden !important;
}

.ViewPropertySetupTable table .variance-font-size {
  font-size: 9px !important;
}

.rateIq-weekend td {
  background-color: #f0f8ff !important;
}

.insightStickyDatePicker {
  background: white;
  position: sticky;
  top: 43px;
  z-index: 3;
}

.insightOffcanvasWrapper .accordion-item {
  border: none !important;
}

.eDailyData-widget-container {
  position: relative !important;
}

.eDailyData-widget-container .eDailyData-widget-container-menu {
  right: 5px;
  position: absolute;
  z-index: 99;
  display: none;
  margin-top: -25px;
  padding-bottom: 5px;
}

.eDailyData-widget-container
  .eDailyData-widget-container-menu
  .heading-menu-btns {
  padding: 2px !important;
}

.eDailyData-widget-container:hover .eDailyData-widget-container-menu {
  display: flex !important;
}

.eMonthlyData-widget-container {
  position: relative !important;
}

.eMonthlyData-widget-container .eMonthlyData-widget-container-menu {
  right: 5px;
  position: absolute;
  z-index: 99;
  display: none;
  margin-top: -25px;
  padding-bottom: 5px;
}

.eMonthlyData-widget-container
  .eMonthlyData-widget-container-menu
  .heading-menu-btns {
  padding: 2px !important;
}

.eMonthlyData-widget-container:hover .eMonthlyData-widget-container-menu {
  display: flex !important;
}

.ebookingPace-widget-container {
  position: relative !important;
}

.ebookingPace-widget-container .ebookingPace-widget-container-menu {
  right: 5px;
  position: absolute;
  z-index: 99;
  display: none;
  margin-top: -25px;
  padding-bottom: 5px;
}

.ebookingPace-widget-container
  .ebookingPace-widget-container-menu
  .heading-menu-btns {
  padding: 2px !important;
}

.ebookingPace-widget-container:hover .ebookingPace-widget-container-menu {
  display: flex !important;
}

.ereservation-widget-container {
  position: relative !important;
}

.ereservation-widget-container .ereservation-widget-container-menu {
  right: 5px;
  position: absolute;
  z-index: 99;
  display: none;
  margin-top: -25px;
  padding-bottom: 5px;
}

.ereservation-widget-container
  .ereservation-widget-container-menu
  .heading-menu-btns {
  padding: 2px !important;
}

.ereservation-widget-container:hover .ereservation-widget-container-menu {
  display: flex !important;
}

.weeklydaily-widget-container {
  position: relative !important;
}

.weeklydaily-widget-container .weeklydaily-widget-container-menu {
  right: 5px;
  position: absolute;
  z-index: 99;
  display: none;
  margin-top: -25px;
  padding-bottom: 5px;
}

.weeklydaily-widget-container
  .weeklydaily-widget-container-menu
  .heading-menu-btns {
  padding: 2px !important;
}

.weeklydaily-widget-container:hover .weeklydaily-widget-container-menu {
  display: flex !important;
}

.weeklygroup-widget-container {
  position: relative !important;
}

.weeklygroup-widget-container .weeklygroup-widget-container-menu {
  right: 5px;
  position: absolute;
  z-index: 99;
  display: none;
  margin-top: -25px;
  padding-bottom: 5px;
}

.weeklygroup-widget-container
  .weeklygroup-widget-container-menu
  .heading-menu-btns {
  padding: 2px !important;
}

.weeklygroup-widget-container:hover .weeklygroup-widget-container-menu {
  display: flex !important;
}

.weeklyreservation-widget-container {
  position: relative !important;
}

.weeklyreservation-widget-container .weeklyreservation-widget-container-menu {
  right: 5px;
  position: absolute;
  z-index: 99;
  display: none;
  margin-top: -25px;
  padding-bottom: 5px;
}

.weeklyreservation-widget-container
  .weeklyreservation-widget-container-menu
  .heading-menu-btns {
  padding: 2px !important;
}

.weeklyreservation-widget-container:hover
  .weeklyreservation-widget-container-menu {
  display: flex !important;
}

.weeklydrilldown-widget-container {
  position: relative !important;
}

.weeklydrilldown-widget-container .weeklydrilldown-widget-container-menu {
  right: 5px;
  position: absolute;
  z-index: 99;
  display: none;
  margin-top: -25px;
  padding-bottom: 5px;
}

.weeklydrilldown-widget-container
  .weeklydrilldown-widget-container-menu
  .heading-menu-btns {
  padding: 2px !important;
}

.weeklydrilldown-widget-container:hover .weeklydrilldown-widget-container-menu {
  display: flex !important;
}

.rateIqTr td {
  background-color: #e0f2e9 !important;
}

.crmwestModernLocationActivity .westModernLocationActivitySingle {
  border-radius: 5px;
  background: #f9f9f9;
  padding: 12px 12px 12px 12px;
  max-width: 688px;
  margin-left: auto;
  position: relative;
}

.CRMAccountRulesMainAccordionMain .westModernLocationActivitySingle {
  max-width: 92% !important;
}

.CRMAccountRulesMainAccordionMain.westModernLocationActivitiesWrapper-wrapper
  .westModernLocationActivitiesWrapper:not(:first-child)
  .westModernLocationActivitySingle-time-line-date-inner::after {
  left: 35px;
}

.accordion-body .crmwestModernLocationActivity {
  border-radius: 0 0 10px 10px;
}

.crmwestModernLocationActivity-title {
  padding: 12px 16px;
  border-radius: 9px 9px 0px 0px;
  background: #d5dae9;
}

.crmwestModernLocationActivity-title h5 {
  margin: 0;
  color: var(--dark);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.crmwestModernLocationActivity {
  padding: 0 0 1px 0;
  border-radius: 10px;
  border: 1px solid rgba(53, 101, 252, 0.2);
}

.westModernLocationActivitySingle-time-line-date {
  border-radius: 5px;
  background: rgba(53, 101, 252, 0.12);
  padding: 7px 12px;
  color: var(--blue);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: inline-block;
}

.crmwestModernLocationActivity .westModernLocationActivitiesWrapper {
  padding: 20px 12px;
}

.crmwestModernLocationActivity .westModernLocationActivitySingle::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -5px;
  background: url("../Images/CRM/activitydot.svg") no-repeat;
  background-size: contain;
  width: 10px;
  height: 10px;
}

.crmwestModernLocationActivity
  .westModernLocationActivitySingle:first-child:after {
  content: "";
  position: absolute;
  bottom: 45%;
  right: 100%;
  background: url("../Images/CRM/activitybefore.svg") no-repeat;
  background-size: contain;
  width: 54px;
  height: 70px;
}

.crmwestModernLocationActivity
  .westModernLocationActivitySingle:not(:first-child):after {
  content: "";
  position: absolute;
  bottom: 45%;
  right: 100%;
  background: url("../Images/CRM/activitybefore.svg") no-repeat;
  background-size: cover;
  width: 55px;
  height: 150px;
}

.westModernLocationActivitiesWrapper-wrapper
  .westModernLocationActivitiesWrapper:not(:first-child)
  .westModernLocationActivitySingle-time-line-date-inner {
  position: relative;
}

.westModernLocationActivitiesWrapper-wrapper
  .westModernLocationActivitiesWrapper:not(:first-child)
  .westModernLocationActivitySingle-time-line-date-inner::after {
  content: "";
  position: absolute;
  bottom: 112%;
  left: 20px;
  background: url("../Images/CRM/activityafter.svg") no-repeat;
  background-size: cover;
  width: 4px;
  height: 107px;
}

.crmDashboardMain
  button.default-btn.secondary-btn.white-button.btn.btn-primary:hover {
  background: var(--blue);
}

.crmDashboardMain
  .westModernLocationActivitiesWrapper-wrapper
  .westModernLocationActivitiesWrapper:not(:first-child)
  .westModernLocationActivitySingle-time-line-date-inner::after {
  left: 9px;
}

.westModernLocationActivitiesWrapper-wrapper {
  max-height: 560px;
  overflow-y: auto;
}

.westModernLocationActivitiesWrapper-wrapper::-webkit-scrollbar {
  display: none;
}

.blue-text {
  color: var(--blue) !important;
}

span.blue-text {
  cursor: pointer;
}

/* .when-grid-is-collepsed p{
  transform: rotate(-90deg);
} */
.when-grid-is-collepsed {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 40px;
  /* margin-left: 10px; */
  border-radius: 5px;
  border: 1px solid #00caf6;
  background: rgba(0, 202, 246, 0.08);
  transition: all 0.4s;
}

.when-grid-is-collepsed-title {
  height: 30px;
}

.when-grid-is-collepsed-content {
  display: flex;
  align-items: center;
  width: max-content;
  grid-gap: 10px;
  transform: rotate(-90deg);
  transition: all 0.4s;
}

.when-grid-is-collepsed {
  display: none;
  transition: all 0.4s;
}

.collapsemaingrid .single-grid-single-grid {
  display: none;
  width: 0;
  min-width: 0px;
  /* transition: all 0.4s; */
}

.gridViewSingleMain.d-flex.collapsemaingrid {
  min-width: 40px;
  width: 40px;
  transition: all 0.4s;
}

.collapsemaingrid .when-grid-is-collepsed {
  display: flex;
  transition: all 0.4s;
}

.collapsemaingrid .when-grid-is-collepsed p,
.singleGridViewTitle-bottom p {
  color: var(--dark);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.collapsemaingrid .when-grid-is-collepsed p span,
.singleGridViewTitle-bottom p span {
  font-weight: 700;
}

.when-grid-is-collepsed-content p:last-child {
  padding-left: 10px;
  border-left: 1px solid var(--dark);
}

.singleGridViewTitle-bottom {
  border-radius: 0px 0px 5px 5px;
  background: rgba(223, 32, 32, 0.08);
  padding: 10px;
  margin-bottom: 10px;
}

@keyframes slideaway {
  from {
    display: none;
  }

  to {
    transform: translateX(-150);
    display: flex;
  }
}

.CRMHotelInfoMain-content-inner {
  border-radius: 0 0 5px 5px;
  background: #fafbff;
  padding: 12px;
  height: 78vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.CRMHotelInfoMain-wrapper {
  border-radius: 5px;
  border: 1px solid rgba(53, 101, 252, 0.2);
}

.fw-500 {
  font-weight: 500 !important;
}

.CRMHotelInfoMain-content-inner span {
  color: var(--dark);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.CRMHotelInfoMain-content-inner p {
  color: var(--dark);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.rate-information-table-main table tbody tr:last-child {
  border-bottom: 1px solid #dee2e6;
}

.rate-information-table-main table * {
  text-align: center;
}

.upload-information-main-wrapper h3 {
  color: var(--dark);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.upload-information-main-wrapper {
  border-radius: 0 0 5px 5px;
  padding: 20px 12px 12px 12px;
  border: 1px solid rgba(53, 101, 252, 0.2);
  margin: 0;
}

.accoedion-header-middle-section {
  margin-left: auto;
}

.accoedion-header-middle-section p {
  color: var(--blue);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.accoedion-header-middle-section span {
  color: rgba(32, 40, 66, 0.8);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.accoedion-header-middle-section-inner {
  background: var(--white);
  padding: 6px 10px;
}

.crmaccount-info-wrapper-cover.CRMAccountRulesMain .accordion-header button {
  height: 42px;
}

/* .crmactivities-settings-wrapper {
  border-radius: 10px;
  border: 1px solid rgba(53, 101, 252, 0.2);
  background: #fafbff;
  padding: 24px;
} */

.crm-act-accordion-main .accordion-item {
  border: 1px solid var(--light) !important;
  border-radius: 5px;
}

.custom-height-datepicker input {
  height: 34px !important;
}

.react-datepicker__month-container {
  float: unset;
}

input.small-font {
  font-size: 11px;
  padding: 3px !important;
}

table tfoot tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

table tfoot tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

.bold-tfoot-text td {
  font-weight: 600;
  text-transform: uppercase;
}

.email-schedule-main {
  position: relative;
}

.email-schedule-icon {
  position: absolute;
  right: 20px;
  top: 14px;
  z-index: 999;
  cursor: pointer;
}

.email-schedule-icon img {
  width: 16px;
  height: 16px;
  object-fit: contain;
}

.email-schedule-icon img:hover {
  filter: brightness(1);
}

img.report-filter {
  width: 14px;
  height: 14px;
  cursor: pointer;
}

.small-height-select .css-b62m3t-container,
.small-height-select .css-13cymwt-control,
.small-height-select .css-t3ipsp-control {
  height: 30px;
  max-height: 30px !important;
  min-height: unset !important;
}

.small-height-select .css-13cymwt-control,
.small-height-select .css-t3ipsp-control {
  border: 1px solid #cfd9f8 !important;
}

.small-height-select .css-b62m3t-container input {
  font-size: 11px !important;
  line-height: 100% !important;
  color: var(--dark) !important;
  max-height: 22px;
}

.small-height-select .css-1p3m7a8-multiValue {
  margin: 6px;
}

.small-height-select .css-wsp0cs-MultiValueGeneric {
  padding: 0 0 0 6px !important;
}

.small-height-select .css-1hb7zxy-IndicatorsContainer {
  max-height: 30px;
}

.small-height-select .css-qbdosj-Input {
  padding: 0 !important;
  margin: 0 !important;
  max-height: 30px !important;
}

.small-height-select .css-3w2yfm-ValueContainer {
  padding: 2px 2px;
  box-sizing: border-box;
  top: -4px;
}

.insightStickyDatePicker .react-datepicker-wrapper {
  margin-top: 1rem;
}

.overflow-css::-webkit-scrollbar {
  display: none;
}

.revpak-icon img {
  width: 16px;
  height: 16px;
}

.todo-detail-modal-main-wrapper .widget-container {
  margin-top: 20px;
}

.crmaccount-info-wrapper-cover .accordion-body table {
  border-width: 0;
  border-radius: 0 0 10px 10px;
}

.crmaccount-info-wrapper-cover .accordion-item {
  border: 1px solid var(--light) !important;
  border-radius: 10px;
}

/* .crmaccount-info-wrapper-cover .accordion-item .table-header {
  display: none;
} */
.crmaccount-info-wrapper-cover .accordion-header button::after {
  flex-shrink: 0;
  width: 11px !important;
  height: 7px !important;
  content: "";
  right: 0 !important;
  top: 0 !important;
  background: transparent url(../Images/Configuration/accordionArrow.svg)
    no-repeat !important;
  transition: var(--bs-accordion-btn-icon-transition);
}

.different-tab-content-height .tab-content {
  max-height: calc(100vh - 350px);
  overflow: auto;
  scrollbar-width: none;
}

.different-tab-content-height .tab-content::-webkit-scrollbar {
  display: none !important;
}

.table .form-control {
  font-size: 12px;
}

.image-on-accordion-header {
  position: absolute;
  top: 10px;
  left: 10%;
  z-index: 9999;
  cursor: pointer;
}

.footer-buttons {
  position: sticky;
  bottom: 0;
  left: 0;
  background: var(--white);
  padding-top: 10px;
}

/* .modal-body.small-height-modal-body{
  max-height: 600px !important;
  overflow: auto;
  padding-bottom: 0;
} */
.heading-menu-btns.no-padding-btn {
  padding: 0 !important;
  background: none !important;
}

.accordion-item .no-padding-btn.heading-menu-btns {
  position: absolute;
  top: 10px;
  right: 40px;
  z-index: 9999;
}

.tbl-header-sticky {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9 !important;
}

.maintbl-widget-container {
  position: relative !important;
}

.maintbl-widget-container .maintbl-widget-container-menu {
  right: 5px;
  position: absolute;
  z-index: 99;
  display: none;
  margin-top: -25px;
  padding-bottom: 5px;
}

.maintbl-widget-container .maintbl-widget-container-menu .heading-menu-btns {
  padding: 2px !important;
}

.maintbl-widget-container:hover .maintbl-widget-container-menu {
  display: flex !important;
}

.weekwisetbl-widget-container {
  position: relative !important;
}

.weekwisetbl-widget-container .weekwisetbl-widget-container-menu {
  right: 5px;
  position: absolute;
  z-index: 99;
  display: none;
  margin-top: -25px;
  padding-bottom: 5px;
}

.weekwisetbl-widget-container
  .weekwisetbl-widget-container-menu
  .heading-menu-btns {
  padding: 2px !important;
}

.weekwisetbl-widget-container:hover .weekwisetbl-widget-container-menu {
  display: flex !important;
}

td.month-drilldown-color {
  background: #e1e8ff !important;
}

.marign-condition-btn {
  margin-top: 20px;
}

/* .conditional-class:has(.text-danger) .marign-condition-btn {
  margin-top: 0;
} */
.make-comment-in-widget {
  margin-top: 4px;
}

.make-comment-in-widget .accordion-header {
  border-radius: 6px !important;
  border: 1px solid #ffe875 !important;
  background: #ffef9e !important;
}

.make-comment-in-widget .accordion-header button {
  padding: 7px !important;
  background: #ffef9e !important;
  border-radius: 6px !important;
  color: var(--font-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.make-comment-in-widget .accordion-button::after {
  background-image: url("../Images/Revenue-management/boldaccordionIcon.svg") !important;
  width: 20px;
  height: 20px;
  transform: rotate(180deg);
}

.make-comment-in-widget .accordion-button:not(.collapsed)::after {
  transform: rotate(0deg);
}

.make-comment-in-widget .accordion-body {
  border-radius: 0 0 6px 6px;
  border: 1px solid #ffe875;
  background: #fffbd0;
  padding: 5px !important;
}

.single-comment-in-widget-inner {
  display: flex;
  align-items: flex-start;
  grid-gap: 6px;
}

.single-comment-in-widget-inner p {
  color: var(--font-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 4px;
}

.single-comment-in-widget-inner img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
}

.single-comment-in-widget-inner span {
  color: var(--white);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 4px 6px;
  border-radius: 0px 6px 6px 0px;
  background: #615800;
}

.single-comment-in-widget:not(:last-child) {
  margin-bottom: 10px;
}

.make-comment-in-widget
  .accordion-item:has(.accordion-collapse.collapse.show)
  .accordion-header {
  border-radius: 6px 6px 0 0 !important;
}

.send-comment-btn img {
  width: 18px;
  height: 18px;
  object-fit: cover;
}

.send-comment-btn {
  padding: 6px;
  border-radius: 5px;
  background: #615800;
  cursor: pointer;
  max-width: 30px;
  max-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.enter-comment-here input,
.enter-comment-here textarea {
  color: var(--font-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 7px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #615800;
  background: #fff;
  max-height: 30px;
}

.enter-comment-here textarea::-webkit-scrollbar {
  display: none;
}

.enter-comment-here {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  margin-top: 12px;
}

.RevPakArchive-main .singleColumnTableBody {
  height: calc(100vh - 200px);
}

.RevPakArchive-main .nav-item a {
  padding: 10px 20px;
  max-height: 39px;
}

.RevPakArchive-main-inner-wrapper {
  /* position: relative; */
}

.all-my-content-here {
  margin-bottom: 15px;
}

.archive-content-right {
  /* position: absolute;
  left: 268px;
  top: 40px; */
  border-radius: 9px;
  border: 1px solid #fcea3d;
  background: #fef49c;
  padding: 15px 12px 5px;
  height: calc(100vh - 220px);
}

.black-small-badge {
  color: var(--white);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 50px;
  background: var(--black);
  padding: 4px 8px;
  height: fit-content;
}

.line-breaker {
  color: rgba(32, 40, 66, 0.8);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-column-gap: 18px;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

.line-breaker::before,
.line-breaker::after {
  content: "";
  display: block;
  height: 1px;
  background-color: #c9c386;
}

.all-comments::-webkit-scrollbar {
  display: none;
}

.all-comments {
  height: calc(100vh - 275px);
  overflow: auto;
}

.archive-content-right .enter-comment-here {
  position: sticky;
  bottom: 10px;
  /* left: 20px; */
}

.revenue-management-page-body:has(.RevPakArchive-main) {
  overflow: hidden;
}

.RevPakArchive-main .accordion-item:not(:last-child) {
  border-bottom: 1px solid rgba(32, 40, 66, 0.1) !important;
}

.RevPakArchive-main .accordion-item .accordion-body {
  padding: 10px 0 0 0;
}

.RevPakArchive-main .accordion-item .accordion-header button {
  padding: 10px 0 10px 0 !important;
}

.all-date-list-archive li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
}

.all-date-list-archive li img {
  cursor: pointer;
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.crm-dashboard-bot-top {
  margin-bottom: 12px;
}

.col-with-diff-padding-left {
  padding-left: 10px;
}

.col-with-diff-padding-right {
  padding-right: 10px;
}

.custom-sized-chart canvas {
  margin: auto auto;
}

.custom-sized-chart {
  height: 345px !important;
  width: 100% !important;
}

.crmdashboard-calendar .RMRateShopMain,
.crmdashboard-calendar .RMRateShopMain,
.crmdashboard-calendar .fullcalendar-container,
.crmdashboard-calendar .fc-direction-ltr {
  height: 100%;
}

.crmdashboard-calendar {
  border-radius: 10px;
  border: 1px solid rgba(53, 101, 252, 0.2);
  padding: 20px 10px 10px 10px;
  height: 100%;
}

.crmdashboard-calendar .event-fullcalendar .fc-scrollgrid-sync-table tbody td {
  width: auto;
  height: 127px;
}

.crmdashboard-calendar *::-webkit-scrollbar {
  display: none;
}

.crmdashboard-calendar .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
  margin-bottom: 10px;
}

.lead-detail-my-main .westModernLocationTabsTabs {
  min-height: calc(100vh - 220px);
  max-height: calc(100vh - 220px);
}

.lead-detail-my-main .nav-underline {
  grid-column-gap: unset !important;
}

.rfpMain .ProspectDashboardTitleWraper {
  margin-bottom: 20px;
}

.mtb {
  margin-top: 20px;
  margin-bottom: 20px;
}

.displacement-calc-table .ProspectDashboardTitleWraper {
  margin-top: 30px;
  margin-bottom: 15px;
}

.fc-prev-button.fc-button.fc-button-primary {
  background-image: url("../Images/Revenue-management/preMonth.svg") !important;
  background-repeat: no-repeat !important;
  background-size: 15px 15px !important;
}

.fc-next-button.fc-button.fc-button-primary {
  background-image: url("../Images/Revenue-management/nextMonth.svg") !important;
  background-repeat: no-repeat !important;
  background-size: 15px 15px !important;
}

.fc-dayGridMonth-view.fc-view.fc-daygrid *::-webkit-scrollbar {
  display: none !important;
}

.crm-fullCustomCalendarMainWrapper .month-name-with-arrow {
  margin: 0;
}

.crm-fullCustomCalendarMainWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0;
}

.fullCustomCalendarMain-and-all-hotels-main {
  margin-top: 20px;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 20px;
}

.enter-comment-here.position-relative .enter-comment-suggestions {
  position: absolute;
  bottom: 100%;
  left: 0;
  border: 1.2px solid rgba(32, 40, 66, 0.12);
  background: var(--white);
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.03);
  border-radius: 10px 10px 0 0;
  padding: 5px 0;
  z-index: 9999;
  min-width: 300px;
  max-width: 300px;
  max-height: 160px;
  overflow: auto;
}

.enter-comment-here.position-relative
  .enter-comment-suggestions::-webkit-scrollbar {
  display: none;
}

.enter-comment-here.position-relative .enter-comment-suggestions ul {
  list-style-type: none;
}

.enter-comment-here.position-relative .enter-comment-suggestions ul li {
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 5px 10px;
}

.enter-comment-here.position-relative .enter-comment-suggestions ul li:hover {
  color: var(--blue);
  background: var(--light);
}

.all-selected-files-single:not(:last-child) {
  margin-bottom: 5px;
}

.flex-items-start {
  display: flex;
  align-items: start;
  flex-direction: column;
}

.background-color-theme {
  width: 8px;
  height: 8px;
  background: var(--blue);
  position: absolute;
  top: 2px;
  right: 0;
  border-radius: 50%;
}

.ConfigpropertiesContent img {
  width: 18px;
  height: 18px;
  object-fit: contain;
}

.footer-buttons .btn:disabled {
  background-color: var(--bs-btn-disabled-bg) !important;
}

.red-btn {
  background-color: var(--red) !important;
  border: 1px solid var(--red) !important;
  color: var(--white);
}

/* .NotificationConfig-main table td p {
  color: var(--blue);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 4px;
} */
.NotificationConfig-main table td span {
  color: #202842;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.NotificationConfig-main table td .form-select {
  border: 1px solid #cfd9f8 !important;
  height: 30px;
}

.NotificationConfig-main table td input {
  max-width: 40px;
}

.search-group-for-table.account-search-group {
  border-radius: 5px;
}

.search-group-for-table.account-search-group input {
  border: none !important;
}

hr.dropdown-divider {
  margin: 0;
}

.header-right .dropdown-menu.show {
  padding: 0px 0;
}

.header-right .dropdown-item {
  font-size: 14px;
  padding: 8px 10px;
}

.header-right .dropdown-item:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.175);
}

.header-right .dropdown-item:first-child {
  border-radius: 5px 5px 0 0;
}

.header-right .dropdown-item:last-child {
  border-radius: 0 0 5px 5px;
}

/* .form-check-input:disabled {
  opacity: 0.8 !important;
} */
.my-employ-detail {
  margin-top: 26px;
}

.marginBottom20 {
  margin-bottom: 20px !important;
}

.displacementCalcWrapper .revpak-icon img {
  filter: invert(1);
}

.displacementCalcWrapper button:hover .revpak-icon img {
  filter: invert(0);
}

.all-reports-main .displacementCalcWrapper {
  margin-left: auto;
  position: absolute;
  right: 30px;
}

.all-reports-main .displacementCalcWrapper::after {
  content: "";
  position: absolute;
  bottom: 100%;
  right: 10px;
  background: url("../../Assets/Images/triangle.svg") no-repeat;
  background-size: contain;
  width: 11px;
  height: 10px;
}

.all-reports-main .small-height-select .css-b62m3t-container,
.all-reports-main .small-height-select .css-13cymwt-control,
.all-reports-main .small-height-select .css-t3ipsp-control {
  min-height: fit-content !important;
  height: fit-content !important;
  max-height: fit-content !important;
}

.all-reports-main .small-height-select .css-3w2yfm-ValueContainer {
  top: 0;
}

.all-reports-main .small-height-select .css-1p3m7a8-multiValue {
  margin: 2px;
}

.all-reports-main .from-groups .form-select,
.all-reports-main .no-border-break input {
  height: 32px;
}

.all-reports-main input#endDate {
  border-radius: 5px;
}

.ViewPropertySetupTable table .react-datepicker__tab-loop * {
  line-height: 170% !important;
}

.whitespace-wrap {
  white-space: nowrap;
}

.allTableTogatherWrapper
  .stripped__table
  table
  tbody
  tr:last-child
  td:last-child {
  border-bottom-right-radius: 10px !important;
}

.allTableTogatherWrapper
  .stripped__table
  table
  tbody
  tr:last-child
  td:first-child {
  border-bottom-left-radius: 10px !important;
}

.allTableTogatherWrapper
  .stripped__table
  table
  thead
  tr:first-child
  th:last-child {
  border-top-right-radius: 10px !important;
}

.allTableTogatherWrapper
  .stripped__table
  table
  thead
  tr:first-child
  th:first-child {
  border-top-left-radius: 10px !important;
}

.react-datepicker__tab-loop,
.react-datepicker__tab-loop * {
  z-index: 99999 !important;
}

.react-datepicker__tab-loop button.react-datepicker__navigation,
.react-datepicker__tab-loop button.react-datepicker__navigation * {
  z-index: 999999 !important;
}

.allTableTogatherWrapper .monthSelectTable th .form-select {
  padding-left: 10px !important;
}

.dynamic-widget-display .ThreeBarAndLineChartWrapper {
  margin-top: 0;
}

.backgroundTr td.stickycolumn {
  background-color: #f0f8ff !important;
  z-index: 1;
}

.childTrColor td.stickycolumn {
  background-color: #a7d6ff !important;
  z-index: 1;
}

.userManagementModalMain-bottom .css-12a83d4-MultiValueRemove {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.userManagementModalMain-bottom .css-v7duua {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.css-1nmdiq5-menu {
  z-index: 9999 !important;
  position: absolute;
}

.copySnapshotTable .table-responsive {
  min-height: 350px !important;
}

.copySnapshotTable .table-responsive table {
  min-height: 100%;
}

.table-info {
  display: flex;
}

.dataTables_length {
  margin-left: 5px !important;
  margin-top: -5px;
}

.dataTables_length select {
  background: #fff;
  border: 1px solid var(--light);
}

.link_color_email_phone {
  color: #3565fc !important;
}

.fullCustomCalendarTable tbody td.my-custom-width-td {
  width: 164px !important;
}

.accordionCustomClearAllBtn {
  position: relative;
}

.accordionCustomClearAllBtn .customClearAllBtn {
  position: absolute;
  right: 25px;
  top: 18px;
  z-index: 9999;
}

.accordion.customPaddingaccordionBody .accordion-body {
  padding: 10px 20px 10px 10px !important;
}

.special-btn-close-modal {
  position: relative !important;
}

.special-btn-close-modal .special-btn-close {
  position: absolute !important;
  top: 12px !important;
  right: 12px !important;
  cursor: pointer;
}

.Toastify__toast-container {
  z-index: 99999999 !important;
}

.headerEditIcon {
  border-radius: 5px;
  border: 1px solid rgba(53, 101, 252, 0.2);
  padding: 2px;
}

.customHeaderIcon {
  min-height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--blue);
  border: 1px solid var(--blue);
  color: var(--white) !important;
  padding: 10px;
}

.modal-body:has(.tab-content) {
  overflow: hidden;
  max-height: 100%;
}

.remove_arrow {
  /* display: none; */
  --bs-form-select-bg-img: none;
}

.crmDashboardMain-bot-section .custom-sized-chart {
  width: fit-content !important;
  margin: auto !important;
}

.tbl-forcast-dynamic-pickup {
  max-height: 515px;
  overflow-y: auto;
}

.dynamicPickupDropdown {
  display: flex;
  align-items: center;
  gap: 50px;
}

.rateShopSelfTag {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background: #43c4dd !important;
  font-size: 13px !important;
  font-weight: bolder !important;
  border-color: #43c4dd !important;
  width: 50px !important;
  border-radius: 5px !important;
}

.invalidInput > input {
  border: 1px solid red !important;
  border-color: red !important;
}

.from-groups.no-border-break.invalidInput select {
  border: 1px solid red !important;
  border-color: red !important;
}

.email-detail-bold-font {
  font-weight: 600 !important;
}

.overflow_upload_file {
  overflow: auto;
}

.zoomModalImage {
  height: 100px !important;
  width: 100px !important;
}

.todo-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
}

/* 23-04-2024 */
.zoombtn {
  display: flex;
  align-items: end;
}

.tblLoding {
  display: none !important;
  width: 100% !important;
}

.tblShowing {
  display: block !important;
  width: 100% !important;
}

.react-loading-skeleton {
  margin-bottom: 5px;
  padding: 2px;
}

.rateiq-calendar-popup {
  opacity: 0.4 !important;
  background-color: "#7F7F7F" !important;
}

.bodyLoader {
  position: relative;
  overflow: hidden !important;
}

.bodyLoader::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: #00000098;
  height: 100%;
  width: 100%;
  /* overflow: hidden; */
  z-index: 9999;
}

.bodyLoader::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url("../Images/Revenue-management/loading1.gif");
  background-size: contain;
  background-repeat: no-repeat;
  filter: brightness(12);
  height: 300px;
  width: 300px;
  z-index: 9999999;
}

.percentage-data {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.percentage-data p {
  border: none;
}

.benchMarkingCompetitorRow {
  display: none !important;
}

.compRow {
  display: none !important;
}

.benchMarkingDatePicker
  .react-datepicker-wrapper
  .react-datepicker__input-container
  input {
  font-size: 14px;
}

.compTr td {
  background-color: rgb(168, 168, 168, 0.2) !important;
}

.my-special-header {
  background: #eaefff;
  padding: 12px 20px;
  color: var(--font-color);
  font-size: 18px;
  font-weight: 600;
  line-height: 17px;
}

.my-special-horizontal-menu-option {
  min-width: 100%;
  width: 100% !important;
}

.allReminder-main-inner-main-internal:not(:last-child) {
  margin-bottom: 10px;
}

.allReminder-main-inner-main p {
  color: var(--font-color);
  font-size: 16px;
  font-weight: 400;
  line-height: 100%;
}

.allReminder-main-inner-main {
  padding: 10px;
}

.heatMap-activeTab {
  color: var(--blue);
}

.heatMapBold {
  font-weight: bolder;
}

.tableWidthForScroll {
  height: 100vh !important;
}

.GroupNameModalMain.ToolsSingleTicketPopUpMain.singleDateSummaryModalMain.no-box-shadow {
  box-shadow: none !important;
  height: auto !important;
  max-height: 100% !important;
}

.stickycolumn.my-special-sticky {
  /* background-color: var(--white) !important; */
}

/*tr:has(.stickycolumn.my-special-sticky):hover .stickycolumn.my-special-sticky {
  background-color: #ebeced;
}*/

.modalHeader {
  border-radius: 5px 5px 0 0;
  background: #ebf0ff;
  display: flex;
  padding: 7px 10px;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.modalHeader h2 {
  font-family: "Roboto";
  font-size: 17px;
  font-weight: 600;
  line-height: 100%;
  margin: 0;
}

.jay-put-this-checkbox.form-switch .form-check-input {
  height: 10px !important;
  width: 25px !important;
}

.jay-put-this-checkbox {
  display: flex;
  align-items: center;
}

.jay-put-this-checkbox,
.jay-put-this-checkbox label {
  margin-bottom: 0 !important;
  font-weight: 600;
}

.tools-new-design-main {
  background: #fff3f3;
  border-radius: 10px;
  padding: 0;
}

.tools-new-design-main h4 {
  font-size: 24px;
  font-weight: 500;
  padding: 12px 0 8px 10px;
  color: #202842;
  margin-bottom: 0;
}

.tools-new-design-body p.danger-color-text {
  font-size: 14px;
  font-weight: 500;
  color: #ee4646;
  margin-bottom: 10px;
}

.tools-new-design-body p {
  color: #20284280;
  font-size: 14px;
  margin-bottom: 10px;
  cursor: pointer;
}

.tools-new-design-body {
  padding: 20px 10px;
}

.tools-new-design-header {
  border-bottom: 1px solid #20284215;
}

.transparent-modal .modal-content {
  background-color: transparent !important;
}

.tools-new-design-right-sec-table table {
  background: var(--white) !important;
  vertical-align: middle;
  border-radius: 10px;
}

.RmEventDetailCardMain.light-pink-bg-wrapper .eventDetailFooterBtn {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.RmEventDetailCardMain.light-pink-bg-wrapper .eventDetailWrapper {
  height: 100%;
  position: relative;
}

.RmEventDetailCardMain.light-pink-bg-wrapper {
  background: #fff3f3;
  border-radius: 10px 0 0 10px;
  height: 100%;
}

.tools-new-design-right-sec {
  background: #fff3f3;
  border-radius: 0 10px 10px 0;
  padding: 20px;
  height: 100%;
  max-height: 658px;
  overflow: auto;
  border-left: 1px solid #20284226;
}

.tools-new-design-right-sec-table table * {
  font-size: 12px !important;
}

.tools-new-design-right-sec-table table td {
  vertical-align: middle;
  border-color: #20284226 !important;
  padding: 5px 10px;
}

.tools-new-design-right-sec-table table th {
  font-size: 12px;
  font-weight: 600;
  vertical-align: middle;
  border-color: #20284226 !important;
  padding: 5px 10px;
}

.tools-new-design-right-sec-table table thead tr:first-child th:first-child {
  border-radius: 10px 0 0 10px;
}

.tools-new-design-right-sec-table table thead tr:first-child th:last-child {
  border-radius: 0 10px 10px 0;
}

.tools-new-design-right-sec-table table tbody tr:last-child td:first-child {
  border-radius: 10px 0 0 10px;
}

.tools-new-design-right-sec-table table tbody tr:last-child td {
  border-bottom: none;
}

.tools-new-design-right-sec-table table tbody tr:last-child td:last-child {
  border-radius: 0 10px 10px 0;
}

.tools-new-design-right-sec h6 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0;
}

.tools-new-design-right-sec h6.tools-new-design-right-sec-red-title {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #ee4646;
  margin-top: 10px;
  margin-bottom: 5px;
}

.tools-new-design-right-sec-table.no-thead-table
  tbody
  tr:first-child
  td:first-child {
  border-radius: 10px 0 0 10px;
}

.tools-new-design-right-sec-table.no-thead-table
  tbody
  tr:first-child
  td:last-child {
  border-radius: 0 10px 10px 0;
}

.toolSummaryModalHeader {
  display: flex;
  align-items: center;
  gap: 10px;
}

.all-weathers-main {
  /* display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 15px; */
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.weather-page-main-content {
  max-width: 650px;
}

.single-weather-image .weather-addicon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  z-index: 2;
  width: 30px;
  height: 30px;
  object-fit: contain;
  /* filter: brightness(0); */
}

.single-weather-wrapper:hover .single-weather-image img.weather-img {
  filter: blur(2px);
}

.single-weather-wrapper:hover .single-weather-image .weather-addicon {
  display: block;
}

.single-weather-image {
  border-radius: 10px;
  background: #ebf0ff;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.single-weather-wrapper p {
  color: #202842;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}

.single-weather-wrapper h6 {
  color: #202842;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 5px;
  margin-bottom: 5px;
}

.single-weather-wrapper {
  text-align: center;
}

.today-weather span {
  color: #202842;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: auto;
}

.today-weather h1 {
  color: #202842;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  margin-bottom: 0;
}

.today-weather p span {
  font-size: 16px;
  font-weight: 700;
}

.today-weather p {
  color: #202842;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}

.today-weather-right img {
  width: 120px;
  height: 120px;
  object-fit: cover;
}

.today-weather {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: 10px;
  background: #ebf0ff;
  padding: 20px 40px;
}

.single-weather-image img {
  width: 35px;
  height: 35px;
  object-fit: contain;
}

span.with-extra-padding {
  padding: 4px 8px !important;
}

.poiter-event-none {
  pointer-events: none;
}

.mainRoot.icon-toggle-menu .container-menu-bar {
  width: 80px !important;
  padding: 0px 0px;
  padding-left: 10px;
  scrollbar-width: none !important;
}

.mainRoot.icon-toggle-menu .container-menu-bar li {
  cursor: pointer;
}

.mainRoot.icon-toggle-menu .container-menu-bar li .accordion-item,
.mainRoot.icon-toggle-menu .container-menu-bar a {
  border-bottom: none !important;
}

.mainRoot.icon-toggle-menu .container-menu-bar a {
  padding: 10px 15px 10px 5px !important;
  justify-content: center;
}

.mainRoot.icon-toggle-menu .container-menu-bar button {
  pointer-events: none;
  padding: 15px 0 !important;
  justify-content: center;
}

.mainRoot.icon-toggle-menu .container-menu-bar button::after {
  display: none !important;
}

.mainRoot.icon-toggle-menu .container-menu-bar span.main-menu-text {
  display: none;
}

.icon-toggle-menu .container-menu-bar ul .accordion-collapse.collapse.show {
  display: none;
}

.icon-toggle-menu .container-menu-bar ul li:hover {
  position: relative;
}

.icon-toggle-menu .container-menu-bar ul li:hover .accordion-collapse {
  display: block !important;
  position: absolute;
  left: 100%;
  top: 0;
  /* background: #fff; */
  /* height: calc(100% - 80px); */
  overflow: auto;
  /* box-shadow: 1px 0 7px -2px #888888; */
  border-radius: 0 !important;
}

.icon-toggle-menu
  .container-menu-bar
  ul
  li:hover
  .accordion-collapse
  .accordion-body {
  padding: 10px !important;
  width: auto;
  min-width: 225px;
  background: #e1e8ff !important;
  /* height: calc(100% - 80px); */
  /* overflow: auto; */
  box-shadow: 1px 0 4px -2px #888888;
  border-radius: 0 10px 10px 0;
  position: fixed;
  z-index: 99;
}

.icon-toggle-menu
  .container-menu-bar
  ul
  li:hover
  .accordion-collapse
  .accordion-body
  li {
  margin-bottom: 0px !important;
}

.mainRoot.icon-toggle-menu .container-data-load {
  width: calc(100vw - 90px);
}

.icon-toggle-menu .container-menu-bar .accordion-button {
  padding-left: 0 !important;
}

.chat-inner-btns {
  position: sticky;
  bottom: 0;
  left: 0;
  background: #fcfcfc;
  padding: 15px 0;
  display: flex;
  align-items: center;
  grid-gap: 20px;
  flex-wrap: wrap;
}

button.different-only-bordered-btn:hover {
  background: #b5e2fa80;
}

button.different-only-bordered-btn {
  padding: 10px 20px;
  border: 1px solid #b5e2fa;
  border-radius: 5px;
  color: var(--black);
  font-weight: 500;
  font-size: 13px;
  line-height: 100%;
  background: #b5e2fa;
}

.different-send-btn {
  border: none !important;
  box-shadow: none !important;
  background: transparent;
  position: absolute;
  right: 15px;
  top: 12px;
  cursor: pointer;
  transition: all 0.4s;
}

.different-send-btn:hover {
  transform: scale(0.9);
  transition: all 0.4s;
}

.common-chat-bot-nova img {
  width: 75px;
  height: 75px;
  position: absolute;
  object-fit: contain;
  top: -13px;
  left: 4px;
  transition: all 0.4s;
}

.common-chat-bot-nova h6 {
  font-size: 16px;
  color: var(--blue);
  color: var(--white);
  font-size: 16px;
  padding: 12.5px 18px;
  border-radius: 45px;
  background: #3565fc;
  margin-bottom: 0;
  transition: all 0.4s;
}

/* .common-chat-bot-nova:hover .common-chat-bot-nova-inner img {
  position: static;
  width: 160px;
  height: 160px;
  object-fit: contain;
  margin-bottom: 25px;
  margin-top: 10px;
  transition: all 0.4s;
}

.common-chat-bot-nova:hover .common-chat-bot-nova-inner h6 {
  width: 100%;
  transition: all 0.4s;
}

.common-chat-bot-nova:hover .common-chat-bot-nova-inner {
  display: block;
  border-radius: 30px;
  padding: 13px;
  transition: all 0.4s;
}  */

.common-chat-bot-nova {
  position: fixed;
  bottom: 10px;
  left: 20px;
  max-width: fit-content;
  text-align: center;
  min-width: 160px;
  max-width: 160px;
  z-index: 999999;
  transition: all 0.4s;
}

.common-chat-bot-nova-inner {
  display: flex;
  align-items: center;
  border-radius: 60px;
  background: #1c3472;
  padding: 13px 13px 13px 65px;
  transition: all 0.4s;
}

.common-chat-bot-nova-inner {
  position: relative;
  transition: all 0.4s;
}

.predicationRateBold p {
  font-weight: bold;
}

.enter-comment-here .cke_top {
  display: none;
}

.make-comment-in-widget .cke_contents {
  height: 50px !important;
}

.enter-comment-here > div {
  width: 100% !important;
}

.chatUser {
  display: flex;
  justify-content: end;
}

.chatBox {
  display: flex !important;
  gap: 5px !important;
  max-width: 70% !important;
}

.chatBox img {
  height: 30px !important;
  border-radius: 50%;
}

.aiChatBox {
  background: #f1f1f1 !important;
  padding: 10px 20px !important;
}

.userChatBox {
  background: #4285f4 !important;
  padding: 10px 10px !important;
  color: white;
}

.chatBoxUser {
  justify-content: end;
  flex-direction: row-reverse;
}

.myBadge .bg-info {
  color: #e09f3e !important;
  background-color: #fff3b0 !important;
}

.lastUpdateSuccess {
  padding: 3px !important;
  color: #009a4f;
  background-color: #d9f0e7;
  border: 1px solid;
  border-radius: 5px;
}

.lastUpdateInProgress {
  padding: 3px !important;
  color: #e09f3e;
  background-color: #fff3b0;
  border: 1px solid;
  border-radius: 5px;
}

.lastUpdateFailed {
  padding: 3px !important;
  color: #ea2b12;
  background-color: #f3d7d6;
  border: 1px solid;
  border-radius: 5px;
}

.asideConfigurationMain.revenue-management-aside-main .font16 {
  font-size: 12px !important;
}

.lastupdateinformation {
  margin-top: 10px !important;
}

.lastupdateinformation .status {
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border: 1px solid;
  border-radius: 10px;
  background-color: #ea2b12;
  color: #ea2b12;
}

.lastupdateinformation .status.inprocess {
  background-color: #e09f3e !important;
  color: #e09f3e !important;
}

.lastupdateinformation .status.success {
  background-color: #009a4f !important;
  color: #009a4f !important;
}

.propertyCodeBadge {
  background-color: #e4e4e4 !important;
  color: black !important;
  margin-right: 5px !important;
}

.secondStageModal {
  filter: none !important;
}

/*.heamMapDayWiseTbl td, .heamMapDayWiseTbl th {
    font-size: 10px !important;
}
*/
.asidecalendar *::-webkit-scrollbar {
  display: none !important;
}

.asidecalendar * {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
  font-size: 11px !important;
}

.asidecalendar p.asidecalendar-month-name {
  font-size: 13px !important;
  font-weight: 700 !important;
}

.asidecalendar-inner {
  position: sticky;
  top: 81px;
  left: 0;
}

.asidecalendar-inner #heamMapDayWiseTbl {
  border-bottom: 1px solid rgba(53, 101, 252, 0.5);
}

.asidecalendar {
  width: 220px;
  max-width: 220px;
  margin-top: 5px;
}

.asidecalendartable {
  width: calc(100% - 240px);
  max-width: calc(100% - 240px);
}

/*heammapdaywise-dataview {
    width: calc(100% - 200px);
}*/
img.filter-blue {
  filter: brightness(0) saturate(100%) invert(28%) sepia(73%) saturate(3434%)
    hue-rotate(222deg) brightness(104%) contrast(98%);
}

.ViewPropertySetupTable table .seasonVarianceSpan {
  font-size: 10px !important;
  margin-left: 5px;
}

.seasonalityAnalysisCount p {
  font-size: 14px;
  line-height: 100%;
}

.seasonalityAnalysisCount p {
  margin-bottom: 0;
}

.seasonalityAnalysisCount {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 8px;
  padding-left: 10px;
  float: right;
  /* border: 1px solid;
    padding: 5px; */
}

.seasonalityAnalysisCount div {
  width: 14px;
  height: 14px;
  border-radius: 3px;
}

.bubblechart_highlight_data td {
  background-color: #ebeced !important;
  font-weight: bold;
}

.dbBoxes.revEvolveBg .side-arrow {
  margin-left: auto;
}

.dbBoxes.revEvolveBg,
.dbBoxes.revEvolveBg:hover {
  position: relative;
  /* background: var(--white); */
}

.dbBoxes.revEvolveBg::after {
  content: "";
  position: absolute;
  background: url("../Images/RevEvolve.png") no-repeat;
  background-size: contain;
  width: calc(100% - 30px);
  height: 200px;
  top: 0;
  left: 30px;
}

.special-btn-input-type-file [type="file"] {
  color: #878787;
}

.special-btn-input-type-file [type="file"]::-webkit-file-upload-button {
  background: var(--blue);
  border: 1px solid var(--blue);
  color: var(--white);
  cursor: pointer;
  font-weight: 500;
  font-size: 13px;
  outline: none;
  padding: 7px 20px;
  border-radius: 5px;
  text-transform: uppercase;
  transition: all 1s ease;
}

.disable-special-btn-input-type-file
  [type="file"]::-webkit-file-upload-button:hover {
  cursor: not-allowed;
}

.disable-special-btn-input-type-file [type="file"]::-webkit-file-upload-button {
  opacity: 0.5;
}

.new-sign-in-blue:hover {
  background: var(--white);
  color: #2b388f;
}

.new-sign-in-blue {
  border-radius: 5px;
  background: #2b388f !important;
  color: var(--white) !important;
  text-align: center;
  border: 1px solid #2b388f !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 19px;
  width: 100%;
  text-align: center;
}

.new-sign-in-orange:hover img {
  filter: brightness(0) saturate(100%) invert(24%) sepia(100%) saturate(1993%)
    hue-rotate(352deg) brightness(106%) contrast(87%);
}

.new-sign-in-orange:hover {
  background: var(--white);
  color: #ee4023;
}

.new-sign-in-orange {
  color: var(--white);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 5px;
  background: #ee4023;
  border: 1px solid #ee4023;
  padding: 19px;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.or-login {
  color: #202842;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.new-sign-in-page-main {
  width: 100vw;
  height: 100vh;
  background: url("../Images/LogIn/newbg.png") no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fontBold {
  font-weight: bold;
}

.unreadnotification {
  background-color: rgb(240, 248, 255) !important;
}

.unreadnotificationcount {
  color: rgb(255, 255, 255);
  font-size: 12px;
  border-radius: 10px;
  background: rgb(53, 101, 252);
  position: absolute;
  padding: 2px 5px;
  /*top: -5px;*/
  left: calc(100% - 50px);
  box-shadow: rgba(12, 0, 0, 0.08) 0px 1px 8px 0px;
}

.payrollWidgetCount {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.singlePayrollWidgetCount {
  display: flex;
  width: 200px;
  flex-direction: column;
  align-items: center;
  border: 1px solid black;
}

.asideConfigurationMain .accordion-item.rateShopMenu {
  border: none !important;
  margin-bottom: 0 !important;
}

.asideConfigurationMain .accordion .rateShopAccrodian {
  padding: 0px 20px 0px 35px !important;
}

/*table tr#subgridbackground td {
  background-color: #ebeced !important;
}
*/
.dashboard-todo {
  /*padding: 10px 20px;*/
  border-radius: 20px;
  border: 1px solid #cfd9f8;
  /*margin-bottom: 30px;*/
}

.dashboard-todo-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  background: #ebf0ff;
  border-radius: 20px 20px 0px 0px;
  padding: 10px 15px;
}

.dashboard-todo-list {
  height: 80vh;
  overflow-y: auto;
  padding: 10px 15px;
}

.dashboard-todo-list .singleTodo {
  background: rgba(28, 52, 114, 0.1);
  padding: 10px;
  margin: 2px 0px;
  border-radius: 5px;
  display: block;
}

.dashboard-todo-list .singleTodo .summary {
  display: flex;
  justify-content: space-between;
  /*align-items: center;*/
  align-items: start;
}

.dashboard-todo-list .singleTodo .details {
  font-size: 12px;
  padding-left: 30px;
  max-height: 40px;
  overflow: hidden;
}

.portfolio-map-property {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  gap: 15px;
  border: 1px solid #bbcafe;
  border-radius: 10px;
}

.portfolio-map-property:hover {
  background-color: #e1e8ff;
}

.portfolio-map-property.active {
  background-color: #e1e8ff;
}

.portfolio-map-property-info {
  display: flex;
  align-items: center;
  gap: 15px;
}

.portfolio-map-property .propertyName {
  font-size: 14px;
}

.heading-menu-btns.heading-menu-btns-revpak {
  padding: 3px !important;
  max-height: fit-content !important;
  height: fit-content !important;
}

.allSettingsMain.portfoliorollup {
  display: grid;
  grid-template-columns: repeat(6, 1fr) !important;
  grid-gap: 20px;
}

.allSettingsMain.portfoliorollup .allSettingsBox p:nth-child(2) {
  font-size: 20px !important;
}

.portfoliorollup-map-widget-count {
  padding: 2px 2px;
  width: 50px;
  text-align: center;
  border-radius: 5px;
}

.stripped__table .backgroundTrRed {
  background-color: #ff0000;
}

.stripped__table .backgroundTrRed td {
  background-color: transparent !important;
  color: white;
}
.horizontal-menu-option.active.reservationFilter {
  max-width: 150px;
}
.reservationFilterList {
  max-height: 200px;
  overflow-y: auto;
}

/* .ViewPropertySetupTable tr:nth-child(even),
.stripped__table tr:nth-child(even) {
  background-color: #ebeced;
}
.ViewPropertySetupTable tr:nth-child(even) td,
.stripped__table tr:nth-child(even) td {
  background-color: transparent !important;
} */

.ViewPropertySetupTable tr td.lightGrayColor,
.lightGrayColor {
  background-color: #f2f2f2 !important;
}

.ViewPropertySetupTable tr td.lightBlueColor,
.lightBlueColor {
  background-color: #a5dbfc !important;
}

.ViewPropertySetupTable tr td.yellowColor,
.yellowColor {
  background-color: #feea5c !important;
}

.ViewPropertySetupTable tr td.orangeColor,
.orangeColor {
  background-color: #feb938 !important;
}

.ViewPropertySetupTable tr td.redColor,
.redColor {
  background-color: #ff5252 !important;
}

.ViewPropertySetupTable tr td.color0to19,
.color0to19 {
  background-color: #e9ecef !important;
}

.ViewPropertySetupTable tr td.color20to34,
.color20to34 {
  background-color: #dee2e6 !important;
}

.ViewPropertySetupTable tr td.color35to49,
.color35to49 {
  background-color: #ced4da !important;
}

.ViewPropertySetupTable tr td.color50to64,
.color50to64 {
  background-color: #84d2f6 !important;
}

.ViewPropertySetupTable tr td.color65to74,
.color65to74 {
  background-color: #59a5d8 !important;
}

.ViewPropertySetupTable tr td.color75to84,
.color75to84 {
  background-color: #ffd000 !important;
}

.ViewPropertySetupTable tr td.color85to89,
.color85to89 {
  background-color: #ffdd00 !important;
}

.ViewPropertySetupTable tr td.color90to94,
.color90to94 {
  background-color: #ffaa00 !important;
}

.ViewPropertySetupTable tr td.color95to100,
.color95to100 {
  background-color: #ff8800 !important;
}

.ViewPropertySetupTable tr td.color100above,
.color100above {
  background-color: #ff5252 !important;
}

.ViewPropertySetupTable tr td.boldTd,
.stripped__table tr td.boldTd {
  font-weight: bold !important;
  background: var(--light) !important;
}

.ViewPropertySetupTable tr td.boldTd,
.stripped__table tr td.boldTd {
  background-color: var(--light) !important;
}
.ViewPropertySetupTable table tr.shadowOnHover:hover,
.stripped__table table tr.shadowOnHover:hover,
.ViewPropertySetupTable table tr.shadowOnHover:hover,
.stripped__table table tr.shadowOnHover:hover td {
  background-color: #c2c5c8 !important;
}

.ViewPropertySetupTable table tr.shadowOnHover:hover,
.stripped__table table tr.shadowOnHover:hover * {
  font-weight: bold !important;
}

.ViewPropertySetupTable .shadowOnHover:hover td,
.stripped__table .shadowOnHover:hover td {
  /* background-color: transparent !important; */
  color: #000 !important;
}

.ViewPropertySetupTable table tr.bookingWindowHover:hover,
.stripped__table table tr.bookingWindowHover:hover * {
  font-weight: bold !important;
}

.ViewPropertySetupTable .bookingWindowHover:hover td,
.stripped__table .bookingWindowHover:hover td {
  /* background-color: transparent !important; */
  color: #000 !important;
}

.ViewPropertySetupTable tr td.swotAnalysis {
  background-color: #f0f8ff !important;
}

/* .ViewPropertySetupTable tr:nth-child(even) td, .stripped__table tr:nth-child(even) td.stickycolumn.my-special-sticky{
  background-color: #ebeced !important;
} */
.backgroundTr {
  background-color: #f0f8ff !important;
}
.backgroundTr td {
  background-color: transparent !important;
}

.childTrColor {
  background-color: #a7d6ff !important;
}
.childTrColor td {
  background-color: transparent !important;
}

.ViewPropertySetupTable tr:nth-child(even) td,
.stripped__table tr:nth-child(even) td {
  background-color: #ebeced;
}

.ViewPropertySetupTable tr:nth-child(odd) td,
.stripped__table tr:nth-child(odd) td {
  background-color: #ffffff;
}
.displayNone {
  display: none !important;
}
td.tdBorder {
  border: 1px solid black !important;
}
td.backgroundTd {
  background-color: #f0f8ff !important;
}
.singleDateSummaryModalHeader {
  display: flex;
  gap: 10px;
  align-items: center;
}
.singleDateSummaryDropdown {
  padding: 0 2.25rem 0 0.75rem !important;
}
.single-date-summary-date-picker
  .react-datepicker-wrapper
  .react-datepicker__input-container
  input {
  border-radius: 5px;
  border: 1px solid #cfd9f8 !important;
  padding: 5px;
  font-weight: 400;
  font-size: 11px;
  line-height: 100%;
  color: var(--font-color);
  /*background: transparent;*/
  width: 100%;
}
.dashboardGlanceSecondRow {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 20px;
}
.dashboardGlanceThirdRow {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 20px;
  margin-top: 20px;
}
.footerForCardDashboard {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 12px;
}
.rangeBtn {
  cursor: pointer;
  padding: 5px;
  border: 1px solid gray;
  border-radius: 5px;
}
.disableRangeBtn {
  border: 1px solid gray;
}
.activeRangeBtn {
  border: 1px solid #3565fc !important;
}
.dashboard-chat-bot-nova img {
  width: 75px;
  height: 60px;
  position: absolute;
  object-fit: contain;
  top: -13px;
  left: 4px;
  transition: all 0.4s;
}

.dashboard-chat-bot-nova h6 {
  font-size: 16px;
  color: var(--blue);
  color: var(--white);
  font-size: 16px;
  padding: 12.5px 18px;
  border-radius: 45px;
  background: #3565fc;
  margin-bottom: 0;
  transition: all 0.4s;
}

/* .dashboard-chat-bot-nova:hover .dashboard-chat-bot-nova-inner img {
  position: static;
  width: 160px;
  height: 160px;
  object-fit: contain;
  margin-bottom: 25px;
  margin-top: 10px;
  transition: all 0.4s;
}

.dashboard-chat-bot-nova:hover .dashboard-chat-bot-nova-inner h6 {
  width: 100%;
  transition: all 0.4s;
}

.dashboard-chat-bot-nova:hover .dashboard-chat-bot-nova-inner {
  display: block;
  border-radius: 30px;
  padding: 13px;
  transition: all 0.4s;
} */

.dashboard-chat-bot-nova {
  position: absolute;
  bottom: 3px;
  left: 10px;
  max-width: fit-content;
  text-align: center;
  min-width: 85px;
  max-width: 85px;
  z-index: 999999;
  transition: all 0.4s;
}

.dashboard-chat-bot-nova-inner {
  display: flex;
  align-items: center;
  border-radius: 60px;
  background: #1c3472;
  padding: 13px 13px 13px 65px;
  transition: all 0.4s;
}

.dashboard-chat-bot-nova-inner {
  height: 50px;
  position: relative;
  transition: all 0.4s;
}
.positiveCompVariance {
  background-color: green !important;
}
.positiveCompVariance td {
  background-color: transparent !important;
}
.negativeCompVariance {
  background-color: red !important;
}
.negativeCompVariance td {
  background-color: transparent !important;
}
.compSetPerChange {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  color: white !important;
  border-radius: 5px;
}
.compSetPerChangeBgPositive {
  background-color: #00a302;
}
.compSetPerChangeBgNegative {
  background-color: #ff1c1c;
}
.revGlancePage {
  /* height: calc(100vh - 81px); */
}

.revGlanceChatPage {
  height: calc(50vh - 80px);
  overflow-y: auto;
}
.hideRevGlanceChatPage {
  display: none;
}

.chatModal {
  height: calc(50vh - 80px);
  background: white;
  bottom: 0;
  z-index: 999999 !important;
  width: 100%;
}

.fullScrennChatModal {
  height: calc(100vh - 150px);
}

.chatModal .glanceChatHeader {
  margin-top: 3px;
  background: #e1e8ff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.chatModal #chatAreaDetails {
  overflow-y: auto;
  height: calc(50vh - 140px);
  padding: 0 10px;
}

.chatModal .fullScreenChatAreaDetails {
  height: calc(100vh - 215px) !important;
}

.chatModal .inputbox {
  width: calc(100% - 34px);
  display: flex;
  align-items: center;
  gap: 5px;
  position: absolute;
  bottom: 0px;
}

.chatModal .glance-send-btn {
  border: none !important;
  box-shadow: none !important;
  background: transparent;
  position: absolute;
  right: 15px;
  top: 12px;
  cursor: pointer;
  transition: all 0.4s;
}

.chatModal .glance-send-btn:hover {
  transform: scale(0.9);
  transition: all 0.4s;
}

tr.rateCodeRow td {
  background-color: rgb(167, 214, 255) !important;
}
.rateshop-table-for-heading-menu {
  position: relative !important;
}

.rateshop-table-for-heading-menu .widget-container-menu {
  right: 5px;
  position: absolute;
  z-index: 99;
  display: none;
  margin-top: -25px;
  padding-bottom: 5px;
}

.rateshop-table-for-heading-menu .widget-container-menu .heading-menu-btns {
  padding: 2px !important;
}

.rateshop-table-for-heading-menu:hover .widget-container-menu {
  display: flex !important;
}
.table-toggle-link {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--blue);
  cursor: pointer;
  text-decoration: underline;
}
.rateiq-table-for-heading-menu {
  position: relative !important;
}
.rateiq-table-for-heading-menu .widget-container-menu {
  right: 5px;
  position: absolute;
  z-index: 99;
  display: none;
  margin-top: -25px;
  padding-bottom: 5px;
}
.rateiq-table-for-heading-menu .widget-container-menu .heading-menu-btns {
  padding: 2px !important;
}
.rateiq-table-for-heading-menu:hover .widget-container-menu {
  display: flex !important;
}

.next-forecast-pickup-heading-menu {
  position: relative !important;
}
.next-forecast-pickup-heading-menu .widget-container-menu {
  right: 5px;
  position: absolute;
  z-index: 99;
  display: none;
  margin-top: -25px;
  padding-bottom: 5px;
}
.next-forecast-pickup-heading-menu .widget-container-menu .heading-menu-btns {
  padding: 2px !important;
}
.next-forecast-pickup-heading-menu:hover .widget-container-menu {
  display: flex !important;
}