@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.mainDiv {
  /* height: 100vh; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.childDiv div {
  position: absolute;
  animation: loading 1s linear infinite;
  width: 80px;
  height: 80px;
  top: 10px;
  left: 10px;
  border-radius: 50%;
  box-shadow: 0 2px 0 0 #3565fc;
  transform-origin: 40px 41px;
}
.parentDiv {
  width: 51px;
  height: 51px;
  display: inline-block;
  overflow: hidden;
  background: rgba(NaN, NaN, NaN, 0);
}
.childDiv {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.51);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.childDiv div {
  box-sizing: content-box;
}
tr .mainDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
}