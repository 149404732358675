@import "./Assets/Styles/variables.css";
@import "./Assets/Styles/component.css";
@import "./Assets/Styles/global.css";
@import "react-datepicker/dist/react-datepicker.css";
@import "primereact/resources/themes/lara-light-indigo/theme.css";
@import "primereact/resources/primereact.min.css";
@import "./Assets/Styles/responsive.css";
@import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

/* 22-08-2023 */

.OperationRatesMain table b {
    font-weight: 500 !important;
}

/* 23-08-2023 */

.propertySettingSettingsMain > div:first-child {
    width: 110px;
}

.propertySettingSettingsMain > div:last-child {
    width: calc(100% - 110px);
}

/* 24-08-2023 */


thead.sticky-header tr:first-child th {
    border-top: 1px solid #cfd9f8 !important;
}

.ViewPropertySetupTable {
    border-radius: 5px;
    border: 1px solid #cfd9f8;
    /* padding: 2px; */
}

    .ViewPropertySetupTable .stripped__table table {
        border: none !important;
        padding: 0;
    }

        /* .ViewPropertySetupTable .stripped__table table thead {
  position: sticky;
  top: 0px;
} */

        /* .ViewPropertySetupTable .stripped__table>div {
  overflow: visible;
} */

        .ViewPropertySetupTable .stripped__table table tr:not(:last-child) {
            border: 1px solid #cfd9f8 !important;
        }

/*.table-header {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  margin-top: 20px;
  margin-bottom: 10px;
  grid-gap: 10px;
}*/

.RoomsPercentilesData {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-gap: 10px;
    padding: 10px;
}

.icon_dropDown {
    display: flex;
    width: 30px;
    /*height: 100%;*/
    height: 30px;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 1px solid #cfd9f8 !important;
}

.icon_opation {
    display: block;
    position: absolute;
    top: calc(100% + 10px);
    left: 0;
    background: #fff;
    width: 242px;
    border: 1px solid #cfd9f8 !important;
    border-radius: 5px;
    z-index: 10;
    max-height: 400px;
    overflow: auto;
    /*padding: 10px;*/
    /* padding-top: 10px;
    padding-bottom: 10px;*/
}

    .icon_opation .icon {
        width: 40px;
        height: 40px;
        float: left;
        text-align: center;
        border: 1px solid #cfd9f8 !important;
        padding-top: 5px;
        cursor: pointer;
    }



/*.icon_opation > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

        .icon_opation > div > div {
            padding: 10px 0;
            width: 100%;
            display: flex;
            justify-content: center;
        }

            .icon_opation > div > div:not(:last-child) {
                border-bottom: 1px solid #cfd9f8 !important;
            }*/
/* drag hover */
.dragging-over-col {
    background: lightblue;
}

.modal-open:has(.DeviceDetailModalMain) .viewListingModalMain {
    filter: blur(3px);
}

/* 04-08-23 */

/* .GroupReservationsPopupMain {
  width: 960px;
} */

/* 05-08-23 */




.its_accordion_btn {
    cursor: pointer;
    padding-right: 24px;
    position: relative;
}

    .its_accordion_btn::after {
        position: absolute;
        content: "";
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 18px;
        height: 18px;
        background: url(./Assets/Images/Action\ Icons/down_arrow.svg) no-repeat;
    }

.its_accordion_content.hidden {
    display: none;
}

    .its_accordion_content.hidden:has(.its_accordion_btn_show) {
        display: table-row;
    }

.its_accordion_content .its_accordion_btn_main {
    padding-left: 20px;
}

.its_accordion_content td:not(.its_accordion_btn_main):first-child {
    padding-left: 30px;
}

.GroupReservationsPopupMain .modal-content {
    height: 695px;
}

.small-padding{
    padding: 0 5px !important;
}
.weekly-report-div{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.weekly-report-heading{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.running-report-div{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.rate-iq-setting-modal{
    display: flex;
    justify-content: end;
    align-items: center;
    position: sticky;
    bottom: 0;
    background: white;
}
