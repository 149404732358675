:root {
  --bg_color: #2c3e50;
  --dark_color: #1e2b37;
  --white: #ffffff;
  --black: #000000;
  --heading_font: 1.75em;
  --regular_font: 14px;
  --small_font: 1em;
  --font_weight: 400;
  --line_height: 1.5;
  --table_border_color: #dddddd;
  --today_color: rgba(255, 220, 40, 0.15);
  --event_bg_color: #3788d8;
  --event_bg_hover: #4975a1d9;
  --list-bg-color: hsla(0, 0%, 82%, 0.3);
}

body {
  background: #f5f6f8;
}
.calender_main {
  /* width: 1208px;
  height: 600px; */
  /* margin: 30px auto 0; */
  /* background: var(--white);
  border-top: 4px solid #fac863;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1); */
  font-size: var(--regular_font);
  line-height: 1.3;
  overflow-y: auto;
}

.viewProperty_calender_main .calender_buttons{
  width: 250px;
  margin: 0 auto;
  position: absolute;
  top: 6px;
  left: 40%;
}
.viewProperty_calender_main {
  width: 100%;
  height: 400px;
  margin: 30px auto 0;
  background: var(--white);
  border-top: 4px solid #fac863;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  font-size: var(--regular_font);
  line-height: 1.3;
  overflow-y: auto;
}
/* .calender_buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 16px 21px;
  animation: 0;
  margin-bottom: 0;
  position: sticky;
  top: 0;
  background: transparent;
  z-index: 111;
} */
.calender_buttons .text_start,
.calender_buttons .text_end {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  justify-content: center;
  grid-gap: 15px;
  margin-bottom: 20px;
}
.calender_buttons .text_end .btn_css:first-child {
  border-radius: 0.25em 0 0 0.25em;
}
.calender_buttons .text_end .btn_css:last-child {
  border-radius: 0 0.25em 0.25em 0;
}
.calender_buttons .btn_css {
  border: none;
  /* background: var(--bg_color);
  color: var(--white);
  display: flex;
  align-items: center;
  font-size: var(--small_font);
  font-weight: var(--font_weight);
  line-height: var(--line_height);
  padding: 0.4em 0.65em; */
}
.calender_buttons .prev {
  border-radius: 0.25em 0 0 0.25em;
  background: none;
}
.calender_buttons .next {
  border-radius: 0 0.25em 0.25em 0;
  background: none;
}
.text_start button svg path:last-child {
  stroke: var(--black);
}
/* .calender_buttons .text_start .btn_css:last-child {
  margin-left: 20px;
  border-radius: 0.25em;
} */
/* .calender_buttons .btn_css:hover,
.calender_buttons .btn_css.view_active {
  background: var(--dark_color);
} */
.calender_buttons .text_center h4 {
  font-size: var(--heading_font);
  font-weight: 500;
  margin: 0;
}

.calender_body table,
.calender_body table th,
.calender_body table td {
  border: 1px solid var(--table_border_color);
  border-collapse: collapse;
}
.list-view-calender:not(:last-child) {
  border-bottom: none !important;
}
.list-view-calender:not(:first-child) {
  border-top: none !important;
}
.list-view-calender td,
.list-view-calender th {
  border: none !important;
}
.list-view-calender tr {
  border: none;
  border-bottom: 1px solid var(--table_border_color);
}
.calender_body table {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.calender_body table .table_cell {
  display: table-cell;
  width: 100px;
}
.calender_body table .table_body_cell {
  display: table-cell;

  overflow: auto;
}
.calender_body table .table_body_cell .main-cell {
  /* width: 100%;
  height: 100%; */
  /* text-align: end; */
  /* width: 164px; */
  height: 150px;
  position: relative;
  padding: 8px;
}
.calender_body table .table_body_cell .main-cell .date-cell {
  text-align: left;
  color: #202842;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.calender_body .disable {
  opacity: 0.3;
  pointer-events: none;
  cursor: grab;
}
.calender_body .today {
  background: var(--today_color);
}
/* .calender_body .event_details {
  margin: 0 auto 2px;
  background: var(--event_bg_color);
  color: var(--white);
  border-radius: 3px;
  box-sizing: border-box;
  width: 98%;
  padding: 2px 4px;
  text-align: left;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
} */
.calender_body .event_details:hover {
  /* background: var(--event_bg_hover); */
}
.calender_body .more_btn {
  /* background: var(--bg_color); */
}
.calender_body .more_btn:hover {
  /* background: var(--dark_color); */
  cursor: pointer;
}
.calender_body table .week_cell {
  display: table-cell;
  width: 100px;
}
.calender_body table .week_cell:first-child {
  width: 54px;
}
.calender_body table .day_cell,
.calender_body table .all_day_cell {
  display: table-cell;
  height: 48px;
  text-align: end;
}
.calender_body table .day_cell:first-child,
.calender_body table .all_day_cell:first-child {
  padding-right: 10px;
}

.calender_body .dayWeek_view_calender_thead {
  position: sticky;
  top: 73px;
  background: var(--white);
}
/* .day_week_body tr:first-child{
      position: sticky;
      top: 112px;
      background: var(--white);
  } */
.calender_body table .day_cell:first-child {
  width: 54px !important;
}
.list-view-calender .list_head {
  background: var(--list-bg-color);
  text-align: left;
}
.list-view-calender .list_head th {
  padding: 8px 14px;
  display: flex;
  justify-content: space-between;
}
.list-view-calender .list_body tr td p {
  padding: 8px 24px;
  margin: 0;
}
.list-view-calender .list_body tr td {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.list-view-calender .list_body tr td p:first-child {
  width: 160px;
}
.list-view-calender .list_body tr td .dot-blue {
  position: relative;
}
.list-view-calender .list_body tr td .dot-blue::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  left: 0;
  width: 10px;
  height: 10px;
  background: var(--event_bg_color);
  border-radius: 50%;
}
.calender_body {
  padding: 0;
  margin-top: 20px;
}
.calender_body table .day_cell.active {
  background-color: var(--today_color);
}

/* timeline css */

.day_week_common_table {
  width: unset !important;
}
.day_week_common_table .day_week_common_table_head tr th {
  padding: 0;
  background: var(--white) !important;
}
/* .day_week_common_table .day_week_common_table_head tr th:first-child{
      padding: 0 50px;    
  } */
.day_week_common_table .day_week_common_table_head,
.day_week_common_table .day_week_common_table_head tr,
.day_week_common_table .day_week_common_table_head th {
  border: 1px solid var(--table_border_color);
  border-collapse: collapse;
}
.day_week_common_table .day_week_common_table_head {
  width: calc(24 * 57px);
}
.timeline_head {
  min-width: 57px;
  max-width: 57px;
  height: 48px;
  border: none !important;
  padding: 0;
}
.timeline_head:not(:last-child) {
  border-right: 1px solid var(--table_border_color) !important;
}
.day_week_common_table .day_week_common_table_body td {
  padding: 0;
}
.day_week_common_table .week_head_one {
  border-bottom: 1px solid var(--table_border_color);
  margin: 0;
  padding: 14px 0;
}
.timeline_head_2 {
  min-width: 57px;
  max-width: 57px;
  height: 48px;
  padding: 0;
}
.calender_body .day_week_common_table tr > th:first-child,
.calender_body .day_week_common_table tr > td:first-child {
  text-align: left;
  padding: 0 20px;
}

/* Anil */
.timeline_scroll_calender .day_week_common_table thead {
  position: sticky;
  top: 0;
}
.timeline_scroll_calender .day_week_common_table thead tr > th:first-child,
.timeline_scroll_calender .day_week_common_table tbody tr > td:first-child {
  position: sticky;
  top: 0;
  left: 16px;
  border-right-width: 3px;
  background: var(--white);
}
.timeline_scroll_calender .day_week_common_table thead tr th:first-child {
  z-index: 111;
}
.timeline_scroll_calender .day_week_common_table thead {
  position: sticky;
  top: 73px;
  z-index: 1111;
}
.timeline_scroll_calender .calender_body table.day_week_common_table {
  border-collapse: separate; /* Don't collapse */
  border-spacing: 0;
}
.timeline_scroll_calender .calender_buttons {
  left: 0;
  width: 100%;
  padding-right: 0;
}
.timeline_scroll_calender .calender_body {
  position: relative;
  width: 100%;
  height: 100%;
}
.timeline_scroll_calender .calender_body table:not(.list-view-calender) {
  width: auto !important;
  max-width: 100%;
}
.timeline_scroll_calender * {
  box-sizing: border-box;
}
.timeline_scroll_calender {
  position: relative;
}
.timeline_scroll_calender .calender_main_inner {
  overflow-y: auto;
  margin: 0 16px 0 0;
}
.timeline_scroll_calender::after,
.timeline_scroll_calender::before {
  position: absolute;
  content: "";
  width: 16px;
  height: 100%;
  background: var(--white);
  top: 0;
  z-index: 11111;
}
.timeline_scroll_calender::after {
  left: 0;
}
.timeline_scroll_calender::before {
  right: 0;
}
.calender_body table.show-border-sticky {
  border-collapse: separate; /* Don't collapse */
  border-spacing: 0;
}
/* .calender_body table.viewtype-Day {
    table-layout: auto;
  } */
.calender_body table.viewtype-Day .week_cell:first-child {
  width: 30px;
  max-width: 5px;
}
.calender_body table.viewtype-Day .all_day_cell:first-child {
  width: 30px;
}
.list-view-calender .list_body tr td .dot-blue {
  width: calc(100% - 200px);
  word-break: break-all;
}
td.disable.table_body_cell {
  opacity: 0.8;
  border-radius: 0px 0px 5px 5px;
  background: #eeeeee;
  border-top: 2px solid #b5c6ff;
}
td.table_body_cell {
  border-radius: 0px 0px 5px 5px;
  background: transparent;
}
.table_body_cell.dayno {
  border-radius: 0px 0px 5px 5px;
  background: transparent;
  border-top: 2px solid #b5c6ff;
}
table.month-view-calender {
  border-collapse: separate;
  border-spacing: 12px;
  border: none;
}
table.month-view-calender thead th {
  border: none;
  color: #111111;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}
.calender_buttons .text_center h4 {
  color: #202842;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.dateVerticalThreeDot img {
  cursor: pointer;
}
.dateVerticalThreeDotWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.event-cell p {
  text-align: start;
  margin-bottom: 0;
}
td.disable.table_body_cell .dateVerticalThreeDot{
  display: none;
}
/* .event-cell {
  display: none;
} */
.RmEventsWeekViewMain .calender_buttons .text_start {
  margin-bottom: 0;
}