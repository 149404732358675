.Allactions {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

/* .snapShotHorizontalChartInner {
  min-height: 470px;
}

.snapShotBarWithLineChartInner>div {
  min-height: 270px;
} */

#tblDataRevenueManagementDashboard .cotent-filter {
  position: absolute;
  border-radius: 5px;
  background: #fff;
  box-shadow: -1px 2px 15px 0px rgba(0, 0, 0, 0.15);
  width: auto;
  right: 80%;
  top: 50%;
  padding: 12px 15px;
  z-index: 1111;
  display: flex;
  align-items: center;
  margin: 0;
  transform: translateY(-50%);
}

#snapshotSecondTable_wrapper .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#snapshotSecondTable_wrapper .header > div:first-child {
  order: 1;
}

@media (min-width: 1600px) {
  .login-main .container {
    max-width: 1576px;
  }
}

@media only screen and (max-width: 1799px) {
  .userNumberDeatailMain {
    width: 570px;
  }

  .table-wrapper {
    overflow-x: auto;
    max-width: 100%;
  }

  .table__container .stripped__table {
    width: 100%;
  }

  .ViewPropertySetupAllTables {
    overflow: auto;
  }

  .WeekViewDayMain {
    width: 190px;
  }

  .nav-underline {
    grid-column-gap: 20px !important;
    gap: 0;
  }

  .ConfigpropertiesContent {
    padding: 15px;
  }

  /* .rateIq-calender .width100 {
    min-width: 80px !important;
    max-width: 80px !important;
  } */
  .CRMAccountRulesMainAccordionMain.westModernLocationActivitiesWrapper-wrapper
    .westModernLocationActivitiesWrapper:not(:first-child)
    .westModernLocationActivitySingle-time-line-date-inner::after {
    left: 28px;
  }
  .crmwestModernLocationActivity .westModernLocationActivitySingle {
    max-width: 614px;
  }
  .westModernLocationActivitiesWrapper-wrapper
    .westModernLocationActivitiesWrapper:not(:first-child)
    .westModernLocationActivitySingle-time-line-date-inner::after {
    content: "";
    position: absolute;
    bottom: 112%;
    left: 30px;
  }
  .crmDashboardMain
    .westModernLocationActivitiesWrapper-wrapper
    .westModernLocationActivitiesWrapper:not(:first-child)
    .westModernLocationActivitySingle-time-line-date-inner::after {
    left: 30px;
  }
}

@media only screen and (max-width: 1699px) {
  .login-main {
    background-size: 52% 92%;
  }
  .crmDashboardMain .allSettingsBox p:nth-child(1) {
    margin-bottom: 10px;
    font-size: 14px;
  }
  .crmDashboardMain .allSettingsBox {
    padding: 10px;
  }
  .crmDashboardMain .allSettingsBox p:nth-child(2) {
    font-size: 28px;
  }
  .dashbord-left-top-sec h1 {
    font-size: 35px;
  }

  .WeekViewDayMain {
    width: 170px;
  }

  .RmToolEventCardMain p {
    font-size: 12px;
  }

  .WeekViewDayMain h5 {
    font-size: 14px;
  }

  .WeekViewDayEventWrapper h6 {
    font-size: 18px;
  }

  .home-aside ul li a {
    padding: 20px 28px;
  }

  .header-select-property .multiselector button {
    width: 240px;
  }

  .multiselector button {
    font-size: 18px;
  }

  .header-search-input {
    width: 340px;
  }

  .header-search-input input {
    padding: 7px 13px 7px 2px;
  }

  .db-left-top-right-sec img {
    bottom: -42px;
  }

  .ConfigpropertiesContentInner:not(:last-child) {
    margin-bottom: 10px;
  }

  .ConfigpropertiesContent {
    padding: 10px 15px;
  }

  .userNumberDeatailMain {
    right: 33px !important;
    width: 670px;
  }

  /* #UserManagementDataTable thead tr th {
    width: 10% !important;
  }
  .userManagementModalMain-innner-input input {
    width: 230px;
  } */
  .login-form-title img {
    margin-bottom: 20px;
  }

  .login-form-title p {
    margin-bottom: 25px;
  }

  .login-form input {
    padding: 10px 18px;
  }

  .login-right-main .forgot-password p {
    margin-bottom: 30px;
  }

  .login-right-main {
    padding: 20px 48px 30px 48px;
  }

  .login-right-main .login-buttons button {
    padding: 13px;
  }

  .login-right-main .login-buttons p {
    margin-bottom: 15px;
    margin-top: 15px;
  }

  .login-form-title h2 {
    font-size: 26px;
    line-height: 22px;
  }

  /* table#tblDataEmployeeManagement {
    width: 1374px !important;
  }
  table#UserManagementDataTable {
    width: 1374px !important;
  }
  table#tblDataPortfolioSetup {
    width: 1515px !important;
  }
  table#tblDataPropertySetup {
    width: 1374px !important;
  } */
  /* .ConfigpropertiesContentInner p {
    font-size: 14px;
  } */

  /* .configurationBasicInfoTitle h3 {
    font-size: 20px;
  }

  .configbasicInfoDetail p {
    font-size: 16px;
  } */

  .header-select-property .multiselector .multiSelectContainer {
    width: 290px;
  }

  /* table#tblDataUserManagementModal {
    width: 1200px;
  } */
  .userManagementModalMain-innner-input input {
    width: 220px;
  }

  .revenue-portfolio-main .nav-item a {
    padding: 10px 12px !important;
  }

  .revenue-portfolio-tab-content-img img {
    width: 50px;
    height: 50px;
  }

  .snapShotBarWithLineChart .barWithLineChartTable {
    overflow: auto;
  }

  .cloudForUpload {
    min-width: 400px;
  }

  .ProspectWestModernLocationsMain > div > div > div.col-xl-4,
  .ProspectWestModernLocationsMain > div > div > div.col-xl-5,
  .ProspectWestModernLocationsMain > div > div > div.col-xl-7 {
    width: 100%;
  }

  .nav-underline {
    grid-column-gap: 30px !important;
    gap: 0;
  }

  .westModernLocationTabsTabs .nav-underline {
    justify-content: flex-start;
  }

  /* .rateIq-calender .subdetails-container {
    font-size: 10px;
  } */

  /* 
  .main-date-box-inner p {
    font-size: 16px;
  } */

  /* .revenueOnSameDate {
    font-size: 16px;
  } */
  .image-on-accordion-header {
    left: 13%;
  }
  .CRMAccountRulesMainAccordionMain.westModernLocationActivitiesWrapper-wrapper
    .westModernLocationActivitiesWrapper:not(:first-child)
    .westModernLocationActivitySingle-time-line-date-inner::after {
    left: 16px;
  }
  .crmDashboardMain
    .crmwestModernLocationActivity
    .westModernLocationActivitySingle {
    max-width: 86%;
  }
  /* .event-fullcalendar .fc-scrollgrid-sync-table tbody td{
    width: 160px;
  } */
  /* .fc .fc-scroller-liquid-absolute{
    overflow: auto;
  } */
  .header-select-property .form-select {
    max-width: 300px;
  }
  .logo-name {
    width: 76%;
    text-align: center;
  }
  .crmDashboardMain
    .westModernLocationActivitiesWrapper-wrapper
    .westModernLocationActivitiesWrapper:not(:first-child)
    .westModernLocationActivitySingle-time-line-date-inner::after {
    left: 35px;
  }
}

@media only screen and (max-width: 1679px) {
  .crmDashboardMain
    .westModernLocationActivitiesWrapper-wrapper
    .westModernLocationActivitiesWrapper:not(:first-child)
    .westModernLocationActivitySingle-time-line-date-inner::after {
    left: 29px;
  }
}
@media only screen and (max-width: 1599px) {
  .login-main {
    background-size: 55% 90%;
  }

  .db-left-top-right-sec img {
    bottom: -55px;
  }
  .crmDashboardMain .allSettingsMain {
    grid-gap: 15px;
  }

  .header-search-input {
    width: 300px;
  }

  .WeekViewDayMain {
    width: 155px;
  }

  .header-select-property .multiselector button {
    width: 240px;
  }

  .dayTemp h4,
  .dayTemp p {
    font-size: 20px;
  }

  /* .db-right-top .db-right-top-profile-img {
    width: 140px;
  } */

  .header-icons li {
    padding-right: 9px;
  }

  .search-group input {
    padding: 8px 13px 8px 2px;
  }

  /* .ConfigpropertiesContent p {
    font-size: 14px;
  } */

  .ConfigpropertiesImg,
  .ConfigpropertiesWrapper .placeHolderConfig {
    width: 330px;
  }

  .ConfigpropertiesContent {
    width: calc(100% - 290px);
  }

  /* .configbasicInfoDetail p {
    font-size: 16px;
  } */

  .userManagementModalMain-innner-input input {
    width: 210px;
  }

  /* .ConfigpropertiesImg {
    width: 352px;
    height: 210px;
    object-fit: cover;
  } */

  /* .rateIq-calender tbody td {
    height: 95px;
  }

  .rateIq-calender .width100 {
    min-width: 78px !important;
    max-width: 78px !important;
  }

  .revenueOnSameDate {
    font-size: 14px;
  } */
  .CRMAccountRulesMainAccordionMain.westModernLocationActivitiesWrapper-wrapper
    .westModernLocationActivitiesWrapper:not(:first-child)
    .westModernLocationActivitySingle-time-line-date-inner::after {
    left: 12px;
  }
  .westModernLocationActivitiesWrapper-wrapper
    .westModernLocationActivitiesWrapper:not(:first-child)
    .westModernLocationActivitySingle-time-line-date-inner::after {
    left: 25px;
  }
  .operation-portfolio-main .nav-pills .nav-item a {
    padding: 5px 10px !important;
  }
  .AddAccountModalMain .tab-content,
  .AddOpenProspectModalMain .tab-content {
    max-height: 430px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .lead-detail-my-main .nav-underline {
    grid-gap: 15px !important;
  }
  .crmDashboardMain
    .westModernLocationActivitiesWrapper-wrapper
    .westModernLocationActivitiesWrapper:not(:first-child)
    .westModernLocationActivitySingle-time-line-date-inner::after {
    left: 25px;
  }
  .houseKeepingSchduleHeadEndMain {
    grid-gap: 12px;
  }
}

@media only screen and (max-width: 1499px) {
  .dbBoxes {
    padding: 20px;
  }

  .ConfigpropertiesContentInner:not(:last-child) {
    margin-bottom: 7px;
  }
  .cloudForUpload {
    min-width: 250px;
  }
  .allSettingsBox p:nth-child(1) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
  .allSettingsMain {
    display: grid;
    grid-template-columns: repeat(3, 1fr) !important;
  }
  .configurationBasicInfo {
    padding: 20px 20px 15px 20px;
  }

  .WeekViewDayMain {
    width: 145px;
  }

  .configActiveSubscription {
    padding: 20px 20px 10px 20px;
  }

  .header-search-input {
    width: 230px;
  }

  /* .configActiveSubscriptionTitle h3 {
    font-size: 20px;
  }

  .configActiveSubscriptionTitle button {
    font-size: 14px;
  }

  .configActiveSubscriptionBill h3 {
    font-size: 18px;
  }

  .revenue-portfolio-main .nav-item a {
    font-size: 14px;
  } */

  .revenue-portfolio-tab-content-img p {
    font-size: 14px;
  }
  /* 
  .allSettingsMain {
    grid-template-columns: repeat(3, 1fr);
  } */

  .revenue-management-dashboard-main .allSettingsMain {
    grid-template-columns: repeat(5, 1fr);
  }

  .AccountsCharts {
    grid-template-columns: repeat(2, 1fr);
  }

  .singleChartPercentageDetail {
    min-width: 150px;
  }

  .top-center-tabs {
    margin-top: 20px !important;
  }

  /* .rateIq-calender .revenueOnSameDate {
    top: 31px !important;
  }*/

  .rateIq-calender tbody td {
    height: 95px;
  }

  .rateIq-calender .width100 {
    min-width: 78px !important;
    max-width: 78px !important;
  }

  .revenueOnSameDate {
    font-size: 14px;
  }

  .main-date-box-inner img {
    width: 18px !important;
    height: 18px !important;
  }
  .image-on-accordion-header {
    left: 15%;
  }

  .westModernLocationActivitiesWrapper-wrapper
    .westModernLocationActivitiesWrapper:not(:first-child)
    .westModernLocationActivitySingle-time-line-date-inner::after {
    left: 34px;
  }
  .crmwestModernLocationActivity .westModernLocationActivitySingle {
    max-width: 83%;
  }
  .crmDashboardMain
    .crmwestModernLocationActivity
    .westModernLocationActivitySingle {
    max-width: 83% !important;
  }
  .CRMAccountRulesMainAccordionMain .westModernLocationActivitySingle {
    max-width: 89% !important;
  }
  .CRMAccountRulesMainAccordionMain.westModernLocationActivitiesWrapper-wrapper
    .westModernLocationActivitiesWrapper:not(:first-child)
    .westModernLocationActivitySingle-time-line-date-inner::after {
    left: 31px;
  }
  .crmwestModernLocationActivity
    .westModernLocationActivitySingle:first-child:after {
    height: 80px;
  }
  .crmDashboardMain
    .crmwestModernLocationActivity
    .westModernLocationActivitySingle:first-child:after {
    height: 70px;
  }
  .crmDashboardMain .westModernLocationActivitySingleTitle p {
    max-width: 250px;
  }
  .RevPakArchive-main .nav-item a {
    padding: 10px 12px;
  }
  .crmDashboardMain
    .westModernLocationActivitiesWrapper-wrapper
    .westModernLocationActivitiesWrapper:not(:first-child)
    .westModernLocationActivitySingle-time-line-date-inner::after {
    left: 34px;
  }
}

@media only screen and (max-width: 1399px) {
  .login-left-img {
    width: 520px;
  }
  .crmDashboardMain
    .westModernLocationActivitiesWrapper-wrapper
    .westModernLocationActivitiesWrapper:not(:first-child)
    .westModernLocationActivitySingle-time-line-date-inner::after {
    left: 29px;
  }

  .login-main {
    background-size: 50% 82%;
  }
  .logo-name {
    width: 71%;
  }
  .header-select-property .form-select {
    max-width: 200px;
  }
  .css-12a83d4-MultiValueRemove {
    padding-left: 1px;
    padding-right: 1px;
  }
  .login-right-main {
    padding: 40px;
  }
  .right-menu .header-search-input .search-group {
    margin-left: 0;
  }
  .dashbord-left-top-sec h1 {
    font-size: 30px;
    line-height: 40px;
  }

  .dashbord-left-top-sec p {
    font-size: 20px;
  }

  .db-left-top-right-sec img {
    bottom: -59px;
  }

  .side-arrow img {
    padding: 5px;
  }

  .header-icons li {
    padding-right: 10px;
  }

  .header-search-input .search-group {
    margin-left: 20px;
  }

  .db-right-top .secondary-btn {
    line-height: 15px;
  }

  .db-right-top h3 {
    font-size: 22px;
    line-height: 20px;
  }

  .cityTemp h1 {
    font-size: 70px;
    line-height: 60px;
  }

  .dayViseTemp {
    padding: 32px 20px;
  }

  .configurationBasicInfo {
    margin-top: 20px;
  }

  .configActiveSubscription {
    margin-bottom: 20px;
  }

  .ConfigpropertiesContentInner:last-child {
    justify-content: space-between;
  }

  .userManagementModalMain-innner-input input {
    width: 180px;
  }

  /* .add-btn-plus {
    padding: 6.5px 16px 6.5px 12px;
  }

  .ConfigpropertiesContent p {
    font-size: 16px;
  } */

  .header-select-property .multiselector .multiSelectContainer {
    width: 260px;
  }

  /* table#tblRoleManagement {
    width: 1110px !important;
  }
  table#EmailConfigurationDataTable {
    width: 1200px !important;
  }
  table#EmailTemplateConfigurationDataTable {
    width: 720px !important;
  }
  table#ActionLogsMainDataTable {
    width: 1100px !important;
  } */
  .logo-name {
    font-size: 20px;
  }

  .asideConfigurationMain,
  .revenue-management-aside-main,
  .asideConfigurationMain.revenue-management-aside-main {
    width: 290px;
  }

  .config-page-body,
  .revenue-management-page-body,
  .operation-page-body,
  .crm-page-body {
    width: calc(100vw - 290px);
  }

  .revenue-portfolio-main {
    margin-bottom: 20px;
  }

  .revenue-portfolio-main .nav-item a {
    padding: 10px 52px !important;
  }

  .configurationBasicInfo {
    margin-bottom: 20px;
  }

  #snapshotSecondTable_wrapper .toolbar .reservation-color-lines {
    display: none;
  }
  .CRMAccountRulesMainAccordionMain.westModernLocationActivitiesWrapper-wrapper
    .westModernLocationActivitiesWrapper:not(:first-child)
    .westModernLocationActivitySingle-time-line-date-inner::after {
    left: 26px;
  }
  .RevPakArchive-main .nav-item a {
    padding: 5px 10px;
  }
  .archive-content-right .enter-comment-here {
    position: sticky;
    bottom: 5px;
    left: 0;
  }
  .crmDashboardMain .westModernLocationActivitiesWrapper-wrapper {
    max-height: 350px;
    overflow-y: auto;
  }
  .operation-portfolio-main .nav-pills .nav-item a {
    padding: 5px 30px !important;
  }
  .operation-portfolio-main {
    margin-bottom: 20px;
  }
  .AddAccountModalMain .tab-content,
  .AddOpenProspectModalMain .tab-content {
    max-height: 290px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .userManagementModalMain-bottom .css-v7duua {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .allDateDetailPercent {
    display: flex;
    align-items: center;
    grid-gap: 12px;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 1299px) {
  /* .ledgerSummaryWrapper h4 {
    font-size: 16px;
  }

  .ledgerSummaryWrapper p {
    font-size: 14px;
  } */

  .ProspectDashboardTitleWraper {
    justify-content: space-between;
    grid-gap: 20px;
    flex-wrap: wrap;
  }

  .crmDashboardMain
    .crmwestModernLocationActivity
    .westModernLocationActivitySingle {
    max-width: 87%;
  }
  .crmDashboardMain
    .crmwestModernLocationActivity
    .westModernLocationActivitySingle:not(:first-child):after,
  .crmDashboardMain
    .crmwestModernLocationActivity
    .westModernLocationActivitySingle:first-child:after {
    width: 35px;
  }
  .crmDashboardMain
    .westModernLocationActivitiesWrapper-wrapper
    .westModernLocationActivitiesWrapper:not(:first-child)
    .westModernLocationActivitySingle-time-line-date-inner::after {
    left: 24px;
  }
  .ProspectDashboardTitleWraper div:nth-child(2) {
    order: 3;
  }

  .ProspectDashboardTitleWraper div:nth-child(3) {
    order: 2;
  }

  .WeekViewDayMain {
    width: 135px;
  }

  .ProspectDashboardTitleWraper h2:nth-child(1) {
    order: 1;
  }

  .roomsInPropertyInner {
    padding: 40px 15px 15px 15px;
  }

  .login-main {
    background-size: 52% 80%;
  }

  .login-form-title img {
    margin-bottom: 20px;
  }

  .login-form-title h2 {
    margin-bottom: 15px;
  }

  .login-form-title p,
  .login-right-main .forgot-password p {
    margin-bottom: 35px;
  }

  .login-right-main .login-buttons button {
    padding: 15px;
  }

  .login-form input {
    padding: 15px 18px;
  }

  .login-form .from-groups:not(:last-child) {
    margin-bottom: 20px;
  }

  .login-right-main .forgot-password p {
    margin-bottom: 20px;
  }

  .login-right-main {
    max-width: 500px;
  }

  .login-right-main .login-buttons p {
    margin-bottom: 15px;
    margin-top: 15px;
  }

  .dashbord-left-top-sec h1 {
    font-size: 28px;
    line-height: 35px;
  }

  .db-left-top-right-sec img {
    bottom: -55px;
  }

  .db-left-top-left-sec {
    padding: 48px 0 28px 48px;
  }

  .db-bottom-sec {
    grid-template-columns: repeat(2, 1fr);
  }

  .header-icons li {
    padding-right: 0;
    border: none;
  }

  .userNumberDeatailMain {
    left: -562px !important;
    width: 560px;
  }

  .header-select-property .multiselector .multiSelectContainer {
    width: 230px;
  }

  .login-right-main {
    padding: 20px 40px;
  }

  .login-form-title p {
    margin-bottom: 25px;
  }

  .header-search-input {
    width: 260px;
  }

  .singleChartPercentageDetail {
    min-width: 150px;
  }

  .chart-and-values canvas {
    height: 30px !important;
    width: 50px !important;
  }

  .rateIq-calender .subdetails-container .subdetails {
    padding: 3px;
  }

  .main-date-box-inner img {
    width: 16px !important;
    height: 16px !important;
  }
  .CRMHotelInfoMain-wrapper {
    width: 270px;
  }
  .CRMAccountRulesMain {
    width: 70%;
    margin-left: auto;
  }
  .image-on-accordion-header {
    left: 18%;
  }
  .CRMAccountRulesMainAccordionMain .westModernLocationActivitySingle {
    max-width: 87% !important;
  }
  .crmwestModernLocationActivity .westModernLocationActivitiesWrapper {
    padding: 10px 10px;
  }
  .crmDashboardMain
    .crmwestModernLocationActivity
    .westModernLocationActivitySingle {
    max-width: 87% !important;
  }
  .RevPakArchive-main .nav-item a {
    padding: 3px 6px;
  }
  .archive-content-right .enter-comment-here {
    bottom: 17px;
  }
}
@media only screen and (max-width: 1240px) {
  .crmDashboardMain
    .crmwestModernLocationActivity
    .westModernLocationActivitySingle {
    max-width: 86.4% !important;
  }
}
@media only screen and (max-width: 1199px) {
  .propertySettingSettingsMain > div:last-child {
    width: 100% !important;
  }

  /* .westModernLocationTabsHeader {
    margin-top: 20px;
  } */
  .asideConfigurationMain.revenue-management-aside-main {
    width: 280px;
  }
  .crmDashboardMain .AnticipatedValueBarChartTitle {
    flex-direction: column;
    align-items: flex-start !important;
  }
  .crmDashboardMain .chart-data.funnel-chart-data {
    margin-top: 15px;
  }
  .crmDashboardMain
    .crmwestModernLocationActivity
    .westModernLocationActivitySingle:not(:first-child):after,
  .crmDashboardMain
    .crmwestModernLocationActivity
    .westModernLocationActivitySingle:first-child:after {
    width: 65px;
  }
  .businessDtailsHeaderWrapper span {
    white-space: pre-wrap;
    line-height: 100%;
    font-size: 13px;
  }

  .asideConfigurationMain,
  .CRM-aside-main {
    top: 74px;
    height: calc(100vh - 74px);
  }

  .home-aside,
  .asideConfigurationMain.revenue-management-aside-main {
    top: 74px;
    height: calc(100vh - 74px);
  }

  .revenue-management-page-body,
  .operation-page-body,
  .crm-page-body,
  .config-page-body {
    top: 60px;
  }

  .AccountReconciliationTableWrapper {
    margin-bottom: 20px;
    height: auto;
  }

  .operationPropertyTopSecondary {
    margin-top: 20px;
  }

  .OperationTaskGridViewMain .ProspectDashboardTitleWraper {
    flex-direction: column;
    grid-gap: 10px;
  }

  .login-main {
    background: #f7f7ff;
    height: 100%;
    padding: 50px;
  }

  .login-left-img {
    width: 70%;
    text-align: center;
    margin-top: 40px;
  }

  .login-left {
    justify-content: center;
  }

  .login-right-main {
    margin: auto;
    width: auto;
  }

  .login-main .login-main-inner {
    flex-direction: column-reverse;
  }

  .login-right-main {
    max-width: 70%;
  }

  .logo-name {
    display: none;
  }

  .home-dashboard-main .col-left {
    width: 100%;
  }

  .home-dashboard-main .col-right {
    width: 100%;
  }

  .home-dashboard-right {
    display: flex;
    justify-content: space-between;
  }

  .db-left-top-right-sec img {
    bottom: -37px;
  }

  .dayViseTemp {
    width: 302px;
    padding: 32px 50px;
  }

  .db-right-top {
    margin-bottom: 0;
  }

  .db-right-bottom {
    padding: 40px 52px 27px 52px;
  }

  .cityTemp {
    margin-bottom: 50px;
  }

  .home-dashboard-right {
    margin-top: 40px;
  }

  .header-select-property .multiselector .multiSelectContainer {
    width: 240px;
  }

  .header-main .header-right-col {
    max-width: calc(100% - calc(63px + calc(var(--bs-gutter-x) * 1)));
    width: 100%;
  }

  .header-search-input {
    width: 240px;
  }

  /* .config-page-body, .revenue-management-page-body {
    margin: 80px 0 0 280px;
  } */
  .asideConfigurationMain,
  .revenue-management-aside-main {
    width: 280px;
  }

  .singleColumnTableBody ul li {
    margin-bottom: 22px;
  }

  .singleColumnTableBody {
    margin-bottom: 15px;
  }

  .userNumberDeatailMain {
    left: -534px !important;
    width: 526px;
  }

  .employee-detail-links {
    margin-top: 20px;
  }

  .employee-img-name-email {
    grid-gap: 15px;
  }

  .employee-detail-links .nav-pills .nav-item {
    margin-bottom: 10px;
  }

  .cotent-filter {
    width: 250px;
    right: 20px;
  }

  /* .allSettingsMain {
    grid-template-columns: repeat(2, 1fr);
  } */

  .chart-container .chartjs-render-monitor .chartjs-size-monitor {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    overflow: hidden;
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
    visibility: hidden;
    z-index: -1;
  }

  .chart-container .chartjs-render-monitor canvas {
    max-height: 300px;
    max-width: 100%;
    width: 100%;
    height: auto;
  }

  .config-page-body,
  .revenue-management-page-body,
  .operation-page-body,
  .crm-page-body {
    width: calc(100vw - 280px);
  }

  /* .crmDashboardMain .allSettingsMain,
    .rfpMain .allSettingsMain {
      grid-template-columns: repeat(2, 1fr);
    } */

  .crmDashboardMain > div > div {
    grid-row-gap: 20px;
  }

  .AccountsCharts {
    grid-template-columns: repeat(1, 1fr);
  }

  .accountRuleBodyDescription::before {
    top: 6px;
    left: 0px;
  }

  .accountRuleBodyDescription {
    position: relative;
    padding-left: 30px;
  }

  .revenue-management-dashboard-main .allSettingsBoxContent {
    padding: 0;
  }

  .month-name-with-arrow {
    margin-left: 0;
  }

  .calenderleft-border {
    border-left: none;
  }

  .main-date-box-inner img {
    width: auto !important;
    height: auto !important;
  }

  .rateIq-calender .subdetails-container .subdetails {
    padding: 5px;
  }

  .rateIq-calender tbody td {
    height: 105px;
  }

  .rateIq-calender .width100 {
    min-width: 100px !important;
    max-width: 100px !important;
  }

  .revenueOnSameDate {
    font-size: 18px;
  }

  .gobtn .gobtnwidth {
    padding: 10px 37px;
  }

  .gobtn {
    margin-top: 15px;
  }
  .CRMHotelInfoMain-wrapper {
    width: 100%;
  }
  .CRMAccountRulesMain {
    width: 100%;
    margin-top: 20px;
  }
  .CRMAccountRulesMainAccordionMain.westModernLocationActivitiesWrapper-wrapper
    .westModernLocationActivitiesWrapper:not(:first-child)
    .westModernLocationActivitySingle-time-line-date-inner::after {
    left: 31px;
  }
  .RevPakArchive-main .singleColumnTableBody {
    height: 100%;
  }
  .RevPakArchive-main .nav-item a {
    font-size: 12px;
  }
  .archive-content-right .enter-comment-here {
    bottom: 5px;
  }
  .crmDashboardMain .crmwestModernLocationActivity {
    margin-top: 20px;
  }
  .crmDashboardMain
    .westModernLocationActivitiesWrapper-wrapper
    .westModernLocationActivitiesWrapper:not(:first-child)
    .westModernLocationActivitySingle-time-line-date-inner::after {
    left: 28px;
  }
  .month-name-with-arrow {
    display: flex;
    justify-content: center;
    margin-bottom: 15px !important;
  }
  .rateShopRightMainContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
    width: 100%;
    overflow: auto;
  }
  .rateShopRightMain {
    margin-bottom: 0 !important;
  }
  .dbBoxImgTitle h3 {
    margin-bottom: 0;
  }
  .dateWithArrow,
  .houseKeepingSchduleHeadEndMain {
    justify-content: start;
  }
  .houseKeepingSchduleHeadEndMain {
    margin-bottom: 15px;
  }
  .dateWithArrow {
    margin-bottom: 0px;
  }
  .Displacement-colum {
    flex-direction: column;
    gap: 10px !important;
  }
}

@media only screen and (max-width: 1023px) {
  .logo-name {
    padding: 27px 5px;
  }
  .crmDashboardMain
    .westModernLocationActivitiesWrapper-wrapper
    .westModernLocationActivitiesWrapper:not(:first-child)
    .westModernLocationActivitySingle-time-line-date-inner::after {
    left: 28px;
  }
  .multiselector button {
    font-size: 17px;
  }

  .header-icons a img,
  .header-icons button img {
    width: 24px;
    height: 24px;
  }

  .header-select-property .multiselector button {
    width: 200px;
  }

  .header-search-input {
    width: 230px;
  }

  .header-main .header-right-col {
    max-width: calc(100% - calc(60px + calc(var(--bs-gutter-x) * 1)));
    width: 100%;
  }

  .right-menu {
    margin-right: 0;
  }

  .logo-name {
    display: none;
  }

  .asideConfigurationMain.revenue-management-aside-main,
  .asideConfigurationMain,
  .revenue-management-aside-main {
    width: 240px;
  }

  .config-page-body,
  .revenue-management-page-body,
  .operation-page-body,
  .crm-page-body {
    width: calc(100vw - 240px);
    padding: 30px calc(20px - calc(var(--bs-gutter-x) * 0.5));
  }

  /* .employee-name-email h3 {
    font-size: 18px;
  }

  .employee-name-email p {
    font-size: 16px;
  } */

  /* .delete-modal-content p {
    font-size: 16px;
    margin-bottom: 19px;
  } */

  .delete-modal-content h2 {
    /* font-size: 20px; */
    margin-bottom: 6px;
  }

  .tab_wrap_remove {
    white-space: nowrap;
    overflow: auto;
  }

  .home-aside,
  .asideConfigurationMain.revenue-management-aside-main,
  .asideConfigurationMain,
  .CRM-aside-main {
    top: 72px;
  }
  .home-aside,
  .asideConfigurationMain.revenue-management-aside-main,
  .asideConfigurationMain,
  .CRM-aside-main {
    height: calc(100vh - 72px);
  }
  .employeeDetailMain .tab-content {
    padding: 16px;
  }

  .revenue-management-dashboard-main .allSettingsBox p {
    font-size: 12px !important;
  }

  .revenue-portfolio-tab-content-img p {
    font-size: 12px;
  }
  .CRMAccountRulesMainAccordionMain .westModernLocationActivitySingle {
    max-width: 88% !important;
  }
  .CRMAccountRulesMainAccordionMain.westModernLocationActivitiesWrapper-wrapper
    .westModernLocationActivitiesWrapper:not(:first-child)
    .westModernLocationActivitySingle-time-line-date-inner::after {
    left: 28px;
  }
}

@media only screen and (max-width: 991px) {
  .OperationVendorMain .generalTermSetup-heading-btns {
    width: auto;
  }

  .maintenanceMainTabSec.nav-pills {
    justify-content: start;
    margin-top: 0px;
  }
  .CRMAccountRulesMainAccordionMain .westModernLocationActivitySingle {
    max-width: 83.4% !important;
  }
  .crmDashboardMain .AnticipatedValueBarChartTitle .width150 {
    width: 130px !important;
    min-width: 130px !important;
  }
  .crmDashboardMain
    .crmwestModernLocationActivity
    .westModernLocationActivitySingle:not(:first-child):after,
  .crmDashboardMain
    .crmwestModernLocationActivity
    .westModernLocationActivitySingle:first-child:after {
    width: 48px;
  }
  .login-right-main {
    max-width: 85%;
  }
  .crmDashboardMain
    .westModernLocationActivitiesWrapper-wrapper
    .westModernLocationActivitiesWrapper:not(:first-child)
    .westModernLocationActivitySingle-time-line-date-inner::after {
    left: 21px;
  }

  .login-left-img {
    width: 85%;
  }

  .header-main .header-left-col {
    max-width: calc(70px + calc(var(--bs-gutter-x) * 1));
  }

  .header-select-property .multiselector button {
    width: 160px;
  }

  .header-search-input .search-group {
    margin-left: 10px;
  }

  .header-search-input {
    width: 180px;
  }

  .ProspectDashboardTitleWraper {
    grid-gap: 0;
  }

  .config-page-body,
  .revenue-management-page-body,
  .crm-page-body,
  .operation-page-body {
    padding: 25px calc(20px - calc(var(--bs-gutter-x) * 0.5));
  }

  .header-avtar {
    margin-right: 10px;
    width: 40px;
    height: 40px;
  }

  .header-icons a img,
  .header-icons button img {
    width: 20px;
    height: 24px;
  }

  .header-main .header-right-col {
    width: 85%;
  }

  .header-icons li {
    margin-left: 12px;
  }

  .revenue-management-dashboard-main .allSettingsBoxImg {
    display: none;
  }

  .db-right-top {
    padding: 42px 15px 30px 15px;
  }

  .db-right-bottom {
    padding: 40px 15px 27px 15px;
  }

  .dayViseTemp {
    width: 285px;
    padding: 32px 30px;
  }

  .db-left-top-left-sec {
    padding: 28px 0 28px 28px;
  }

  .dashbord-left-top-sec h1 {
    font-size: 23px;
  }

  .dashbord-left-top-sec p {
    font-size: 20px;
  }

  .db-left-top-right-sec {
    padding-right: 28px;
  }

  .db-left-top-right-sec img {
    bottom: -50px;
  }

  .ConfigpropertiesImg,
  .ConfigpropertiesWrapper .placeHolderConfig {
    width: 100%;
    height: 250px;
  }

  .ConfigpropertiesWrapper {
    align-items: unset;
    flex-direction: column;
    max-height: unset;
  }

  .home-aside,
  .asideConfigurationMain.revenue-management-aside-main,
  .asideConfigurationMain,
  .CRM-aside-main {
    top: 82px;
  }

  .revenue-management-page-body,
  .operation-page-body,
  .crm-page-body,
  .config-page-body {
    top: 70px;
  }

  .ConfigpropertiesContent {
    width: 100%;
  }

  .header-select-property .multiselector .multiSelectContainer {
    margin-left: -25px;
    width: 200px;
  }

  .employee-detail-links a {
    color: var(--blue);
  }

  .tab-one-content-inputs {
    grid-template-columns: repeat(2, 1fr);
  }

  .profile-address {
    justify-content: left;
    margin-top: 10px;
  }

  .employee-detail-links {
    margin-top: 35px;
  }

  .userNumberDeatailContentInner {
    flex-direction: column;
  }

  .userNumberDeatailContentInner h3 {
    margin-top: 10px;
  }

  .userNumberDeatailMain {
    left: -324px !important;
    width: 316px;
  }

  .userManagementModalMain-innner-input {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .revenue-portfolio-main .nav-item a {
    padding: 10px 30px !important;
  }

  /* table#LoginLogsMainDataTable {
    width: 900px !important;
  } */
  .tab-one-content-inputs {
    grid-template-columns: repeat(1, 1fr);
  }

  .employeeDetailMain .tab-content {
    margin-top: 90px;
  }

  .employeeDetailMain .tab-content {
    max-height: 100%;
  }

  .employee-detail-links {
    margin-top: 45px;
  }

  .DoughnutChartMain {
    align-items: unset;
    flex-direction: column;
    grid-gap: 20px;
  }

  .placeHolderConfig {
    border: none;
    border-bottom: 1px solid #bbcafe;
    width: 100%;
  }

  .placeHolderConfig img {
    width: 100%;
    height: 100%;
  }

  .ConfigpropertiesImg img,
  .ConfigpropertiesWrapper .placeHolderConfig img {
    width: 100%;
    height: 100%;
  }

  .contact-info-inline-inputs {
    flex-wrap: wrap;
  }

  .contact-info-inline-inputs > div {
    width: 30%;
  }

  .generalTermSetup-heading-btns {
    display: flex;
    grid-gap: 10px;
  }

  .nav-underline {
    grid-column-gap: 11px !important;
    gap: 0;
  }

  /* .generalTermSetup-heading-btns {
    width: 280px;
  } */
  .allGridBoxWrapper {
    flex-wrap: wrap;
  }

  .switchGroups .form-switch {
    margin-bottom: 10px;
  }
  .image-on-accordion-header {
    left: 22%;
  }
  .generalTermSetup-heading-btns {
    margin-top: 0px;
  }
  .header-main .header-right-col {
    width: 80%;
  }
  .flexcolum {
    flex-direction: column;
    align-items: self-start !important;
  }
  .event-fullcalendar .fc-daygrid-day-top {
    font-size: 18px;
  }
}

@media only screen and (max-width: 800px) {
  .home-dashboard-right {
    display: block;
  }

  .dayViseTemp {
    width: 400px;
    padding: 32px 30px;
    margin: auto;
  }

  .db-right-top {
    margin-bottom: 30px;
  }

  .db-left-top-right-sec img {
    bottom: -57px;
  }

  .employee-detail-links a {
    font-size: 14px;
  }

  .tab-one-content-inputs {
    grid-template-columns: repeat(1, 1fr);
  }

  .header-select-property .multiselector .multiSelectContainer {
    margin-left: -28px;
    width: 187px;
  }

  .allSettingsMain {
    grid-template-columns: repeat(1, 1fr);
  }

  .table-header {
    flex-direction: column;
    grid-gap: 20px;
  }

  .PortfolioSetupModalMain .from-groups,
  .AddPropertySetupModalMain .from-groups {
    min-width: 150px;
  }

  .rateIq-calender .width100 {
    min-width: 97px !important;
    max-width: 97px !important;
  }
}

@media only screen and (max-width: 767px) {
  .dbBoxImgTitle .dbBoxImg {
    width: 50px;
  }
  .rateshop-colum {
    flex-direction: column;
    align-items: self-start !important;
  }
  .RateShopConfigBottomInner {
    max-height: 100%;
  }
  .Configflexcolum {
    flex-direction: column;
    align-items: self-start !important;
    gap: 10px;
  }
  .allSettingsMain {
    grid-template-columns: repeat(1, 1fr) !important;
  }
  .allSettingsBox p:nth-child(2) {
    font-size: 28px;
  }
  .main-date-box-inner p {
    font-size: 18px;
  }
  .revenueOnSameDate {
    font-size: 16px;
  }
}

@media only screen and (max-width: 575px) {
}

@media only screen and (max-width: 480px) {
}

@media only screen and (max-width: 389px) {
}

@media only screen and (max-width: 321px) {
}
