*,
* ::after,
*::before {
  box-sizing: border-box;
}

body {
  font-family: "Roboto";
  font-style: normal;
  overflow-x: hidden !important;
}

a {
  text-decoration: none;
  color: inherit;
  display: inline-block;
}
p {
  margin: 0;
}
img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
*:focus,
*:focus-visible,
*:focus-within {
  outline: none !important;
  box-shadow: none !important;
}
table {
  width: 100% !important;
}

ul {
  padding: 0;
  margin: 0;
}

.width45 {
  width: 59px !important;
  min-width: 59px !important;
  max-width: 59px !important;
}

.width25 {
  width: 25px !important;
  min-width: 25px !important;
  max-width: 25px !important;
}

.width50 {
  width: 50px !important;
  min-width: 50px !important;
  max-width: 50px !important;
}

.width60 {
  width: 60px !important;
  min-width: 60px !important;
  max-width: 60px !important;
}

.width70 {
  width: 70px !important;
  min-width: 70px !important;
  max-width: 70px !important;
}
.width75 {
  width: 75px !important;
  min-width: 75px !important;
  max-width: 75px !important;
}

.width90 {
  width: 90px !important;
  min-width: 90px !important;
  max-width: 90px !important;
}

.width100 {
  width: 100px !important;
  min-width: 100px !important;
  max-width: 100px !important;
}

.width110 {
  width: 110px !important;
  min-width: 110px !important;
  max-width: 110px !important;
}

.width125 {
  width: 125px !important;
  min-width: 125px !important;
  max-width: 125px !important;
}

.width150 {
  width: 150px !important;
  min-width: 150px !important;
  max-width: 150px !important;
}

.width175 {
  width: 175px !important;
  min-width: 175px !important;
  max-width: 175px !important;
}

.width200 {
  width: 200px !important;
  min-width: 200px !important;
  max-width: 200px !important;
}

.width225 {
  width: 225px !important;
  min-width: 225px !important;
  max-width: 225px !important;
}

.width250 {
  width: 250px !important;
  min-width: 250px !important;
  max-width: 250px !important;
}

.width275 {
  width: 275px !important;
  min-width: 275px !important;
  max-width: 275px !important;
}

.width300 {
  width: 300px !important;
  min-width: 300px !important;
  max-width: 300px !important;
}

.width350 {
  width: 350px !important;
  min-width: 350px !important;
  max-width: 350px !important;
}

.width400 {
  width: 400px !important;
  min-width: 400px !important;
  max-width: 400px !important;
}
.width420 {
  width: 420px !important;
  min-width: 420px !important;
  max-width: 420px !important;
}
.width450 {
  width: 450px !important;
  min-width: 450px !important;
  max-width: 450px !important;
}

.width500 {
  width: 500px !important;
  min-width: 500px !important;
  max-width: 500px !important;
}

.width550 {
  width: 550px !important;
  min-width: 550px !important;
  max-width: 550px !important;
}

.width600 {
  width: 600px !important;
  min-width: 600px !important;
  max-width: 600px !important;
}

.width650 {
  width: 650px !important;
  min-width: 650px !important;
  max-width: 650px !important;
}

.width700 {
  width: 700px !important;
  min-width: 700px !important;
  max-width: 700px !important;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #e1e8ff;
  background-color: #e1e8ff;
}

.table-wrapper::-webkit-scrollbar-thumb,
.table-responsive::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #e1e8ff;
  background-color: #e1e8ff;
}

.modal-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #e1e8ff;
  background-color: #cad5f9;
}

.table-wrapper::-webkit-scrollbar,
.table-responsive::-webkit-scrollbar,
.modal-body::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #f5f5f5;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #e1e8ff #f5f5f5;
}

/* Safari */
* {
  scrollbar-color: #e1e8ff #f5f5f5;
}

/* .border_right_inner tbody td:nth-child(4),
.border_right_inner tbody td:nth-child(7),
.border_right_inner tbody td:nth-child(10),
.border_right_inner thead tr.no-bg-tr th:nth-child(4),
.border_right_inner thead tr.no-bg-tr th:nth-child(7),
.border_right_inner thead tr.no-bg-tr th:nth-child(10),
.border_right_inner thead tr.colspanborder th:not(:last-child),
.border_right_inner .colapseborder td:nth-child(4),
.border_right_inner .colapseborder td:nth-child(7),
.border_right_inner .colapseborder td:nth-child(10){
  border-right: 1px solid black; 
} */

.border_right {
  border-right: 1px solid #c0c7df !important;
}
.border_left {
  border-left: 1px solid #c0c7df !important;
}

/* th.border_right {
  border-right: 1px solid #3565FC80 !important; 
} */
th {
  border-bottom: 1px solid #3565fc80;
}

/* table:has(.stickyLastRow ) thead th:nth-last-child(2),
table:has(.stickyLastRow ) thead .filters th:nth-last-child(2),
table:has(.stickyLastRow ) tbody td:nth-last-child(2){
  border: none !important;
}  */
/* table tr.filters th:not(:last-child) {
  border-right: 1px solid #d7e0fe !important; 
} */
table {
  margin-bottom: 0 !important;
}

table tfoot tr:last-child td {
  border-bottom: none !important;
}

.stripped__table table::after {
  display: none;
}

/* .filters th:not(:last-child) {
  border: 1px solid #d7e0fe;
  border-width: 0 1px 1px 0;
} */
.stripped__table table {
  border: 1px solid #e1e8ff;
  position: relative;
  z-index: 0;
  border-collapse: unset;
  border-radius: 5px;
  border-spacing: 0;
  /* padding: 2px; */
  /* border-width: 1px 1px 1px ; */
}
.stripped__table table thead tr:first-child th:first-child {
  border-top-left-radius: 5px;
}
.stripped__table table thead tr:first-child th:last-child {
  border-top-right-radius: 5px;
}
.stripped__table table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 5px;
}
.stripped__table table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 5px;
}
.hotels_tab_error {
  color: #ff0000;
  margin-top: 5px;
}
